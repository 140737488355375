import paymentServices from '../../Payments/Services'
import { i18n } from '../../../../../../plugins/i18n'
import helpers from '../../../../../../helpers'
import moment from 'moment'

const getCustomData = async (data) => {
  const { 
    allParties, 
    landlordId, 
    idLandlordContactPerson, 
    otherTenantId, 
    idTenantContactPerson, 
    tenant, 
    tenantIdentityNumber, 
    otherTenantIdentityNumber,
    contractPaymentsList, 
    separatePaymentsList, 
    singlePaymentsList, 
    rentPaymentDomains, 
    selectedUnits, 
    contractNumber, 
    customerLogo, 
    additionalInfo, 
    tenantExtensionPeriod, 
    noticePeriodLandlord, 
    noticePeriodTenant, 
    extensionNoticePeriod, 
    rentPeriodStartDate, 
    rentContractEndDate, 
    billingStartDate, 
    tenureStartDate, 
    validityAdditionalInfo, 
    validityType, 
    earliestNoticeDateTenant, 
    earliestNoticeDateLandlord, 
    billingCycleLength, 
    firstBillingMonth, 
    additionalInfoUnits, 
    additionalInfoParking, 
    additionalInfoPayments, 
    guaranteesList, 
    attachments, 
    contractConditions, 
    contractConditionsTemplates, 
    settings, 
    getAttachmentArrayBuffer,
    existingUnits,
    definitionLabelById,
    currentDate,
    rambollfmapi
  } = data

  let selectedTargets = []
  //If for some reason the address -field is empty, we try to get it again
  if (selectedUnits) {
    selectedTargets = await Promise.all(selectedUnits.map(async (unit) => {
      if (unit.id !== undefined && unit.id !== null && unit.address === "") {
        unit.address = await getBuildingAddressForFutureBuilding(unit.buildingId, unit.siteId, currentDate, rambollfmapi);
      }
      return unit;
    }));
  }

  //landlord and tenant(s) table data
  const landlord = allParties.find(party => party.id == landlordId)
  const landlordContactPerson = allParties.find(party => party.id == idLandlordContactPerson)
  const otherTenant = allParties.find(party => party.id == otherTenantId)
  const tenantContactPerson = allParties.find(party => party.id == idTenantContactPerson)

  await paymentServices.calculateSumsService.calculatePaymentSum(contractPaymentsList, rentPaymentDomains, selectedTargets, rambollfmapi)

  // get Payments data
  const contractPayments = await Promise.all(getPaymentData(contractPaymentsList, selectedTargets, existingUnits, rentPaymentDomains, rambollfmapi, definitionLabelById))
  const separatePayments = await Promise.all(getPaymentData(separatePaymentsList, selectedTargets, existingUnits, rentPaymentDomains, rambollfmapi, definitionLabelById))
  const singlePayments = await Promise.all(getSinglePaymentData(singlePaymentsList, selectedTargets, rentPaymentDomains, rambollfmapi))

  // Check if there is any vat in the payments
  const payments = {
    contractPayments: selectedTargets ? contractPayments : undefined,
    separatePayments: selectedTargets ? separatePayments : undefined,
    singlePayments: selectedTargets ? singlePayments : undefined,
  }
  const vatCount = getVatCount(payments)

  // Unique sites data
  const uniqueSites = selectedTargets?.length > 0 ? getUniqueSites(selectedTargets, additionalInfo) : []
  
  // Rent reviews table data
  const rentReviews = contractPayments && contractPayments.length > 0 ? getRentReviewsData(contractPayments) : []

  // Guarantees table data
  const guarantees = guaranteesList?.length > 0 ? getGuaranteesData(guaranteesList) : []
  
  // Rent table data
  const rent = (contractPayments?.length > 0 || separatePayments?.length > 0) ? getRentData(contractPayments, separatePayments, selectedTargets, vatCount, billingCycleLength, firstBillingMonth) : {}
  
  // Units and structures table data
  const selectedUnitsAndStructures = selectedTargets?.length > 0 ? getSelectedUnitsAndStructuresData(selectedTargets) : []
  
  // Parking spaces table data
  const selectedParkingSpaces = selectedTargets?.length > 0 ? getSelectedParkingSpacesData(selectedTargets) : []

  // Payments tables data  
  const mappedContractPaymentsData = contractPayments.length > 0 && selectedTargets.length > 0 ? mapContractPaymentsData(contractPayments, selectedTargets) : []
  const mappedSeparatePaymentsData = separatePayments.length > 0 && selectedTargets.length > 0 ? mapSeparatePaymentsData(separatePayments, selectedTargets) : []
  const mappedSinglePaymentsData = singlePayments.length > 0 && selectedTargets.length > 0 ? mapSinglePaymentsData(singlePayments, selectedTargets) : []

  return {
    contractNumber,
    noGuarantees: i18n.t('pdf.noGuarantees'),
    getAttachmentArrayBuffer,
    header: {
      contractNumber: contractNumber,
      contractType: additionalInfo.contracttype,
      logo: customerLogo,
    },
    landlord: {
      name: landlord?.name,
      phoneNumber: landlord?.phone_number,
      address: landlord?.address,
      businessId: landlord?.business_id,
      contactPersonName: landlordContactPerson?.name,
      contactPersonEmail: landlordContactPerson?.email,
      contactPersonPhoneNumber: landlordContactPerson?.phone_number,
      accountNumber: landlord?.account_number,
    },
    tenant: {
      name: tenant?.name,
      identityNumber: tenantIdentityNumber,
      address: tenant?.address,
      email: tenant?.email,
      phoneNumber: tenant?.phone_number,
      businessId: tenant?.business_id,
      contactPersonName: tenantContactPerson?.name,
      contactPersonEmail: tenantContactPerson?.email,
      contactPersonPhoneNumber: tenantContactPerson?.phone_number,
      tenantType: tenant?.type,
    },
    otherTenant: otherTenant === undefined ? undefined : {
      name: otherTenant?.name,
      identityNumber: otherTenantIdentityNumber,
      email: otherTenant?.email,
      phoneNumber: otherTenant?.phone_number,
      tenantType: otherTenant?.type,
    },
    uniqueSites,
    selectedUnitsAndStructures,
    selectedParkingSpaces,
    additionalInfoUnits: additionalInfoUnits ? additionalInfoUnits : undefined,
    additionalInfoParking: additionalInfoParking ? additionalInfoParking : undefined,
    rentPeriod: {
      rentPeriodStartDate: toDateString(rentPeriodStartDate),
      rentPeriodEndDate: toDateString(rentContractEndDate),
      billingStartDate: toDateString(billingStartDate),
      tenureStartDate: toDateString(tenureStartDate),
      validityAdditionalInfo: validityAdditionalInfo,
      validityType: validityType === 'validity_fixed' ? i18n.t('pdf.fixed_term'): i18n.t('pdf.permanent'),
      isFixedTerm: validityType === 'validity_fixed',
      hasContractPeriodOfExtension: settings.hasContractPeriodOfExtension,
      tenantExtensionPeriod: tenantExtensionPeriod ? tenantExtensionPeriod + ' ' + i18n.t('Mth') : null,
      noticePeriodLandlord: noticePeriodLandlord ? noticePeriodLandlord + ' ' + i18n.t('Mth') : null,
      noticePeriodTenant: noticePeriodTenant ? noticePeriodTenant + ' ' + i18n.t('Mth') : null,
      extensionNoticePeriod: extensionNoticePeriod ? extensionNoticePeriod + ' ' + i18n.t('pdf.monthsBeforeEnding') : null,
    },
    noticePeriod: {
      noticePeriodLandlord: noticePeriodLandlord ? noticePeriodLandlord + ' ' + i18n.t('Mth') : null,
      noticePeriodTenant: noticePeriodTenant ? noticePeriodTenant + ' ' + i18n.t('Mth') : null,
      earliestNoticeDateTenant: toDateString(earliestNoticeDateTenant),
      earliestNoticeDateLandlord: toDateString(earliestNoticeDateLandlord),
      isFixedTerm: validityType === 'validity_fixed',
    },
    housingModification: {
      hasHousingModification: 
        additionalInfo.housingModification === 'contract.no_housing_modifications' || 
        additionalInfo.housingModification === undefined || 
        additionalInfo.housingModification === '' || 
        additionalInfo.housingModification === null ? false : true,
      housingModification: 
        additionalInfo.housingModification === 'contract.no_housing_modifications' || 
        additionalInfo.housingModification === undefined || 
        additionalInfo.housingModification === '' || 
        additionalInfo.housingModification === null ? i18n.t('No') : i18n.t('Yes'),
      housingModificationAgreedUpon: additionalInfo.housingModificationInfo,
    },
    billingInfo: {
      billingCycleLength: billingCycleLength,
      firstBillingMonth: firstBillingMonth,
      isVat: vatCount > 0
    },
    rent,
    contractPayments: mappedContractPaymentsData, 
    separatePayments: mappedSeparatePaymentsData, 
    singlePayments: mappedSinglePaymentsData,
    additionalInfoPayments: additionalInfoPayments ? additionalInfoPayments : undefined,
    rentReviews, 
    guarantees,
    attachments: attachments.filter((a) => a.isForContract),
    contractConditions: contractConditions?.text ?? contractConditionsTemplates.filter((template) => template.id === contractConditions.templateId)?.[0]?.text,
    signees: {
      landlordName: landlord?.name + '\n\n\n\n\n' + i18n.t("pdf.signature"),
      tenantName: tenant?.name + '\n\n\n\n\n' + i18n.t("pdf.signature"),
      dateAndTime: '',
    }
  }
}

const getTargetUnitCodes = (payment, selectedTargets) => {
  return selectedTargets
    .filter(({ unitId, structureId, parkingSpaceId }) => (payment?.targetUnits?.includes(Number(unitId)) || payment?.targetStructures?.includes(Number(structureId)) || payment?.targetParkingSpaces?.includes(Number(parkingSpaceId)))
    )
    .map(u => u.unitCode)
    .join(', ')
}

const toDateString = (date) => {
  if (date === null) {
    return "–"
  }
  var newDate = new Date(date)
  return isNaN(newDate) ? "" : newDate.toLocaleDateString("fi-FI")
}

const getPaymentDomain = (payment, rentPaymentDomains) => {

  if (typeof rentPaymentDomains !== 'undefined'){
    let paymentDomain = rentPaymentDomains.filter(domains => domains.id === payment.paymentDomainId)
    let name = paymentDomain[0].name
    return name
  } 
}

const getVatCount = (payments) => {
  return Object.values(payments)
    .filter(p => p !== undefined)
    .map(pt => Object.values(pt).some(p => p?.sums?.tax > 0))
    .reduce((acc, cur) => acc + cur, 0)
}

const getMonthName = (monthNumber) => {
  const date = new Date()
  date.setMonth(monthNumber - 1)
  return date.toLocaleString('en-US', { month: 'long' })
}

const getBuildingAddressForFutureBuilding = async (buildingId, siteId, currentDate, rambollfmapi) => {
  const buildings = await rambollfmapi.buildings.listWithFuture({ query: { siteId: siteId, time: currentDate } })
  const filteredBuildings = buildings.filter(building => building.id_building === buildingId)
  const addressArray = new Set(filteredBuildings
    .filter(b => !!b.address)
    .map(b => `${b.address}, ${b.zip_code} ${b.post_office}`)
  )
  return Array.from(addressArray).join("\n")
}

const getUniqueSites = (sites, additionalInfo) => {
  return sites
    .filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.address === value.address && t.site === value.site
      ))
    )
    .map((unit) => {
      return {
        site: unit.site,
        address: unit.address,
        spaceUsage: additionalInfo.spaceusage,
        id: unit.siteId,
      }
    })
}

const getGuaranteesData = (guarantees) => {
  return guarantees.map(g => {
    return {
      id: g.id,
      sum: `${helpers.humanize.amount_long(g.guaranteeSum, 2).replace('.',',')} €`,
      description: g.guaranteeDescription,
      type: i18n.t(g.guaranteeType),
      agreedDateOfDelivery: toDateString(g.agreedDateOfDelivery),
      dateOfExpiration: toDateString(g.dateOfExpiration),
    }
  })
}

const getSelectedUnitsAndStructuresData = (units) => {
  return units        
    .filter(unit => unit.unitId || unit.structureId)
    .map(unit => {
      return {
        unitCode: unit.unitCode,
        usage: unit.usage,
        agreedArea: unit.agreedArea,
        agreedAreaText: `${helpers.humanize.amount_long(unit.agreedArea, 1).replace('.',',')} m²`,
      }
    })
}

const getSelectedParkingSpacesData = (units) => {
  return units        
    .filter(unit => unit.parkingSpaceId)
    .map(unit => {
      return {
        unitCode: unit.unitCode,
        usage: unit.usage,
        agreedAmount: unit.agreedAmount,
        agreedAmountText: `${unit.agreedAmount} ${i18n.t('pcs')}`,
      }
    })
}

const getPaymentData = (payments, selectedTargets, existingUnits, rentPaymentDomains, rambollfmapi, definitionLabelById) => {
  return payments.map(async (payment) => {

    if (selectedTargets === undefined) {
      return
    }
    if (selectedTargets.length === 0) {
      selectedTargets = existingUnits
    }
    // Calculate sums

    const paymentDomain = rentPaymentDomains.filter(domain => domain.id === payment.paymentDomainId)[0]

    const agreedUnitAreas = selectedTargets
    .filter(unit => payment.wholeContractPayment || payment.targetUnits?.some(targetUnit => targetUnit === unit.id))
    .map(unit => unit.agreedArea ?? 0)

    const sums = payment.pcsCount > 0 
      ? await rambollfmapi.rentpayments.payments.calculateSumsPcs(paymentDomain, payment.rent, payment.pcsCount, payment.squareBased) 
      : await rambollfmapi.rentpayments.payments.calculateSums(paymentDomain, payment.rent, agreedUnitAreas, payment.squareBased)

    // Get index point numbers
    if (!payment.basicIndexPointNumber && payment.basicIndexYear && payment.basicIndexMonth) {
      const index = await rambollfmapi.rentpayments.indexnumbers(payment.basicIndexYear, payment.basicIndexMonth)
      payment.basicIndexPointNumber = index[0]?.costOfLivingIndex
    }

    return  {
      ...payment,
      reviewType: i18n.t(definitionLabelById(payment.defRentReviewType)),
      reviewTypeDef: definitionLabelById(payment.defRentReviewType),
      paymentType: getPaymentDomain(payment, rentPaymentDomains),
      paymentVat: rentPaymentDomains.filter(domains => domains.id === payment.paymentDomainId)[0].vatPercent,
      rentReviewBasedOn: definitionLabelById(payment.defRentReviewBasedOn),
      processingMode: i18n.t(definitionLabelById(payment.defProcessingMode)),
      invoicingBasis: i18n.t(definitionLabelById(payment.defInvoicingBasis)),
      sums: sums
    }
  })
}

const getSinglePaymentData = (payments, selectedTargets, rentPaymentDomains, rambollfmapi) => {
  return payments.map(async (payment) => {
    if (selectedTargets === undefined) {
      return
    }
    const paymentDomain = rentPaymentDomains.filter(domain => domain.id === payment.paymentDomainId)[0]
    const agreedUnitAreas = selectedTargets
      .filter(unit => payment.wholeContractPayment || payment.targetUnits?.some(targetUnit => targetUnit === unit.id))
      .map(unit => unit.agreedArea ?? 0)
      const sums = await rambollfmapi.rentpayments.payments.calculateSums(paymentDomain, payment.rent, agreedUnitAreas, payment.squareBased)

    return {
      ...payment,
      paymentType: getPaymentDomain(payment, rentPaymentDomains),
      paymentVat: rentPaymentDomains.filter(domains => domains.id === payment.paymentDomainId)[0].vatPercent,
      sums: sums
    }
  })
}

const mapContractPaymentsData = (contractPayments, selectedTargets) => {
  const payments = []
  for (const payment of contractPayments) {
    const targetUnitCodes = getTargetUnitCodes(payment, selectedTargets)

    payments.push(
      {
        paymentType: payment.paymentType,
        startDate: toDateString(payment.startDate),
        endDate: toDateString(payment.endDate),
        reviewType: payment.reviewType,
        reviewTypeDef: payment.reviewTypeDef,
        netSum: payment.netSum !== undefined ? `${helpers.humanize.amount_long(Number(payment?.netSum), 2).replace('.',',')} €` : '',
        target: payment.wholeContractPayment ? i18n.t('pdf.wholeContract') : targetUnitCodes,
      }
    )
  }
  return payments
}

const mapSeparatePaymentsData = (separatePayments, selectedTargets) => {
  const payments = []
  for (const payment of separatePayments) {
    const targetUnitCodes = getTargetUnitCodes(payment, selectedTargets)
    
    payments.push(
      {
        legend: payment.legend,
        netSquareRent: payment.sums.netSquareRent !== undefined ? `${helpers.humanize.amount_long(Number(payment?.sums.netSquareRent), 2).replace('.',',')} ${i18n.t("pdf.euro_m2_month")}` : '',
        netSum: payment.sums.netSum !== undefined ? `${helpers.humanize.amount_long(Number(payment?.sums.netSum), 2).replace('.',',')} €` : '',
        alv: payment.paymentVat !== undefined ? `${payment.paymentVat} %` : '',
        target: payment.wholeContractPayment ? i18n.t('pdf.wholeContract') : targetUnitCodes,
      }
    )
  }
  return payments
}

const mapSinglePaymentsData = (singlePayments, selectedTargets) => {
  const payments = []
  for (const payment of singlePayments) {
    const targetUnitCodes = getTargetUnitCodes(payment, selectedTargets)
    const grossSum = (1 + ( payment?.paymentVat / 100 )) * payment?.netSum
    payments.push(
      {
        paymentType: payment.paymentType,
        paymentDate: toDateString(payment.paymentDate),
        netSum: payment.netSum !== undefined ? `${helpers.humanize.amount_long(Number(payment?.netSum), 2).replace('.',',')} €` : '',
        paymentVat: payment?.paymentVat !== undefined ? `${payment?.paymentVat} %` : '',
        grossSum: grossSum !== undefined ? `${helpers.humanize.amount_long(Number(grossSum), 2).replace('.',',')} €` : '',
        target: payment.wholeContractPayment ? i18n.t('pdf.wholeContract') : targetUnitCodes,
      }
    )
  }
  return payments
}

const getRentReviewsData = (contractPayments) => {
  const rentReviews = []
  // filter out payments that are do not have rent reviews
  const filteredPayments = contractPayments?.filter((p) => (p.reviewTypeDef !== "PaymentRentReviewNone" && p.reviewTypeDef !== "") )

  if (filteredPayments.length > 0) {
    const reviewGroups = filteredPayments.reduce((acc, value) => {
      if (!acc[value.reviewTypeDef]) {
        acc[value.reviewTypeDef] = []
      }
      acc[value.reviewTypeDef].push(value)
      return acc;
    }, {})

    for (const [reviewTypeDef, reviewGroup] of Object.entries(reviewGroups)) {
      // Calculate net sum
      const netSum = reviewGroup.map(p => p.netSum).reduce((acc, val) => {
        acc += val;
        return acc;
      }, 0)

      // Init values for table
      const rows = {
        reviewType: [],
        paymentType: [],
        netSum: [netSum !== undefined ? `${helpers.humanize.amount_long(Number(netSum), 2).replace('.',',')} €` : ''],
        indexHeading: [i18n.t("pdf.index")],
        baseindex: [],
        basicIndexPointNumber: [],
        rentReviewStartDate: [],
        reviewMonths: [],
        indexMonths: [],
        minimumIncrease: [],
        rentReviewBasedOn: [],
        reviewAdditionalInfo: [],
        dynamicReviewInfo: [],
      }

      // Update values
      reviewGroup.map(p => {
        const formatDate = (date) => {
          const formatted = moment(date, "YYYY-MM-DD")
          return formatted.isValid() ? formatted.format("D.M.YYYY") : undefined
        }

        const getReviewInspectionFrequency = (count) => {
          if (count === 0) {
            return ''
          } else if (count === 1) {
            return i18n.t('customPdf.singleRentReviewFirstDay')
          } else {
            return i18n.t('customPdf.multipleRentReviewFirstDay')
          }
        }
        // Dynamic review info
        const rentBasedOn = [ i18n.t(p.rentReviewBasedOn).toLowerCase() ]
        const reviewMonths = [ p.reviewMonths.map(m => i18n.t(getMonthName(m))).join(', ') ]
        const rentIsCalculated = p.reviewMonths.map((r, i) => ([ i18n.t(getMonthName(r)), i18n.t(getMonthName(p.indexMonths[i]))]))
        const partA = i18n.tc('pdf.rentChecks', p.reviewMonths.length)
        const partB = p.reviewMonths.length > 0 ? i18n.t('customPdf.rentReviewMonths', reviewMonths) : ''
        const partC = getReviewInspectionFrequency(p.reviewMonths.length)
        const partD = i18n.t('customPdf.rentBasedOn', rentBasedOn)
        const partE = rentIsCalculated.map(rm => (i18n.t('pdf.rentIsCalculated', rm))).join(', ').replace(/, ([^,]*)$/, ` ${i18n.t('and')} $1`)
        const partF = p.rentReviewBasedOn === "CurrentRentAndLatestIndex" ? i18n.t("pdf.review_original_rent_info") : ""
        const dynamicReviewInfo = `${partA} ${partB} ${partC} ${partD} ${partE}. ${partF}`

        // Push values for rows
        rows.reviewType.push(p.reviewType)
        rows.paymentType.push(p.paymentType)
        if (p.basicIndexMonth && p.basicIndexYear) {
          rows.baseindex.push(p.basicIndexMonth + "/" + p.basicIndexYear)
        }
        rows.basicIndexPointNumber.push(p.basicIndexPointNumber)
        if (p.rentReviewStartDate) {
          const reviewInfo = [ formatDate(p.rentReviewStartDate), 100 ]
          rows.rentReviewStartDate.push(i18n.t('pdf.firstReview', reviewInfo))
        }
        rows.reviewMonths.push(p.reviewMonths.map(month => i18n.t(getMonthName(month))).join(', '))
        rows.indexMonths.push(p.indexMonths.map(month => i18n.t(getMonthName(month))).join(', '))
        rows.minimumIncrease.push(p.minimumIncrease ? `${p.minimumIncrease} %. ${i18n.t("pdf.indexLowerText")}` : i18n.t("pdf.indexLowerText"))
        rows.rentReviewBasedOn.push(i18n.t(p.rentReviewBasedOn))
        rows.reviewAdditionalInfo.push(p.reviewAdditionalInfo)
        rows.dynamicReviewInfo.push(dynamicReviewInfo)
      })

      // Create rowdata for Rent Review Table
      if (reviewTypeDef === "PaymentRentReviewIndex") {
        const obj = {}
        obj.id = reviewGroup[0].id
        obj.reviewTypeDef = reviewTypeDef
        for (const [key, value] of Object.entries(rows)) {
          const uniqueValues = [...new Set(value.filter(v => v != null && v != undefined && v != ""))] // remove duplicated and empty data
          if (value.length > 0) {
            obj[key] = uniqueValues.join('\n')
          }
        }
        rentReviews.push(obj)

      } else {
        rentReviews.push(
          {
            id: reviewGroup[0].id,
            reviewType: rows.reviewType.join("\n"),
            reviewTypeDef: reviewTypeDef,
            paymentType: rows.paymentType.join("\n"),
            netSum: netSum !== undefined ? `${helpers.humanize.amount_long(Number(netSum), 2).replace('.',',')} €` : '',
          }
        ) 
      }
    }
  }
  return rentReviews
}

const getRentData = (contractPayments, separatePayments, selectedTargets, vatCount, billingCycleLength, firstBillingMonth) => {
  // Get the erliest payment date
  const earliestPaymentDate = [...contractPayments, ...separatePayments].reduce((pre, cur) => Date.parse(pre.startDate) > Date.parse(cur.startDate) ? cur : pre).startDate
  const getPaymentsTotals = (payments) => {
    // Only valid payments are counted
    const validPayments = payments.filter(p => Date.parse(p.startDate) === Date.parse(earliestPaymentDate))
    // For area based payments
    const totalArea = selectedTargets.reduce((acc, cur) => acc + cur?.agreedArea, 0)
    // For pcs based payments
    const totalPsc = validPayments.reduce((acc, cur) => acc + cur?.pcsCount, 0)
    const netSquareDenominator = validPayments[0]?.invoicingBasis === 'Pinta-ala' ? totalArea : totalPsc

    return {
      net: validPayments.reduce((acc, cur) => acc + cur?.sums.netSum, 0),
      gross: validPayments.reduce((acc, cur) => acc + cur?.sums.grossSum, 0),
      netSquare: validPayments.reduce((acc, cur) => acc + cur?.sums.netSum, 0) / netSquareDenominator,
      grossSquare: validPayments.reduce((acc, cur) => acc + cur?.sums.grossSum, 0) / netSquareDenominator,
      tax: validPayments.reduce((acc, cur) => acc + cur?.sums.tax, 0),
      invoicingBasisList: validPayments.map(p => p?.invoicingBasis),
      processingModeList: validPayments.map(p => p?.processingMode),
      vatList: validPayments.map(p => p?.paymentVat + ' %')
    }
  }

  const getTotalsText = (sums) => {
    if (!sums.net) return

    const humanize = (num) => num ? helpers.humanize.amount_long(Number(num), 2).replace('.',',') : ''
    // Remove empty and duplicated data
    const invoicingBasis = [...new Set(sums.invoicingBasisList.filter(ib => ib !== ""))]
    const processingMode = [...new Set(sums.processingModeList.filter(pm => pm !== ""))]
    // Add default processing mode
    if (invoicingBasis[0] !== 'Kpl') processingMode.push(i18n.t("pdf.euro_m2_month"))

    const totalsText = [
      {
        name: `${i18n.t('Exempt from taxation')}:`,
        total: `${humanize(sums.net)} €`,
        show: true,
      },
      {
        name: `${i18n.t('Taxable')}:`,
        total: `${humanize(sums.gross)} €`,
        show: sums.tax !== 0
      },
      {
        name: `${i18n.t('Tax')}:`,
        total: `${humanize(sums.tax)} € ${[...new Set(sums.vatList)].join(', ')}`,
        show: sums.tax !== 0
      }
    ]

    return totalsText.map(t => t.show ? `${t.name} ${t.total}` : '').filter(t => t !== "").join('\n')
  }

  const contractSums = getPaymentsTotals(contractPayments)
  const separateSums = getPaymentsTotals(separatePayments)

  return {
    rentPerMonth: getTotalsText(contractSums),
    separateRentPerMonth: getTotalsText(separateSums),
    due: billingCycleLength == 12 ? i18n.t("pdf.alt_due_text") + i18n.t(getMonthName(firstBillingMonth)) : i18n.t("pdf.due_text"),
    rentPeriod: billingCycleLength ? billingCycleLength + " kk" : "",
    latePaymentInterest: i18n.t("pdf.according_to_the_interest_act"),
    isVat: vatCount > 0
  }
}

export default { 
  getCustomData, 
  getVatCount,
  getBuildingAddressForFutureBuilding,
}