import { i18n } from '../../plugins/i18n'
import { helpers as chartHelpers } from 'chart.js'
import {
  contractsColors,
  prospectLikelihoodColors,
  prospectActivityColors,
  prospectStatusColors,
  prospectTypeColors,
  generalColors,
  contractValidityColors,
  rentalProcessStageColors,
  stageColors,
  circleBlue,
} from '../../constants/colors'
import helpers from './../../helpers'
import moment from 'moment'

function generateLabelsWithValuesAndPercentages (format) {
  function generateLabels (chart) {
    const data = chart.data
    if (data.labels.length && data.datasets.length) {
      return data.labels.map((label, index) => {
        const ds = data.datasets[0]

        // Get label color options
        const getValueAtIndexOrDefault = chartHelpers.getValueAtIndexOrDefault
        const arcOpts = chart.options.elements.arc
        const fill = getValueAtIndexOrDefault(
          ds.backgroundColor,
          index,
          arcOpts.backgroundColor
        )
        const stroke = getValueAtIndexOrDefault(
          ds.borderColor,
          index,
          arcOpts.borderColor
        )
        const bw = getValueAtIndexOrDefault(
          ds.borderWidth,
          index,
          arcOpts.borderWidth
        )
        
        // Get hidden value from metadata
        const chartMetadata = chart.getDatasetMeta(0)
        const hidden = chartMetadata.data[index].hidden

        // Get label values and percentages
        const value = ds.data[index]
        const total = ds.data.reduce((acc, curr) => acc + curr, 0)
        const percentage = (value / total) * 100
        let percentageString = percentage.toFixed(2).toString()

        // Change percentage decimal pointer based on locale
        if (i18n.locale === 'fi') {
          percentageString = percentageString.replace('.', ',')
        }
        return {
          text:
            `${label 
            }: ${ 
            helpers.format.formatData(value, format) 
            }, ${ 
            percentageString 
            }%`,
          fillStyle: fill,
          strokeStyle: stroke,
          lineWidth: bw,
          index: index,
          hidden
        }
      })
    }
    return []
  }
  return generateLabels
}

const prospectColors = {
  ...prospectTypeColors,
  ...prospectLikelihoodColors,
  ...prospectStatusColors,
  ...prospectActivityColors,
}

function siteActivityCustomColors (label) {
  return prospectColors[label] ?? 'black'
}

function validityColors (label){
  return contractValidityColors[label]
}

function sortBiggestActiveProspects (a, b, option, set) {
  if (option === 'Odds') {
    if (a.success_likelihood === b.success_likelihood) {
      return b[set.sortField] - a[set.sortField]
    }
    
      return a.success_likelihood.localeCompare(b.success_likelihood )
    
    
  }
  else if (option === 'Area') {
    return b[set.sortField] - a[set.sortField]
  }
}

function getThemeColor (index) {
  return `hsl(${204 + 45 * index}, 82%, 72%)`
}

export default {
  OwnActiveLeft () {
    return [
      {
        id: 'leasing.my_active_renting_processes',
        type: 'PieDataGraph',
        data: {
          items: [],
          value: 'free_space_area',
          sets: [
            {
              title: i18n.t('leasing.renting_stage'),
              groupBy: 'stage',
              method: 'sum',
              parameters: ['value'],
              format: 'Area'
            },
          ],
          colors: rentalProcessStageColors,
          showAreaTooltip: true,
          showDatasetNamesAsTitle: true,
          customLabelFunction: generateLabelsWithValuesAndPercentages,
        },
        small: true,
        loading: true,
      },
    ]
  },
  OwnActiveRight () {
    return [
      {
        id: 'leasing.my_rental_processes',
        type: 'ObjectTable',
        customDefaultFilter: true,
        data: {
          headers: [
            { text: 'Rental_event', value: 'process_title' },
            { text: 'Tenant corporation', value: 'tenant', leasing: true },
            { text: 'Site', value: 'site' },
            {
              text: 'prospect.m2_requirement',
              value: 'area_preference',
              format: 'Area',
            },
            { text: 'Updated', value: 'last_modified', format: 'Date' },
            { text: 'leasing.stage', value: 'stage' },
            { text: 'leasing.status', value: 'prospect_status' },
            {
              text: 'prospect.location_municipalities',
              value: 'city_preference',
            },
            { text: 'Usage types', value: 'unit_type' },
            { text: 'prospect.estimated_completion', value: 'estimated_completion', format: 'Quarter'},
            { text: 'prospect.estimated_value', value: 'estimated_value', format: 'Euro'},
            { text: '', value: 'toggle', type: 'toggle' },
          ],
          browseHeaders: [
            { text: 'Rental_event', value: 'process_title', leasing: true },
            { text: 'Type', value: 'prospect_type_text', leasing: true },
            { text: 'Party', value: 'tenant', leasing: true },
            { text: 'Sites', value: 'sitesString' },
            {
              text: 'prospect.m2_requirement',
              value: 'area_preference',
              format: 'Area',
            },
            { text: 'Updated', value: 'last_modified', format: 'Date' },
            { text: 'prospect.first_responsible', value: 'first_responsible' },
            {
              text: 'prospect.second_responsible',
              value: 'secondary_responsible',
            },
            { text: 'Created', value: 'created', format: 'Date' },
            {
              text: 'leasing.status',
              value: 'prospect_fullscreen_status',
              leasing: true,
            },
            {
              text: 'prospect.location_municipalities',
              value: 'city_preference',
            },
            { text: 'Usage types', value: 'unit_type' },
            { text: 'leasing.stage', value: 'stage' },
            { text: 'leasing.stage_changed', value: 'stageChanged' },
            { text: 'prospect.estimated_completion', value: 'estimated_completion', format: 'Quarter'},
            { text: 'prospect.estimated_value', value: 'estimated_value', format: 'Euro'},
          ],
          filter: {
            header: 'prospect_status',
            type: 'equal',
            options: [
              { text: 'prospect.show_only_actives', value: 'Active' },
              { text: 'prospect.show_only_inactives', value: 'Inactive' },
              { text: 'prospect.show_only_closed', value: 'Closed' },
              { text: 'prospect.show_all', value: null },
            ],
            defaultFilter: {
              text: 'prospect.show_only_actives',
              value: 'Active',
            },
          },
          footers: [
            { text: i18n.t('Total') },
            {},
            {},
            { value: 'area_preference', unit: 'area', format: 'Area' },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ],
          items: [],
          sortDesc: false,
          ignoreSubitemsInTable: true,
        },
        maximized: [true],
        loading: true,
      },
    ]
  },
  OccupancyRates () {
    return {
      id: 'leasing.occupancy_rates',
      type: 'PieDataGraph',
      data: {
        items: [],
        sets: [
          {
            title: i18n.t('leasing.technical_rentable_area'),
            groupBy: 'status',
            method: 'sum',
            parameters: ['area'],
            format: 'Area',
            info: 'leasing.occupancy_rate_info',
            detailsHeaders: [
              { text: i18n.t('Site'), value: 'siteName' },
              { text: i18n.t('Commercial name'), value: 'commercialName' },
              { text: i18n.t('leasing.rentable_area'), value: 'totalArea', format: 'Area' },
              { text: i18n.t('leasing.rented_room_area'), value: 'rentedArea', format: 'Area' },
              { text: i18n.t('leasing.free_and_future_contracts'), value: 'futureRentedArea', format: 'Area' },
              { text: i18n.t('leasing.free_unit_area'), value: 'freeArea', format: 'Area' },
              { text: i18n.t('Technical usage rate'), value: 'techincalUsageRate', format: 'Percentage' },
            ],
            detailsFooters: [
              { text: i18n.t('Total') },
              {},
              { value: 'totalArea', format: 'Area' },
              { value: 'rentedArea', format: 'Area' },
              { value: 'futureRentedArea', format: 'Area' },
              { value: 'freeArea', format: 'Area' },
              { value: 'techincalUsageRate', format: 'Percentage', average: true },
            ]
          },
          {
            title: i18n.t('leasing.technical_unit_count'),
            groupBy: 'status',
            method: 'sum',
            parameters: ['unitCount'],
            format: 'Amount',
            info: 'leasing.carpark_occupancy_rate_info',
            detailsHeaders: [
              { text: i18n.t('Site'), value: 'siteName' },
              { text: i18n.t('Commercial name'), value: 'commercialName' },
              { text: i18n.t('leasing.rentable_units_total'), value: 'totalUnitCount', format: 'Amount' },
              { text: i18n.t('leasing.rented_units'), value: 'rentedUnitCount', format: 'Amount' },
              { text: i18n.t('leasing.free_and_future_contract'), value: 'futureRentedUnitCount', format: 'Amount' },
              { text: i18n.t('leasing.free_units_pcs'), value: 'freeUnitCount', format: 'Amount' },
              { text: i18n.t('leasing.units_rate'), value: 'unitRate', format: 'Percentage' },
            ],
          },
          {
            title: i18n.t('leasing.technical_carpark_count'),
            groupBy: 'status',
            method: 'sum',
            parameters: ['carparkCount'],
            format: 'Amount',
            info: 'leasing.carpark_occupancy_rate_info',
            detailsHeaders: [
              { text: i18n.t('Site'), value: 'siteName' },
              { text: i18n.t('Commercial name'), value: 'commercialName' },
              { text: i18n.t('leasing.carparks_rented'), value: 'carparksRented', format: 'Amount' },
              { text: i18n.t('leasing.free_and_future_rented'), value: 'carparksFutureRented', format: 'Amount' },
              { text: i18n.t('leasing.carparks_free_count'), value: 'freeCarparks', format: 'Amount' },
              { text: i18n.t('leasing.all_carparks_'), value: 'totalCarparkCount', format: 'Amount' },
            ],
          },
          {
            title: i18n.t('leasing.economic_combined'),
            groupBy: 'status',
            method: 'sum',
            parameters: ['capital'],
            format: 'EuroPerMonth',
            info: 'leasing.economic_combined_info',
            detailsHeaders: [
              { text: i18n.t('Site'), value: 'siteName' },
              { text: i18n.t('Commercial name'), value: 'commercialName' },
              { text: i18n.t('leasing.current_capital_total_units'), value: 'unitCapitalCurrent' , format: 'EuroPerMonth' },
              { text: i18n.t('leasing.current_capital_total_carparks'), value: 'carparkCapitalCurrent' , format: 'EuroPerMonth' },
              { text: i18n.t('leasing.future_capital_total'), value: 'capitalFuture' , format: 'EuroPerMonth' },
              { text: i18n.t('leasing.market_rent_total_units'), value: 'unitMarketRentTotal' , format: 'EuroPerMonth' },
              { text: i18n.t('leasing.market_rent_totals_carparks'), value: 'carparkMarketRentTotal' , format: 'EuroPerMonth' },
              { text: i18n.t('leasing.market_rent_free_units'), value: 'marketRentFree' , format: 'EuroPerMonth' },
              { text: i18n.t('leasing.occupancy_rate_economic'), value: 'economicOccupancy' , format: 'Percentage' },
            ],
            detailsFooters: [
              { text: i18n.t('Total') },
              {},
              { value: 'unitCapitalCurrent', format: 'EuroPerMonth' },
              { value: 'carparkCapitalCurrent', format: 'EuroPerMonth' },
              { value: 'capitalFuture', format: 'EuroPerMonth' },
              { value: 'unitMarketRentTotal', format: 'EuroPerMonth' },
              { value: 'carparkMarketRentTotal', format: 'EuroPerMonth' },
              { value: 'marketRentFree', format: 'EuroPerMonth' },
              { value: 'economicOccupancy', format: 'Percentage', average: true },
            ],
          },
        ],
        colors: contractsColors,
        small: true,
        showPercentages: true,
        showDatasetNamesAsTitle: true,
        customLabelFunction: generateLabelsWithValuesAndPercentages,
        report: 'occupancyRates',
        summaryText: i18n.t('leasing.occupancy_rate_combined'),
        detailsRowsPerPage: 15
      },
      small: true,
      loading: true,
    }
  },
  EndingContractsGraph (smallSize = true) {
    return {
      id: 'site.leasing.ending_contracts_graph',
      type: 'VerticalStackedGraph',
      small: smallSize,
      loading: true,
      data: {
        cumulative: [],
        headers: {
          fixedTerm: 
            [
              { text: i18n.t('Inactive'), value: 'fixedTermInactiveArea', color: rentalProcessStageColors.Inactive, group: 'area' },
              { text: i18n.t('ProspectPending'), value: 'fixedTermPendingArea', color: '#707070', group: 'area' },
              { text: i18n.t('leasing.not_relevant'), value: 'fixedTermNotRelevantArea', color: '#acabab', group: 'area' },
              { text: i18n.t('leasing.begin_later'), value: 'fixedTermStartLaterArea', color: '#78adcf', group: 'area' },
              { text: i18n.t('1. Lead'), value: 'fixedTermLeadArea', color: rentalProcessStageColors['1. Lead'], group: 'area' },
              { text: i18n.t('2. Prospect'), value: 'fixedTermProspectArea', color: rentalProcessStageColors['2. Prospect'], group: 'area' },
              { text: i18n.t('3. NeedsAssessment'), value: 'fixedTermNeedsAssessmentArea', color: rentalProcessStageColors['3. NeedsAssessment'], group: 'area' },
              { text: i18n.t('4. Proposal'), value: 'fixedTermProposalArea', color: rentalProcessStageColors['4. Proposal'], group: 'area' },
              { text: i18n.t('5. ContractNegotiation'), value: 'fixedTermContractNegotiationArea', color: rentalProcessStageColors['5. ContractNegotiation'], group: 'area' },
              { text: i18n.t('6. Won'), value: 'fixedTermWonArea', color: rentalProcessStageColors['6. Won'], group: 'area' },
              { text: i18n.t('7. RentAdministration'), value: 'fixedTermRentAdministrationArea', color: rentalProcessStageColors['7. RentAdministration'] , group: 'area' },
              { text: i18n.t('8. Closed'), value: 'fixedTermClosedArea', color: rentalProcessStageColors['8. Closed'], group: 'area' },
              
              { text: i18n.t('Inactive'), value: 'fixedTermInactiveRent', color: rentalProcessStageColors.Inactive, group: 'rent' },
              { text: i18n.t('ProspectPending'), value: 'fixedTermPendingRent', color: '#707070', group: 'rent' },
              { text: i18n.t('leasing.not_relevant'), value: 'fixedTermNotRelevantRent', color: '#707070', group: 'rent' },
              { text: i18n.t('leasing.begin_later'), value: 'fixedTermStartLaterRent', color: '#707070', group: 'rent' },
              { text: i18n.t('1. Lead'), value: 'fixedTermLeadRent', color: rentalProcessStageColors['1. Lead'], group: 'rent' },
              { text: i18n.t('2. Prospect'), value: 'fixedTermProspectRent', color: rentalProcessStageColors['2. Prospect'], group: 'rent' },
              { text: i18n.t('3. NeedsAssessment'), value: 'fixedTermNeedsAssessmentRent', color: rentalProcessStageColors['3. NeedsAssessment'], group: 'rent' },
              { text: i18n.t('4. Proposal'), value: 'fixedTermProposalRent', color: rentalProcessStageColors['4. Proposal'], group: 'rent' },
              { text: i18n.t('5. ContractNegotiation'), value: 'fixedTermContractNegotiationRent', color: rentalProcessStageColors['5. ContractNegotiation'], group: 'rent' },
              { text: i18n.t('6. Won'), value: 'fixedTermWonRent', color: rentalProcessStageColors['6. Won'], group: 'rent' },
              { text: i18n.t('7. RentAdministration'), value: 'fixedTermRentAdministrationRent', color: rentalProcessStageColors['7. RentAdministration'] , group: 'rent' },
              { text: i18n.t('8. Closed'), value: 'fixedTermClosedRent', color: rentalProcessStageColors['8. Closed'], group: 'rent' },
            ],
            permanent:
            [
              { text: i18n.t('Inactive'), value: 'permanentInactiveArea', color: rentalProcessStageColors.Inactive, group: 'area' },
              { text: i18n.t('ProspectPending'), value: 'permanentPendingArea', color: '#707070', group: 'area' },
              { text: i18n.t('leasing.not_relevant'), value: 'permanentNotRelevantArea', color: '#acabab', group: 'area' },
              { text: i18n.t('leasing.begin_later'), value: 'permanentStartLaterArea', color: '#78adcf', group: 'area' },
              { text: i18n.t('1. Lead'), value: 'permanentLeadArea', color: rentalProcessStageColors['1. Lead'], group: 'area' },
              { text: i18n.t('2. Prospect'), value: 'permanentProspectArea', color: rentalProcessStageColors['2. Prospect'], group: 'area' },
              { text: i18n.t('3. NeedsAssessment'), value: 'permanentNeedsAssessmentArea', color: rentalProcessStageColors['3. NeedsAssessment'], group: 'area' },
              { text: i18n.t('4. Proposal'), value: 'permanentProposalArea', color: rentalProcessStageColors['4. Proposal'], group: 'area' },
              { text: i18n.t('5. ContractNegotiation'), value: 'permanentContractNegotiationArea', color: rentalProcessStageColors['5. ContractNegotiation'], group: 'area' },
              { text: i18n.t('6. Won'), value: 'permanentWonArea', color: rentalProcessStageColors['6. Won'], group: 'area' },
              { text: i18n.t('7. RentAdministration'), value: 'permanentRentAdministrationArea', color: rentalProcessStageColors['7. RentAdministration'] , group: 'area' },
              { text: i18n.t('8. Closed'), value: 'permanentClosedArea', color: rentalProcessStageColors['8. Closed'], group: 'area' },
              
              { text: i18n.t('Inactive'), value: 'permanentInactiveRent', color: rentalProcessStageColors.Inactive, group: 'rent' },
              { text: i18n.t('ProspectPending'), value: 'permanentPendingRent', color: '#707070', group: 'rent' },
              { text: i18n.t('leasing.not_relevant'), value: 'permanentNotRelevantRent', color: '#acabab', group: 'rent' },
              { text: i18n.t('leasing.begin_later'), value: 'permanentStartLaterRent', color: '#78adcf', group: 'rent' },              
              { text: i18n.t('1. Lead'), value: 'permanentLeadRent', color: rentalProcessStageColors['1. Lead'], group: 'rent' },
              { text: i18n.t('2. Prospect'), value: 'permanentProspectRent', color: rentalProcessStageColors['2. Prospect'], group: 'rent' },
              { text: i18n.t('3. NeedsAssessment'), value: 'permanentNeedsAssessmentRent', color: rentalProcessStageColors['3. NeedsAssessment'], group: 'rent' },
              { text: i18n.t('4. Proposal'), value: 'permanentProposalRent', color: rentalProcessStageColors['4. Proposal'], group: 'rent' },
              { text: i18n.t('5. ContractNegotiation'), value: 'permanentContractNegotiationRent', color: rentalProcessStageColors['5. ContractNegotiation'], group: 'rent' },
              { text: i18n.t('6. Won'), value: 'permanentWonRent', color: rentalProcessStageColors['6. Won'], group: 'rent' },
              { text: i18n.t('7. RentAdministration'), value: 'permanentRentAdministrationRent', color: rentalProcessStageColors['7. RentAdministration'] , group: 'rent' },
              { text: i18n.t('8. Closed'), value: 'permanentClosedRent', color: rentalProcessStageColors['8. Closed'], group: 'rent' },
            ],
          all:
          [
            { text: i18n.t('leasing.fixed_term_contracts'), value: 'allFixedTermArea', color: '#8f7df2', group: 'area' },
            { text: i18n.t('leasing.permanent_contracts'), value: 'allPermanentArea', color: '#7dc3f2', group: 'area' },
            { text: i18n.t('leasing.fixed_term_contracts'), value: 'allFixedTermRent', color: '#8f7df2', group: 'rent' },
            { text: i18n.t('leasing.permanent_contracts'), value: 'allPermanentRent', color: '#7dc3f2', group: 'rent' },
          ]
        },
        sets: [
          {
            text: i18n.t('prospect.show_all'),
            value: 'all',
          },
          {
            text: i18n.t('leasing.fixed_term'),
            value: 'fixedTerm',
          },
          {
            text: i18n.t('leasing.permanent'),
            value: 'permanent',
          },
        ],
        groups: [
          { text: 'm\u00B2', value: 'area', unit: 'm\u00B2', subtitle: '' },
          { text: '€/kk', value: 'rent', unit: '€/kk', subtitle: '' }
        ],
        rangeSlider: true,
        items: [],
        column: 'year',
        report: 'endingContractsGraph',
        infoText: 'leasing.ending_contracts_graph_info',
        lineItems: [],
        lineReport: 'endingContractsGraphLine',
        lineItemsLegend: 'Occupancy rate at the end of year',
        lineUnit: '%',
        lineColor: '#acabab',
      },
    }
  },
  GeneralSituation () {
    return [
      {
        id: 'leasing.leasing_progress',
        type: 'VerticalStackedGraph',
        data: {
          cumulative: [i18n.t('Cumulative sum')],
          headers: {
            rented: [
              {
                text: i18n.t('leasing.ending_contracts_permanent'),
                value: 'rentedRenewals',
                color: '#f389d6',
              },
              {
                text: i18n.t('leasing.ending_contracts_fixed_term'),
                value: 'rentedRenegotiations',
                color: '#2A74B5',
              },
              {
                text: i18n.t('leasing.new_negotiation'),
                value: 'rentedNew',
                color: '#4caf50',
              },
              {
                text: i18n.t('leasing.ending_fixed'),
                value: 'endedFixedTermArea',
                color: '#bf1ef8',
              },
              {
                text: i18n.t('leasing.terminated_permanent'),
                value: 'endedPermanentArea',
                color: '#ff0000',
              }
            ],
            marketRent: [
              {
                text: i18n.t('leasing.ending_contracts_permanent'),
                value: 'marketRentRenewals',
                color: '#f389d6',
              },
              {
                text: i18n.t('leasing.ending_contracts_fixed_term'),
                value: 'marketRentRenegotiations',
                color: '#2A74B5',
              },
              {
                text: i18n.t('leasing.new_negotiation'),
                value: 'marketRentNew',
                color: '#4caf50',
              },
              {
                text: i18n.t('leasing.ending_fixed'),
                value: 'endedFixedTermMarketRent',
                color: '#bf1ef8',
              },
              {
                text: i18n.t('leasing.terminated_permanent'),
                value: 'endedPermanentMarketRent',
                color: '#ff0000',
              }
            ],
            contracts: [
              {
                text: i18n.t('leasing.ending_contracts_permanent'),
                value: 'contractsRenewals',
                color: '#f389d6',
              },
              {
                text: i18n.t('leasing.ending_contracts_fixed_term'),
                value: 'contractsRenegotiations',
                color: '#2A74B5',
              },
              {
                text: i18n.t('leasing.new_negotiation'),
                value: 'contractsNew',
                color: '#4caf50',
              },
              {
                text: i18n.t('leasing.ending_fixed'),
                value: 'endedFixedTermContracts',
                color: '#bf1ef8',
              },
              {
                text: i18n.t('leasing.terminated_permanent'),
                value: 'endedPermanentContracts',
                color: '#ff0000',
              }
            ],
          },
          sets: [
            {
              text: i18n.t('leasing.rented_area'),
              value: 'rented',
              unit: 'm\u00B2',
              info: i18n.t('leasing.leasing_progress_area_info'),
            },
            {
              text: i18n.t('Market rent carpark'),
              value: 'marketRent',
              unit: '€',
              info: i18n.t('leasing.leasing_progress_market_rent_info'),
            },
            {
              text: i18n.t('leasing.drawn_up_contracts'),
              value: 'contracts',
              unit: ' ',
            },
          ],
          lineItems: [],
          lineItemsLegend: 'Occupancy rate',
          lineUnit: '%',
          lineColor: '#acabab',
          rangeSlider: true,
          items: [],
          column: 'quarter',
          showTotals: true
        },
        small: false,
        loading: true,
      },
      {
        id: 'leasing.most_leased_by_site',
        type: 'HorizontalStackedGraph',
        data: {
          items: [],
          customColors: siteActivityCustomColors,
          itemSets: [
            { title: i18n.t('prospect.show_all'), value: null, fieldName: 'rental_type' },
            { title: i18n.t('leasing.new_negotiation'), value: 'leasing.new_negotiation', fieldName: 'rental_type' },
            { title: i18n.t('leasing.ending_contracts_fixed_term'), value: 'leasing.renegotiations', fieldName: 'rental_type' },
            { title: i18n.t('leasing.ending_contracts_permanent'), value: 'leasing.renewal', fieldName: 'rental_type' }
          ],
          sets: [
            {
              title: i18n.t('leasing.rented_area'),
              groupBy: 'siteName',
              groupValue: 'total_area',
              stackBy: 'rental_type',
              limit: 10,
              format: 'Area'
            },
            {
              title: i18n.t('Market rent carpark'),
              groupBy: 'siteName',
              groupValue: 'total_rent',
              stackBy: 'rental_type',
              limit: 10,
              format: 'Euro'
            },
          ],
          setButtonText: '€/m²'
        },
        small: false,
        loading: true,
      },
      {
        id: 'leasing.most_terminated_by_site',
        type: 'HorizontalStackedGraph',
        data: {
          items: [],
          customColors: validityColors,
          itemSets: [
            { title: i18n.t('leasing.newly_terminated'), value: true, fieldName: 'terminated' },
            { title: i18n.t('leasing.ended_contracts'), value: true, fieldName: 'ended' },
          ],
          sets: [
            {
              title: i18n.t('leasing.rented_area'),
              groupBy: 'siteName',
              groupValue: 'total_area',
              stackBy: 'rentalType',
              limit: 10,
              format: 'Area'
            },
            {
              title: i18n.t('Market rent carpark'),
              groupBy: 'siteName',
              groupValue: 'total_rent',
              stackBy: 'rentalType',
              limit: 10,
              format: 'Euro'
            },
          ],
          setButtonText: '€/m²'
        },
        small: false,
        loading: true,
      },
      {
        id: 'leasing.occupancy_rate_progress',
        type: 'VerticalStackedGraph',
        data: {
          cumulative: [],
          headers: {
            capital: [
              {
                text: i18n.t('Vuokrattu'),
                value: 'rented_capital_percentage',
                color: '#4cae4f',
                afterLabel: 'rented_capital',
                afterLabelFormat: 'Euro',
                showOnTooltip: true,
              },
              {
                text: i18n.t('Vapaana & tulevaisuudessa vuokrattu'),
                value: 'future_rented_capital_percentage',
                color: '#89f27d',
                afterLabel: 'future_rented_capital',
                afterLabelFormat: 'Euro',
                showOnTooltip: true,
              },
              {
                text: i18n.t('Vapaana'),
                value: 'free_capital_percentage',
                color: '#ff5252',
                afterLabel: 'free_capital',
                afterLabelFormat: 'Euro',
                showOnTooltip: true,
              },
              { 
                text: i18n.t('Occupancy rate'), 
                value: 'occupancy_rate_capital', 
                color: '#000', 
                tooltipOnly: true, 
                unit: '%', 
                decimals: 2
              },
            ],
            unitsArea: [
              {
                text: i18n.t('Vuokrattu'),
                value: 'rented_units_area_percentage',
                color: '#4cae4f',
                afterLabel: 'rented_units_area',
                afterLabelFormat: 'Area',
                showOnTooltip: true,
              },
              {
                text: i18n.t('Vapaana & tulevaisuudessa vuokrattu'),
                value: 'future_rented_units_area_percentage',
                color: '#89f27d',
                afterLabel: 'future_rented_units_area',
                afterLabelFormat: 'Area',
                showOnTooltip: true,
              },
              {
                text: i18n.t('Vapaana'),
                value: 'free_units_area_percentage',
                color: '#ff5252',
                afterLabel: 'free_units_area',
                afterLabelFormat: 'Area',
                showOnTooltip: true,
              },
              { 
                text: i18n.t('Occupancy rate'), 
                value: 'occupancy_rate_units_area', 
                color: '#000', 
                tooltipOnly: true, 
                unit: '%', 
                decimals: 2
              },
            ],
            units: [
              {
                text: i18n.t('Vuokrattu'),
                value: 'rented_units_percentage',
                color: '#4cae4f',
                afterLabel: 'rented_units',
                afterLabelFormat: 'Amount',
                showOnTooltip: true,
              },
              {
                text: i18n.t('Vapaana & tulevaisuudessa vuokrattu'),
                value: 'future_rented_units_percentage',
                color: '#89f27d',
                afterLabel: 'future_rented_units',
                afterLabelFormat: 'Amount',
                showOnTooltip: true,
              },
              {
                text: i18n.t('Vapaana'),
                value: 'free_units_percentage',
                color: '#ff5252',
                afterLabel: 'free_units',
                afterLabelFormat: 'Amount',
                showOnTooltip: true,
              },
              { 
                text: i18n.t('Occupancy rate'), 
                value: 'occupancy_rate_units', 
                color: '#000', 
                tooltipOnly: true, 
                unit: '%', 
                decimals: 2
              },
            ],
            carparks: [
              {
                text: i18n.t('Vuokrattu'),
                value: 'rented_carparks_percentage',
                color: '#4cae4f',
                afterLabel: 'rented_carparks',
                afterLabelFormat: 'Amount',
                showOnTooltip: true,
              },
              {
                text: i18n.t('Vapaana & tulevaisuudessa vuokrattu'),
                value: 'future_rented_carparks_percentage',
                color: '#89f27d',
                afterLabel: 'future_rented_carparks',
                afterLabelFormat: 'Amount',
                showOnTooltip: true,
              },
              {
                text: i18n.t('Vapaana'),
                value: 'free_carparks_percentage',
                color: '#ff5252',
                afterLabel: 'free_carparks',
                afterLabelFormat: 'Amount',
                showOnTooltip: true,
              },
              { 
                text: i18n.t('Occupancy rate'), 
                value: 'occupancy_rate_carparks', 
                color: '#000', 
                tooltipOnly: true, 
                unit: '%', 
                decimals: 2
              },
            ],
          },
          sets: [
            {
              text: i18n.t('leasing.economic_combined'),
              value: 'capital',
              unit: '%',
            },
            {
              text: i18n.t('leasing.technical_rentable_area'),
              value: 'unitsArea',
              unit: '%',
            },
            {
              text: i18n.t('leasing.units_count'),
              value: 'units',
              unit: '%',
            },
            {
              text: i18n.t('leasing.technical_carpark_count'),
              value: 'carparks',
              unit: '%',
            },
          ],
          rangeSlider: true,
          items: [],
          column: 'month',
          customTooltip: true
        },
        small: false,
        loading: true,
      },
    ]
  },
  Prospects (prospectType) {
    return [
      {
        id: 'leasing.all_prospects',
        type: 'ObjectTable',
        data: {
          headers: [
            { text: 'prospect', value: 'prospect_description' },
            { text: 'Tenant corporation', value: 'tenant', leasing: true },
            { text: 'Site', value: 'site' },
            {
              text: 'prospect.m2_requirement',
              value: 'unit_area_preference',
              format: 'Area',
            },
            { text: 'Updated', value: 'last_modified', format: 'Date' },
            { text: 'leasing.stage', value: 'stage' },
            { text: 'leasing.status', value: 'prospect_status' },
            { text: 'Odds', value: 'success_likelihood' },
            { text: 'Usage types', value: 'unit_type' },
            {
              text: 'prospect.location_municipalities',
              value: 'city_preference',
            },
            { text: 'prospect.estimated_completion', value: 'estimated_completion', format: 'Quarter' },
            { text: 'prospect.estimated_value', value: 'estimated_value', format: 'Euro' },
            { text: '', value: 'toggle', type: 'toggle' },
          ],
          browseHeaders: [
            { text: 'prospect', value: 'prospect_description', leasing: true },
            { text: 'Type', value: 'prospect_type_text', leasing: true },
            { text: 'Party', value: 'tenant', leasing: true },
            { text: 'Sites', value: 'sitesString' },
            {
              text: 'prospect.m2_requirement',
              value: 'unit_area_preference',
              format: 'Area',
            },
            { text: 'Updated', value: 'last_modified', format: 'Date' },
            {
              text: 'prospect.rental_contract_end_date',
              value: 'rental_contract_end_date',
              format: 'Date',
            },
            { text: 'prospect.first_responsible', value: 'first_responsible' },
            {
              text: 'prospect.second_responsible',
              value: 'secondary_responsible',
            },
            { text: 'Created', value: 'created', format: 'Date' },
            {
              text: 'leasing.status',
              value: 'prospect_fullscreen_status',
              leasing: true,
            },
            { text: 'Odds', value: 'success_likelihood', leasing: true },
            {
              text: 'prospect.location_municipalities',
              value: 'city_preference',
            },
            { text: 'Usage types', value: 'unit_type' },
            { text: 'leasing.stage', value: 'stage' },
            { text: 'leasing.stage_changed', value: 'stageChanged' },
            { text: 'prospect.estimated_completion', value: 'estimated_completion', format: 'Quarter' },
            { text: 'prospect.estimated_value', value: 'estimated_value', format: 'Euro' },
          ],
          filter: {
            header: 'prospect_status',
            type: 'equal',
            options: [
              { text: 'prospect.show_only_actives', value: 'Active' },
              { text: 'prospect.show_only_inactives', value: 'Inactive' },
              { text: 'prospect.show_only_closed', value: 'Closed' },
              { text: 'AwaitingAllocation', value: 'AwaitingAllocation' },
              { text: 'Done (no report)', value: 'Done (no report)' },
              { text: 'prospect.show_all', value: null },
            ],
          },
          footers: [
            { text: i18n.t('Total') },
            {},
            {},
            { value: 'unit_area_preference', unit: 'area', format: 'Area' },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
          ],
          items: [],
          sortDesc: false,
          ignoreSubitemsInTable: true,
        },
        small: false,
        maximized: [true],
        loading: true,
      },
      {
        id: 'leasing.all_active_renting_processes',
        type: 'PieDataGraph',
        data: {
          items: [],
          value: 'free_space_area',
          sets: [
            {
              title: i18n.t('prospect.activity_distribution'),
              groupBy: 'activity',
              method: 'sum',
              parameters: ['value'],
              format: 'Area'
            },
            {
              title: i18n.t('leasing.status'),
              groupBy: 'name',
              method: 'sum',
              parameters: ['value'],
              format: 'Area'
            },
          ],
          colors: prospectColors,
          showAreaTooltip: true,
          showDatasetNamesAsTitle: true,
          customLabelFunction: generateLabelsWithValuesAndPercentages,
        },
        small: true,
        loading: true,
      },
      {
        id: 'leasing.prospect_origins',
        type: 'PieDataGraph',
        data: {
          items: [],
          value: 'area',
          sets: [
            {
              title: i18n.t('prospect.show_all'),
              groupBy: 'allOrigin',
              method: 'sum',
              parameters: ["area"],
              format: 'Area'
            },
            {
              title: i18n.t('prospect.show_only_actives'),
              groupBy: 'activeOrigin',
              method: 'sum',
              parameters: ["area"],
              filterFunction: (item) => item.status === "Active",
              format: 'Area'
            },
            {
              title: i18n.t('prospect.show_only_inactives'),
              groupBy: 'inactiveOrigin',
              method: 'sum',
              parameters: ["area"],
              filterFunction: (item) => item.status === "Inactive",
              format: 'Area'
            },
            {
              title: i18n.t('prospect.show_only_closed'),
              groupBy: 'closedOrigin',
              method: 'sum',
              parameters: ["area"],
              filterFunction: (item) => item.status === "Closed",
              format: 'Area'
            },
            {
              title: i18n.t('AwaitingAllocation'),
              groupBy: 'awaitingAllocationOrigin',
              method: 'sum',
              parameters: ["area"],
              filterFunction: (item) => item.status === "AwaitingAllocation",
              format: 'Area'
            },
          ],
          showAreaTooltip: true,
          showDatasetNamesAsTitle: true,
          customLabelFunction: generateLabelsWithValuesAndPercentages,
        },
        small: true,
        loading: true,
      },
      {
        id: 'leasing.active_prospects_progress',
        type: 'VerticalStackedGraph',
        data: {
          cumulative: [i18n.t('Cumulative sum')],
          headers: {},
          dynamicHeaders: true,
          sets: [
            {
              text: i18n.t('prospect.prospects_area'),
              value: 'area',
              unit: 'm\u00B2',
              info: i18n.t('leasing.active_prospects_progress_info'),
            },
            {
              text: i18n.t('prospect.prospects_amount'),
              value: 'amount',
              unit: ' ',
              info: i18n.t('leasing.active_prospects_progress_info'),
            },
          ],
          groupButtonText: 'leasing.active_prospects_progress_group_button',
          groups: [
            {
              text: 'Date of creation',
              value: 'created',
              subtitle: 'Date of creation',
            },
            {
              text: 'prospect.active_per_given_date',
              value: 'active',
              subtitle: 'prospect.active_per_given_date',
            },
          ],
          rangeSlider: true,
          items: [],
          column: 'quarter',
          customTooltip: true,
        },
        small: false,
        loading: true,
      },
      {
        id: 'leasing.site_activity',
        type: 'HorizontalStackedGraph',
        data: {
          items: [],
          infoText: i18n.t("leasing.site_activity_info"),
          customColors: siteActivityCustomColors,
          sets: [
            {
              title: i18n.t('prospect.prospects_area'),
              groupBy: 'siteName', // Defines by which field we will from the rows
              groupValue: 'unit_area_preference', // The length of the bar is calculated using this field in the provided objects
              stackBy:
                prospectType && prospectType.value !== 'all'
                  ? 'likelihood'
                  : 'prospectType', // If the data set needs to be stacked, provide variable name here. This will create datasets based on this variable.
              limit: 5,
              format: 'Area'
            },
            {
              // Without groupValue variable, will default to the number of items in each group
              title: i18n.t('prospect.prospects_amount'),
              groupBy: 'siteName', // Defines by which field we will from the rows
              stackBy:
                prospectType && prospectType.value !== 'all'
                  ? 'likelihood'
                  : 'prospectType', // // If the data set needs to be stacked, provide variable name here. This will create datasets based on this variable.
              limit: 5,
              format: 'Number'
            },
          ],
        },
        small: false,
        loading: true,
      },
      {
        id: 'leasing.biggest_active_prospects',
        type: 'HorizontalGroupedBarDataGraph',
        data: {
          items: [],
          limit: 10,
          sets: [],
          customLegend: true,
          dynamicSets: true,
          customSort: true,
          sortOptions: ['Odds', 'Area'],
          sortFunction: sortBiggestActiveProspects,
          clickable: true
        },
        plugins: [{
          beforeInit: function (chart) {
            chart.data.labels.forEach(function (e, i, a) {
                if (/\n/.test(e)) {
                  a[i] = e.split(/\n/);
                }
            });
          },
        }],
        small: false,
        loading: false,
      },
      {
        id: 'leasing.inactive_prospects',
        type: 'HorizontalGroupedBarDataGraph',
        data: {
          items: [],
          limit: 10,
          sets: [
            {
              title: i18n.t('Share of squares'),
              groupBy: 'status_description',
              format: 'Percentage',
              sortField: 'unit_area_preference',
              groups: [
                {
                  value: 'unit_area_preference',
                  label: i18n.t('prospect.area_requirement'),
                },
              ],
            },
          ],  
        },
        small: false,
        loading: false,
      },
    ]
  },
  RenegotiationsAndRenewals () {
    return [
      {
        id: 'leasing.ending_contracts_fixed_term',
        type: 'ObjectTable',
        data: {
          headers: [
            { text: 'Tenant', value: 'tenant', leasing: true },
            { text: 'Site', value: 'siteName' },
            {
              text: 'leasing.rentable_area',
              value: 'totalArea',
              format: 'Area',
            },
            { text: 'leasing.end_date', value: 'endDate', format: 'Date' },
            {
              text: 'leasing.contract_total_market_rent',
              value: 'contractTotalMarketRent',
              format: 'EuroPerMonth',
            },
            { text: 'leasing.renegotiations_status', value: 'prospect_status' },
            { text: 'leasing.renegotiations', value: 'renegotiations' },
          ],
          browseHeaders: [
            { text: 'Tenant', value: 'tenant', leasing: true },
            { text: 'Site', value: 'siteName' },
            { text: 'Identifier', value: 'site_identifier' },
            {
              text: 'leasing.rentable_area',
              value: 'totalArea',
              format: 'Area',
            },
            { text: 'leasing.end_date', value: 'endDate', format: 'Date' },
            {
              text: 'leasing.contract_total_market_rent',
              value: 'contractTotalMarketRent',
              format: 'EuroPerMonth',
            },
            { text: 'Contract number', value: 'contractNumber' },
            {
              text: 'leasing.renegotiations_status',
              value: 'prospect_fullscreen_status',
              leasing: true,
            },
            {
              text: 'leasing.renegotiations',
              value: 'renegotiations',
              leasing: true,
            },
          ],
          filter: {
            header: 'prospect_status',
            type: 'equal',
            options: [
              { text: 'ProspectPending', value: 'ProspectPending' },
              { text: '1. Lead', value: '1. Lead'  },
              { text: '2. Prospect', value: '2. Prospect' },
              { text: '3. NeedsAssessment', value: '3. NeedsAssessment' },
              { text: '4. Proposal', value: '4. Proposal' },
              { text: '5. ContractNegotiation', value: '5. ContractNegotiation' },
              { text: '6. Won', value: '6. Won' },
              { text: '7. RentAdministration', value: '7. RentAdministration' },
              { text: '8. Closed', value: '8. Closed'},
              { text: 'prospect.show_only_inactives', value: 'Inactive' },
              { text: 'Technical modification', value: 'Technical modification' },
              { text: 'leasing.not_relevant', value: 'Ei ajankohtainen' },
              { text: 'prospect.show_all', value: null },
            ],
          },
          footers: [
            { text: i18n.t('Total') },
            { value: 'siteName', unique: true, format: 'Amount' },
            { value: 'totalArea', format: 'Area' },
            {},
            {},
            { value: 'prospect_status', format: 'Amount' },
            {},
          ],
          //Order doesn't matter for browseFooters. Needs browseHeader with the same value. Use if normal footers don't have same values or formats
          browseFooters: [
            { value: 'siteName', unique: true, format: 'Amount' },
            { value: 'totalArea', format: 'Area' },
            { value: 'prospect_fullscreen_status', format: 'Amount' },
          ],
          items: [],
          itemsPerPage: 10,
          infoText: "leasing.ending_contracts_info"
        },
        small: false,
        loading: true,
      },
      {
        id: 'leasing.ending_contracts_permanent',
        type: 'ObjectTable',
        data: {
          headers: [
            { text: 'Tenant', value: 'tenant', leasing: true },
            { text: 'Site', value: 'siteName' },
            {
              text: 'leasing.rentable_area',
              value: 'totalArea',
              format: 'Area',
            },
            {
              text: 'leasing.contract_first_possible_end_date_short',
              value: 'contractFirstPossibleEndDate',
              format: 'Date',
            },
            { text: 'leasing.end_date', value: 'endDate', format: 'Date' },
            {
              text: 'leasing.contract_total_market_rent',
              value: 'contractTotalMarketRent',
              format: 'EuroPerMonth',
            },
            { text: 'leasing.renewal_status', value: 'prospect_status' },
            { text: 'leasing.renewal', value: 'renewal' },
          ],
          browseHeaders: [
            { text: 'Tenant', value: 'tenant', leasing: true },
            { text: 'Site', value: 'siteName' },
            { text: 'Identifier', value: 'site_identifier' },
            {
              text: 'leasing.rentable_area',
              value: 'totalArea',
              format: 'Area',
            },
            {
              text: 'leasing.contract_first_possible_end_date_short',
              value: 'contractFirstPossibleEndDate',
              format: 'Date',
            },
            { text: 'leasing.end_date', value: 'endDate', format: 'Date' },
            {
              text: 'leasing.contract_total_market_rent',
              value: 'contractTotalMarketRent',
              format: 'EuroPerMonth',
            },
            { text: 'Contract number', value: 'contractNumber' },
            {
              text: 'leasing.renewal_status',
              value: 'prospect_fullscreen_status',
              leasing: true,
            },
            { text: 'leasing.renewal', value: 'renewal', leasing: true },
          ],
          filter: {
            header: 'prospect_status',
            type: 'equal',
            options: [
              { text: 'ProspectPending', value: 'ProspectPending' },
              { text: '1. Lead', value: '1. Lead'  },
              { text: '2. Prospect', value: '2. Prospect' },
              { text: '3. NeedsAssessment', value: '3. NeedsAssessment' },
              { text: '4. Proposal', value: '4. Proposal' },
              { text: '5. ContractNegotiation', value: '5. ContractNegotiation' },
              { text: '6. Won', value: '6. Won' },
              { text: '7. RentAdministration', value: '7. RentAdministration' },
              { text: '8. Closed', value: '8. Closed'},
              { text: 'prospect.show_only_inactives', value: 'Inactive' },
              { text: 'Technical modification', value: 'Technical modification' },
              { text: 'leasing.not_relevant', value: 'Ei ajankohtainen' },
              { text: 'prospect.show_all', value: null },
            ],
          },
          footers: [
            { text: i18n.t('Total') },
            { value: 'siteName', unique: true, format: 'Amount' },
            { value: 'totalArea', format: 'Area' },
            {},
            {},
            {},
            { value: 'prospect_status', format: 'Amount' },
            {},
          ],
          //Order doesn't matter for browseFooters. Needs browseHeader with the same value. Use if normal footers don't have same values or formats
          browseFooters: [
            { value: 'siteName', unique: true, format: 'Amount' },
            { value: 'totalArea', format: 'Area' },
            { value: 'prospect_fullscreen_status', format: 'Amount' },
          ],
          items: [],
          itemsPerPage: 10,
          infoText: "leasing.ending_contracts_info"
        },
        small: false,
        loading: true,
      },
      this.EndingContractsGraph(false),
      {
        id: 'leasing.largest_ending_contracts',
        type: 'HorizontalGroupedBarDataGraph',
        data: {
          items: [],
          limit: 10,
          setsAreValueAreaType: true,
          customLegend: true,
          itemSetTypes: [
            i18n.t('prospect.show_all'), 
            i18n.t('Fixed term'), 
            i18n.t('Permanent'), 
            i18n.t('Permanent (termination date)')
          ],
          itemSetsButtonText: '€/m²',
          sets: [
            {
              title: i18n.t('Area'),
              groupBy: 'label',
              sortField: 'area',
              format: "Area",
              groups: [
                {
                  value: 'area',
                  label: i18n.t('Area'),
                }
              ],
            },
            {
              title: i18n.t('Total market rent'),
              groupBy: 'label',
              sortField: 'marketRent',
              format: "Euro",
              groups: [
                {
                  value: 'marketRent',
                  label: i18n.t('Total market rent'),
                },
              ],
            },

          ],
        },
        plugins: [{
          beforeInit: function (chart) {
            chart.data.labels.forEach(function (e, i, a) { 
              if (/\n/.test(e)) {
                  a[i] = e.split(/\n/);
                }
            });
          },
        }],
        small: false,
        loading: false,
      },
      {
        id: 'leasing.ending_contracts_based_on_status',
        type: 'PieDataGraph',
        data: {
          items: [],
          itemSets: [
            { text: 'm\u00B2', subtitle: i18n.t('leasing.rentable_area'), value: 'area', format: 'Area'},
            { text: '€', subtitle: i18n.t('leasing.contract_total_market_rent'), value: 'rent', format: 'EuroPerMonth'},
            { text: '#', subtitle: i18n.t('Count'), value: 'amount'}
          ],
          sets: [
            {
              title: i18n.t('prospect.show_all'),
              groupBy: 'nameAll',
              method: 'sum',
              parameters: ['all'],
            },
            {
              title: i18n.t('leasing.fixed_term'),
              groupBy: 'nameFixedTerm',
              method: 'sum',
              parameters: ['fixed_term'],
            },
            {
              title: i18n.t('leasing.permanent'),
              groupBy: 'namePermanent',
              method: 'sum',
              parameters: ['permanent'],
            },
          ],
          colors: {
            'Mitä tehdään?': '#707070',
            'Ohi mennyt': '#EB0B0B',
            'Ei ajankohtainen': '#c6c6c6',
            "1. Liidi": "#FFE682",
            "2. Prospekti": "#E3F586",
            "3. Tarvekartoitus": "#BEE385",
            "4. Ehdotus": "#97CF8A",
            "5. Sopimusneuvottelu": "#4CAF50",
            "6. Voitettu": "#237426",
            "7. Voitettu ja vuokrahallinnossa": "#0D4D0F",
            "8. Voitettu ja Valmis": "#053306",
          },
          small: true,
          showPercentages: true,
          showDatasetNamesAsTitle: true
        },
        small: true,
        loading: true,
      },
    ]
  },

  RentableUnitsLeft () {
    return [
      /*
      {
        id: 'leasing.sites_occupancy_rates',
        type: 'ObjectTable',
        data: {
          headers: [
            { text: i18n.t('Site'), value: 'site_name' },
            { text: i18n.t('Status'), value: 'rentalSituation', noDescription: true } ,
            { text: `${i18n.t('Economic usage rate')  } %`, value: 'economic_usage_percentage', format: 'Percentage' },
            { text: `${i18n.t('Technical usage rate')  } %`, value: 'technical_usage_percentage', format: 'Percentage' },
            { text: `${i18n.t('Rentable area')  } m\u00B2 ${  i18n.t('Totals').toLowerCase()}` , value: 'total_area', format: 'Area'  },
            { text: `${i18n.t('leasing.free_rentable_area')  } m\u00B2` , value: 'free_area_total', format: 'Area' },
            { text: `${i18n.t('leasing.free_market_rent_total')  } €/kk`, value: 'free_market_rent_total', format: 'Euro' },
            { text: i18n.t('leasing.net_total_current_year') , value: 'net_total_current_year', format: 'Area' },
            { text: i18n.t('Visibility prevented spaces') , value: 'prevented_units' },
            { text: `${i18n.t('Visibility prevented spaces')   } m\u00B2` , value: 'prevented_units_area', format: 'Area' },
            { text: `${i18n.t('leasing.renovations_area')  } m\u00B2` , value: 'repairs_area', format: 'Area' },
          ],
          browseHeaders: [
            { text: i18n.t('Site'), value: 'site_name' },
            { text: i18n.t('Identifier'), value: 'site_identifier' },
            { text: i18n.t('Status'), value: 'rentalSituation', leasing: true, noDescription: true } ,
            { text: `${i18n.t('Economic usage rate')  } %`, value: 'economic_usage_percentage', format: 'Percentage' },
            { text: `${i18n.t('Technical usage rate')  } %`, value: 'technical_usage_percentage', format: 'Percentage' },
            { text: `${i18n.t('Rentable area')  } m\u00B2 ${  i18n.t('Totals').toLowerCase()}` , value: 'total_area', format: 'Area' },
            { text: `${i18n.t('Free rentable area')  } m\u00B2 ${  i18n.t('Totals').toLowerCase()}` , value: 'free_area_total', format: 'Area' },
            { text: `${i18n.t('leasing.free_market_rent_total')  } m\u00B2` , value: 'free_market_rent_total', format: 'Euro'  },
            { text: i18n.t('leasing.net_total_current_year') , value: 'net_total_current_year', format: 'Area' },
            { text: i18n.t('leasing.net_total_previous_year') , value: 'net_total_previous_year', format: 'Area' },
            { text: i18n.t('leasing.confirmed_new_renewed_contracts') , value: 'confirmed_new_contracts_area', format: 'Area' },
            { text: i18n.t('leasing.average_rent'), value: 'average_rent', format: 'MoneyPerSquare' },
            { text: i18n.t('leasing.site_active_processes') , value: 'active_prospects_area', format: 'Area' },
            { text: i18n.t('leasing.number_of_contracts') , value: 'number_of_contracts', format: 'Amount' },
            { text: i18n.t('leasing.number_of_tenants') , value: 'number_of_tenants', format: 'Amount' },
            { text: i18n.t('leasing.lease_responsible') , value: 'leasing_responsible' },
            { text: i18n.t('Visibility prevented spaces') , value: 'prevented_units' },
            { text: `${i18n.t('Visibility prevented spaces')   } m\u00B2` , value: 'prevented_units_area', format: 'Area' },
            { text: `${i18n.t('leasing.renovations_area')  } m\u00B2` , value: 'repairs_area', format: 'Area' },
            { text: i18n.t('Vacant units') , value: 'free_units' },
            { text: i18n.t('Rented units') , value: 'rented_units' },
            { text: i18n.t('Site manager') , value: 'site_manager' },
          ],
          footers: [
            {},
            {},
            { value: 'total_economic_usage_percentage', format: 'Percentage' },
            { value: 'total_technical_usage_percentage', format: 'Percentage' },
            { value: 'total_area', format: 'Area' },
            { value: 'free_area_total', format: 'Area'},
            { value: 'free_market_rent_total', format: 'Euro' },
            { value: 'net_total_current_year', format: 'Area' },
            { value: 'prevented_units' },
            { value: 'prevented_units_area', format: 'Area' },
            { value: 'repairs_area', format: 'Area' },
          ],
          browseFooters: [
            { value: 'total_area', format: 'Area' },
            { value: 'free_area_total', format: 'Area'},
            { value: 'free_market_rent_total', format: 'Euro' },
            { value: 'net_total_current_year', format: 'Area' },
            { value: 'active_prospects_area', format: 'Area' },
            { value: 'number_of_contracts', format: 'Amount' },
            { value: 'number_of_tenants', format: 'Amount' },
            { value: 'prevented_units' },
            { value: 'prevented_units_area', format: 'Area' },
            { value: 'repairs_area', format: 'Area' },
            { value: 'free_units', format: 'Amount' },
            { value: 'rented_units', format: 'Amount' },
          ],
          items: [],
          sortBy: 'site_name',
          sortDesc: false,
        },
        small: false,
        loading: true,
        maximized:[true]
      },*/
      {
        id: 'leasing.most_vacant_space_per_site',
        type: 'HorizontalGroupedBarDataGraph',
        data: {
          items: [],
          limit: 20,
          customLegend: true,
          sets: [
            {
              title: i18n.t('Area'),
              groupBy: 'siteName',
              format: 'Area',
              sortField: 'vacant_space_area',
              groups: [
                {
                  value: 'vacant_space_area',
                  label: i18n.t('leasing.free_space'),
                },
                {
                  value: 'active_prospects_area',
                  label: i18n.t('leasing.active_processes'),
                  color: generalColors.greyBar,
                },
              ],
            },
            {
              title: i18n.t('Eur'),
              groupBy: 'siteName',
              format: 'Euro',
              sortField: 'vacant_space_eur',
              groups: [
                {
                  value: 'vacant_space_eur',
                  label: i18n.t('leasing.free_space'),
                },
                {
                  value: 'active_prospects_eur',
                  label: i18n.t('leasing.active_processes'),
                  color: generalColors.greyBar,
                },
              ],
            },
            {
              title: i18n.t('Vacancy rate technical'),
              groupBy: 'siteName',
              format: 'Percentage',
              sortField: 'freeAreaPortion',
              groups: [
                {
                  value: 'freeAreaPortion',
                  label: i18n.t('Vacancy rate technical'),
                }
              ],
            },
            {
              title: i18n.t('short.amount'),
              groupBy: 'siteName',
              format: 'Amount',
              sortField: 'unitCount',
              groups: [
                {
                  value: 'unitCount',
                  label: i18n.t('short.amount'),
                }
              ],
            },
          ],
        },
        small: false,
        loading: true,
      },
    ]
  },
  RentableUnitsRight () {
    return [
      {
        id: 'leasing.empty_units',
        type: 'ObjectTable',
        data: {
          headers: [
            { text: i18n.t('Site'), value: 'site_name' },
            { text: i18n.t('Building'), value: 'building_name' },
            { text: i18n.t('Unit'), value: 'unit_name' },
            { text: i18n.t('Unit area'), value: 'unit_area', format: 'Area' },
            { text: i18n.t('Rental situation'), value: "rentalSituation"},
            { text: i18n.t('Unit use'), value: 'unit_class' },
            { text: i18n.t('Previous tenant'), value: 'previous_tenant_name' },
            { text: i18n.t('End date'), value: 'previous_tenant_end_date', format: 'Date' },
            { text: i18n.t('Unit market rent'), value: 'unit_market_rent', format: 'Euro' },
            { text: i18n.t('leasing.active_processes'), value: 'process_title'},
            { text: i18n.t('leasing.latest_note'), value: 'unitNote' },
            { text: i18n.t('Visibility prevented'), value: 'visibility_prevented', format: 'Boolean' },
            { text: i18n.t('leasing.visibility_prevented_reasoning'), value: 'reservation_reasoning' },
            { text: '', value: 'toggle', type: 'toggle' },
          ],
          browseHeaders: [
            { text: i18n.t('Site'), value: 'site_name' },
            { text: i18n.t('Identifier'), value: 'site_identifier' },
            { text: i18n.t('Building'), value: 'building_name' },
            { text: i18n.t('Unit'), value: 'unit_name' },
            { text: i18n.t('Unit code long'), value: 'unit_code_long' },
            { text: i18n.t('Unit area'), value: 'unit_area', format: 'Area' },
            { text: i18n.t('Rental situation'), value: "rentalSituation", leasing: true},
            { text: i18n.t('Unit use'), value: 'unit_class' },
            { text: i18n.t('Previous tenant'), value: 'previous_tenant_name' },
            { text: i18n.t('End date'), value: 'previous_tenant_end_date', format: 'Date' },
            { text: i18n.t('Unit market rent'), value: 'unit_market_rent', format: 'Euro' },
            { text: i18n.t('Next tenant'), value: 'next_tenant_name' },
            { text: i18n.t('Start date'), value: 'next_tenant_start_date', format: 'Date' },
            { text: i18n.t('Usage types'), value: 'usage' },
            { text: i18n.t('Site status'), value: 'site_status' },
            { text: i18n.t('Site state'), value: 'site_state' },
            { text: i18n.t('Unit rental status'), value: 'unit_rental_status' },
            { text: i18n.t('Move readiness'), value: 'move_readiness' },
            { text: i18n.t('Campaign active'), value: 'campaign_active' },
            { text: i18n.t('Reservation status'), value: 'reservation_status' },
            { text: i18n.t('Master unit'), value: 'master_unit' },
            { text: i18n.t('Market rent'), value: 'market_rent', format: 'MoneyPerSquare' },
            { text: i18n.t('Clarified ti expense'), value: 'clarified_ti_expense', format: 'MoneyPerSquare' },
            { text: i18n.t('Estimated ti expense'), value: 'estimated_ti_expense', format: 'MoneyPerSquare' },
            { text: i18n.t('Advertising phrase'), value: 'advertising_phrase' },
            { text: i18n.t('Unit end date'), value: 'unit_end_date', format: 'Date' },
            { text: i18n.t('leasing.active_processes'), value: 'processesString' },
            { text: i18n.t('leasing.latest_note'), value: 'unitNote' },
            { text: i18n.t('Visibility prevented'), value: 'visibility_prevented', format: 'Boolean' },
            { text: i18n.t('leasing.visibility_prevented_reasoning'), value: 'reservation_reasoning' },
          ],
          filter: {
            header: 'preventation_end_date_exists',
            type: 'equal',
            options: [
              { text: 'leasing.hide_visibility_prevented', value: 'not prevented' },
              { text: 'leasing.show_only_visibility_prevented', value: 'prevented' },
              { text: 'prospect.show_all', value: null },
            ],
            defaultFilter: {
              text: 'leasing.hide_visibility_prevented',
              value: 'not prevented',
            },
          },
          footers: [
            { text: i18n.t('Total') },
            {},
            {},
            { value: 'unit_area', unit: 'area', format: 'Area' },
            {},
            {},
            {},
            {},
            { value: 'unit_market_rent', format: 'Euro' },
            {},
            {},
            {},
            {},
            {}
          ],
          items: [],
          sortBy: 'unit_area',
          sortDesc: true,
          itemsPerPage: 10,
          ignoreSubitemsInTable: true
        },
        small: false,
        loading: true,
        maximized:[false]
      },
      {
        id: 'leasing.owned_free_units_progress',
        type: 'VerticalStackedGraph',
        data: {
          cumulative: [i18n.t('Cumulative sum')],
          headers: {},
          dynamicHeaders: true,
          sets: [],
          dynamicSets: true,
          showSetAsHeader: true,
          groupButtonText: '€/m²',
          groups: [
            {
              text: 'm\u00B2',
              value: 'area',
              unit: 'm\u00B2',
              subtitle: 'leasing.area_with_unit',
            },
            {
              text: '€',
              value: 'rent',
              unit: '€',
              subtitle: 'leasing.market_rent_capital',
            },
            {
              text: i18n.t('short.amount'),
              value: 'count',
              unit: i18n.t('short.amount'),
            },
          ],
          lineItems: [],
          lineItemsLegend: 'Occupancy rate',
          lineUnit: '%',
          lineColor: '#acabab',
          rangeSlider: true,
          items: [],
          column: 'quarter',
          yUnit: '€',
          difference: 'leasing.free_area_change',
          differenceLabel: i18n.t('leasing.free_area'),
          secondaryDifferenceLabel: i18n.t('leasing.leased_or_ending_in_future'),
          //This is set to true/false by a component outside the widget
          useSecondaryDifferenceLabel: false,
          displayLabelColors: false,
          customTooltip: true,
          showLineOnTooltip: true,
          info:  i18n.t('leasing.info_owned_free_units_progress')       
        },
        small: false,
        loading: true,
      },
      {
        id: 'leasing.free_usage_types',
        type: 'PieDataGraph',
        data: {
          items: [],
          sets: [
            {
              title: i18n.t('leasing.area_with_unit'),
              groupBy: 'areaUsage',
              method: 'sum',
              parameters: ['area'],
            },
            {
              title: i18n.t('Market rent carpark'),
              groupBy: 'marketRentUsage',
              method: 'sum',
              parameters: ['market_rent'],
            },
            {
              title: i18n.t('short.amount'),
              groupBy: 'unitCountUsage',
              method: 'count',
              parameters: ['unitCountUsage']
            },
          ],
          showContractAreaTooltip: true,
          showDatasetNamesAsTitle: true,
        },
        small: true,
        loading: false,
      },
      {
        id: 'leasing.free_units_by_size',
        type: 'PieDataGraph',
        data: {
          items: [],
          dynamicSets: true,
          sets: [],
          showDatasetNamesAsTitle: true,
        },
        small: true,
        loading: false,
      },
      {
        id: 'leasing.free_units_by_type',
        type: 'PieDataGraph',
        data: {
          items: [],
          sets: [
            {
              title: i18n.t('leasing.area_with_unit'),
              groupBy: 'apartment',
              method: 'sum',
              parameters: ['area'],
            },
            {
              title: i18n.t('short.amount'),
              groupBy: 'apartment',
              method: 'count',
              parameters: ['apartment']
            },
          ],
          showContractAreaTooltip: true,
          showDatasetNamesAsTitle: true,
        },
        small: true,
        loading: false,
        maximized: [false],
      },
    ]
  },

  CustomersLeft () {
    return [
      {
        id: 'leasing.customers_by_status',
        type: 'PieDataGraph',
        data: {
          items: [],
          sets: [
            {
              title: `${i18n.t('Rental contract')  } m\u00B2`,
              groupBy: 'tenant_status',
              method: 'sum',
              parameters: ['corporation_contract_area'],
            },
            {
              title:
                `${i18n.t('leasing.customers')  }, ${  i18n.t('short.amount')}`,
              groupBy: 'tenant_status',
              method: 'count',
              parameters: ['tenant_status'],
            },
          ],
          showContractAreaTooltip: true,
          showDatasetNamesAsTitle: true,
        },
        small: true,
        loading: false,
      },
      {
        id: 'leasing.customer_register',
        type: 'ObjectTable',
        data: {
          headers: [
            { text: 'Tenant corporation', value: 'corporation_name' },
            { text: 'leasing.status', value: 'tenant_status' },
            { text: 'Business ID', value: 'business_id' },
            {
              text: 'leasing.active_processes',
              value: 'process_amount',
              format: 'Integer',
            },
            {
              text: 'leasing.valid_contracts',
              value: 'valid_contracts_area',
              format: 'Area',
            },
            { text: 'Capital rent (€/yr)', value: 'capital_rent', format: 'Euro' },
            { text: 'Customer correspondent', value: 'customer_correspondent' },
            {
              text: 'leasing.linked_current_sites',
              value: 'current_sites',
              format: 'Integer',
            },
          ],
          browseHeaders: [
            {
              text: 'Tenant corporation',
              value: 'corporation_name',
              leasing: true,
            },
            { text: 'leasing.status', value: 'tenant_status' },
            { text: 'Business ID', value: 'business_id' },
            {
              text: 'leasing.active_processes',
              value: 'process_amount',
              format: 'Integer',
            },
            {
              text: 'leasing.valid_contracts',
              value: 'valid_contracts_area',
              format: 'Area',
            },
            { text: 'Capital rent (€/yr)', value: 'capital_rent', format: 'Euro' },
            { text: 'Customer correspondent', value: 'customer_correspondent' },
            {
              text: 'leasing.linked_current_sites',
              value: 'current_sites',
              format: 'Integer',
            },
            { text: 'Tenant industry', value: 'tenant_industry' },
            { text: 'Tenant category', value: 'tenant_category' },
            { text: 'Leasing correspondent', value: 'leasing_correspondent' },
            { text: 'Corporation type', value: 'corporation_type' },
            { text: 'Customer number', value: 'customer_number' },
          ],
          filter: {
            header: 'tenant_status',
            type: 'equal',
            options: [],
          },
          footers: [
            { text: i18n.t('Total') },
            {},
            {},
            { value: 'process_amount', format: 'Integer' },
            { value: 'valid_contracts_area', format: 'Area' },
            {},
            {},
          ],
          items: [],
          sortDesc: false,
          infoText: 'leasing.customer_register_info',
        },
        maximized: [true],
        loading: false,
        small: false,
      },
      {
        id: 'leasing.open_bills_progress',
        type: 'VerticalStackedGraph',
        data: {
          cumulative: [],
          headers: {
            openBills: [
              { color: getThemeColor(1), value: 'Pienyritys', text: i18n.t('Pienyritys') },
              { color: getThemeColor(2), value: 'Keskisuuri', text: i18n.t('Keskisuuri') },
              { color: getThemeColor(3), value: 'Unknown', text: i18n.t('Unknown') },
              { color: getThemeColor(4), value: 'Pörssilistattu', text: i18n.t('Pörssilistattu') },
              { color: getThemeColor(5), value: 'Suuryritys', text: i18n.t('Suuryritys') },
              { color: getThemeColor(6), value: 'Säätiö / yhdistys', text: i18n.t('Säätiö / yhdistys') },
              { color: getThemeColor(7), value: 'Kansainvälisen yhtiön sivuyhtiö', text: i18n.t('Kansainvälisen yhtiön sivuyhtiö') },
              { color: getThemeColor(8), value: 'Julkinen', text: i18n.t('Julkinen') },
              { color: getThemeColor(9), value: 'Start up', text: i18n.t('Start up') },
            ],
          },
          sets: [
            {
              text: i18n.t('rental_status.open_bills_total'),
              value: 'openBills',
              unit: '€',
            },
          ],
          lineItems: [],
          lineItemsLegend: 'leasing.open_percent_of_monthly_rent',
          lineUnit: '%',
          lineColor: '#acabab',
          rangeSlider: true,
          totalsDifference: true,
          difference: 'leasing.open_bills_change',
          items: [],
          column: 'quarter',
        },
        small: false,
        loading: true,
      },
    ]
  },
  CustomersRight () {
    return [
      {
        id: 'leasing.largest_customers',
        type: 'HorizontalGroupedBarDataGraph',
        data: {
          items: [],
          limit: 20,
          itemSetTypes: [],
          dynamicSetTypes: true,
          setsAreValueAreaType: true,
          itemSetsButtonText: '',
          customLegend: true,
          sets: [
            {
              title: i18n.t('leasing.rented_area'),
              groupBy: 'corporation_name',
              format: 'Area',
              sortField: 'shown_contract_area',
              summary: true,
              groups: [
                {
                  value: 'valid_contracts_area',
                  label: i18n.t('leasing.rented_area'),
                },
              ],
            },
            {
              title: i18n.t('Market rent carpark'),
              groupBy: 'corporation_name',
              format: 'Euro',
              sortField: 'shown_market_rent',
              summary: true,
              groups: [
                {
                  value: 'valid_contracts_market_rent',
                  label: i18n.t('Market rent carpark'),
                },
              ],
            },
            {
              title: i18n.t('Sites'),
              groupBy: 'corporation_name',
              format: '',
              sortField: 'shown_current_sites',
              groups: [
                {
                  value: 'current_sites',
                  label: i18n.t('Sites'),
                },
              ],
            },
            {
              title: i18n.t('Capital rent (€/yr)'),
              groupBy: 'corporation_name',
              format: 'Euro',
              sortField: 'shown_capital_rent',
              groups: [
                {
                  value: 'shown_capital_rent',
                  label: i18n.t('Capital rent (€/yr)'),
                },
              ],
            },
          ],
        },
        small: false,
        loading: true,
      },
    ]
  },
  CorporationModal () {
    return [
      {
        id: 'leasing.corporation_processes',
        type: 'ObjectTable',
        loading: false,
        maximized: [false],
        data: {
          headers: [
            { text: 'Rental_event', value: 'process_title', leasing: true },
            { text: 'leasing.renting_stage', value: 'prospect_status', leasing: true },
          ],
          footers: [],
          items: [],
          noBrowse: false,
          filter: {
            header: 'prospect_status',
            type: 'equal',
            options: [
              { text: 'prospect.show_only_inactives', value: 'Inactive' },
              { text: '1. Lead', value: '1. Lead' },
              { text: '2. Prospect', value: '2. Prospect' },
              { text: '3. NeedsAssessment', value: '3. NeedsAssessment' },
              { text: '4. Proposal', value: '4. Proposal' },
              { text: '5. ContractNegotiation', value: '5. ContractNegotiation' },
              { text: '6. Won', value: '6. Won' },
              { text: '7. RentAdministration', value: '7. RentAdministration' },
              { text: '8. Closed', value: '8. Closed' },
              { text: 'prospect.show_all', value: null },
            ],
          },
        },
      },
      {
        id: 'leasing.corporation_current_sites',
        type: 'DataTable',
        loading: true,
        maximized: [false],
        data: {
          headers: [
            { text: 'Site', value: 'site_name' },
            { text: 'Unit', value: 'unit_name' },
            { text: 'Net floor area', value: 'unit_area', format: 'Area' },
            { text: 'Spaces by purpose of use', value: 'unit_class' },
            {
              text: 'Start date',
              value: 'start_date',
              format: 'Date',
            },
            {
              text: 'End date',
              value: 'end_date',
              format: 'Date',
            },
          ],
          footers: [
            { text: 'Total' },
            {},
            { value: 'unit_area', unit: 'area', format: 'Area' },
            {},
            {},
          ],
          items: [],
          sortBy: 'end_date',
          sortDesc: true
        },
      },
      {
        id: 'leasing.corporation_notebook',
        data: {},
        small: false,
        loading: true,
        maximized: [false]
      },
      {
        id: 'leasing.corporation_contracts',
        type: 'ObjectTable',
        loading: false,
        maximized: [false],
        data: {
          headers: [
            {
              value: 'site_identifier',
              text: i18n.t('Site identifier'),
            },
            {
              value: 'longUnitCodes',
              text: i18n.t('Unit code long'),
            },
            {
              value: 'buildingCode',
              text: i18n.t('Building code'),
            },
            {
              value: 'unitNameList',
              text: i18n.t('Unit'),
            },
            {
              value: 'contractNumber',
              text: i18n.t('Contract number'),
            },
            {
              value: 'curPartyName',
              text: i18n.t('Tenant'),
            },
            {
              value: 'business_id',
              text: i18n.t('Business ID'),
            },
            {
              value: 'validity',
              text: i18n.t('Validity'),
            },
            {
              value: 'signatureDate',
              text: i18n.t('Contract date'),
              format: 'Date',
            },
            {
              value: 'curPartyStartDate',
              text: i18n.t('Contract start date'),
              format: 'Date',
            },
            {
              value: 'contractFirstPossibleEndDate',
              text: i18n.t('Contract first possible end date'),
              format: 'Date',
            },
            {
              value: 'curPartyEndDate',
              text: i18n.t('Contract end date'),
              format: 'Date',
            },
            {
              value: 'tenantNotice',
              text: i18n.t('Agreement notice period (Tenant)'),
              format: 'Number',
            },
            {
              value: 'landlordNotice',
              text: i18n.t('Agreement notice period (Lanlord)'),
              format: 'Number',
            },
            {
              value: 'huom',
              text: i18n.t('Validity notice'),
            },
            {
              value: 'contract_area',
              text: i18n.t('Contract area'),
              format: 'AreaExact',
            },
            {
              value: 'contract_note',
              text: i18n.t('Contract details'),
            },
            {
              value: 'vat_status',
              text: i18n.t('VAT-responsibility'),
            },
            {
              value: 'contractType',
              text: i18n.t('leasing.contract_type'),
            },
            {
              value: 'industry',
              text: i18n.t('leasing.industry'),
            },
            {
              value: 'contract_status',
              text: i18n.t('Contract status'),
            },
          ],
          footers: [],
          items: [],
          noBrowse: false,
        },
      },
      {
        id: 'site.leasing.ending_contracts_graph',
        type: 'VerticalStackedGraph',
        small: true,
        loading: true,
        maximized: [false],
        data: {
          cumulative: [],
          headers: {
            fixedTerm: 
            [
              { text: i18n.t('Inactive'), value: 'fixedTermInactiveArea', color: rentalProcessStageColors.Inactive, group: 'area' },
              { text: i18n.t('ProspectPending'), value: 'fixedTermPendingArea', color: '#707070', group: 'area' },
              { text: i18n.t('leasing.not_relevant'), value: 'fixedTermNotRelevantArea', color: '#acabab', group: 'area' },
              { text: i18n.t('leasing.begin_later'), value: 'fixedTermStartLaterArea', color: '#78adcf', group: 'area' },
              { text: i18n.t('1. Lead'), value: 'fixedTermLeadArea', color: rentalProcessStageColors['1. Lead'], group: 'area' },
              { text: i18n.t('2. Prospect'), value: 'fixedTermProspectArea', color: rentalProcessStageColors['2. Prospect'], group: 'area' },
              { text: i18n.t('3. NeedsAssessment'), value: 'fixedTermNeedsAssessmentArea', color: rentalProcessStageColors['3. NeedsAssessment'], group: 'area' },
              { text: i18n.t('4. Proposal'), value: 'fixedTermProposalArea', color: rentalProcessStageColors['4. Proposal'], group: 'area' },
              { text: i18n.t('5. ContractNegotiation'), value: 'fixedTermContractNegotiationArea', color: rentalProcessStageColors['5. ContractNegotiation'], group: 'area' },
              { text: i18n.t('6. Won'), value: 'fixedTermWonArea', color: rentalProcessStageColors['6. Won'], group: 'area' },
              { text: i18n.t('7. RentAdministration'), value: 'fixedTermRentAdministrationArea', color: rentalProcessStageColors['7. RentAdministration'] , group: 'area' },
              { text: i18n.t('8. Closed'), value: 'fixedTermClosedArea', color: rentalProcessStageColors['8. Closed'], group: 'area' },
              
              { text: i18n.t('Inactive'), value: 'fixedTermInactiveRent', color: rentalProcessStageColors.Inactive, group: 'rent' },
              { text: i18n.t('ProspectPending'), value: 'fixedTermPendingRent', color: '#707070', group: 'rent' },
              { text: i18n.t('leasing.not_relevant'), value: 'fixedTermNotRelevantRent', color: '#707070', group: 'rent' },
              { text: i18n.t('leasing.begin_later'), value: 'fixedTermStartLaterRent', color: '#707070', group: 'rent' },
              { text: i18n.t('1. Lead'), value: 'fixedTermLeadRent', color: rentalProcessStageColors['1. Lead'], group: 'rent' },
              { text: i18n.t('2. Prospect'), value: 'fixedTermProspectRent', color: rentalProcessStageColors['2. Prospect'], group: 'rent' },
              { text: i18n.t('3. NeedsAssessment'), value: 'fixedTermNeedsAssessmentRent', color: rentalProcessStageColors['3. NeedsAssessment'], group: 'rent' },
              { text: i18n.t('4. Proposal'), value: 'fixedTermProposalRent', color: rentalProcessStageColors['4. Proposal'], group: 'rent' },
              { text: i18n.t('5. ContractNegotiation'), value: 'fixedTermContractNegotiationRent', color: rentalProcessStageColors['5. ContractNegotiation'], group: 'rent' },
              { text: i18n.t('6. Won'), value: 'fixedTermWonRent', color: rentalProcessStageColors['6. Won'], group: 'rent' },
              { text: i18n.t('7. RentAdministration'), value: 'fixedTermRentAdministrationRent', color: rentalProcessStageColors['7. RentAdministration'] , group: 'rent' },
              { text: i18n.t('8. Closed'), value: 'fixedTermClosedRent', color: rentalProcessStageColors['8. Closed'], group: 'rent' },
            ],
            permanent:
            [
              { text: i18n.t('Inactive'), value: 'permanentInactiveArea', color: rentalProcessStageColors.Inactive, group: 'area' },
              { text: i18n.t('ProspectPending'), value: 'permanentPendingArea', color: '#707070', group: 'area' },
              { text: i18n.t('leasing.not_relevant'), value: 'permanentNotRelevantArea', color: '#acabab', group: 'area' },
              { text: i18n.t('leasing.begin_later'), value: 'permanentStartLaterArea', color: '#78adcf', group: 'area' },
              { text: i18n.t('1. Lead'), value: 'permanentLeadArea', color: rentalProcessStageColors['1. Lead'], group: 'area' },
              { text: i18n.t('2. Prospect'), value: 'permanentProspectArea', color: rentalProcessStageColors['2. Prospect'], group: 'area' },
              { text: i18n.t('3. NeedsAssessment'), value: 'permanentNeedsAssessmentArea', color: rentalProcessStageColors['3. NeedsAssessment'], group: 'area' },
              { text: i18n.t('4. Proposal'), value: 'permanentProposalArea', color: rentalProcessStageColors['4. Proposal'], group: 'area' },
              { text: i18n.t('5. ContractNegotiation'), value: 'permanentContractNegotiationArea', color: rentalProcessStageColors['5. ContractNegotiation'], group: 'area' },
              { text: i18n.t('6. Won'), value: 'permanentWonArea', color: rentalProcessStageColors['6. Won'], group: 'area' },
              { text: i18n.t('7. RentAdministration'), value: 'permanentRentAdministrationArea', color: rentalProcessStageColors['7. RentAdministration'] , group: 'area' },
              { text: i18n.t('8. Closed'), value: 'permanentClosedArea', color: rentalProcessStageColors['8. Closed'], group: 'area' },
              
              { text: i18n.t('Inactive'), value: 'permanentInactiveRent', color: rentalProcessStageColors.Inactive, group: 'rent' },
              { text: i18n.t('ProspectPending'), value: 'permanentPendingRent', color: '#707070', group: 'rent' },
              { text: i18n.t('leasing.not_relevant'), value: 'permanentNotRelevantRent', color: '#acabab', group: 'rent' },
              { text: i18n.t('leasing.begin_later'), value: 'permanentStartLaterRent', color: '#78adcf', group: 'rent' },              
              { text: i18n.t('1. Lead'), value: 'permanentLeadRent', color: rentalProcessStageColors['1. Lead'], group: 'rent' },
              { text: i18n.t('2. Prospect'), value: 'permanentProspectRent', color: rentalProcessStageColors['2. Prospect'], group: 'rent' },
              { text: i18n.t('3. NeedsAssessment'), value: 'permanentNeedsAssessmentRent', color: rentalProcessStageColors['3. NeedsAssessment'], group: 'rent' },
              { text: i18n.t('4. Proposal'), value: 'permanentProposalRent', color: rentalProcessStageColors['4. Proposal'], group: 'rent' },
              { text: i18n.t('5. ContractNegotiation'), value: 'permanentContractNegotiationRent', color: rentalProcessStageColors['5. ContractNegotiation'], group: 'rent' },
              { text: i18n.t('6. Won'), value: 'permanentWonRent', color: rentalProcessStageColors['6. Won'], group: 'rent' },
              { text: i18n.t('7. RentAdministration'), value: 'permanentRentAdministrationRent', color: rentalProcessStageColors['7. RentAdministration'] , group: 'rent' },
              { text: i18n.t('8. Closed'), value: 'permanentClosedRent', color: rentalProcessStageColors['8. Closed'], group: 'rent' },
            ],
            all: 
            [
              { text: i18n.t('leasing.fixed_term_contracts'), value: 'allFixedTermArea',  color: '#8f7df2', group: 'area' },
              { text: i18n.t('leasing.permanent_contracts'), value: 'allPermanentArea', color: '#7dc3f2', group: 'area' },
              { text: i18n.t('leasing.fixed_term_contracts'), value: 'allFixedTermRent', color: '#8f7df2', group: 'rent' },
              { text: i18n.t('leasing.permanent_contracts'), value: 'allPermanentRent', color: '#7dc3f2', group: 'rent' },
            ]
          },
          sets: [
            {
              text: i18n.t('prospect.show_all'),
              value: 'all',
            },
            {
              text: i18n.t('leasing.fixed_term'),
              value: 'fixedTerm',
            },
            {
              text: i18n.t('leasing.permanent'),
              value: 'permanent',
            },
          ],
          groups: [
            { text: 'm\u00B2', value: 'area', unit: 'm\u00B2', subtitle: '' },
            { text: '€/kk', value: 'rent', unit: '€/kk', subtitle: '' }
          ],
          rangeSlider: true,
          items: [],
          column: 'year',
          report: "endingContractsGraph",
          infoText: 'leasing.ending_contracts_graph_info',
        },
      },
      { id: "site.rental_contracts"},
    ]
  },
  SitesRentalContracts () {
    return [
      {
        id: 'site.leasing.ending_contracts',
        type: 'ObjectTable',
        data: {
          headers: [
            { text: 'Tenant', value: 'tenant', leasing: true },
            { text: 'Units', value: 'unitNames' },
            {
              text: 'leasing.rentable_area',
              value: 'totalArea',
              format: 'Area',
            },
            { text: 'leasing.end_date', value: 'endDate', format: 'Date' },
            { text: 'Vacancy rate assumption', value: 'vacancyRateAssumption' },
            { text: 'Vacancy rate assumption updated', value: 'vacancyRateAssumptionUpdated'},
            {
              text: 'leasing.contract_total_market_rent',
              value: 'contractTotalMarketRent',
              format: 'EuroPerMonth',
            },
            { text: 'leasing.renegotiations_status', value: 'prospect_status' },
            { text: 'leasing.renegotiations', value: 'renegotiations' },
          ],
          browseHeaders: [
            { text: 'Tenant', value: 'tenant', leasing: true },
            { text: 'Units', value: 'unitNames' },
            {
              text: 'leasing.rentable_area',
              value: 'totalArea',
              format: 'Area',
            },
            { text: 'leasing.end_date', value: 'endDate', format: 'Date' },
            { text: 'Vacancy rate assumption', value: 'vacancyRateAssumption' },
            { text: 'Vacancy rate assumption updated', value: 'vacancyRateAssumptionUpdated'},
            {
              text: 'leasing.contract_total_market_rent',
              value: 'contractTotalMarketRent',
              format: 'EuroPerMonth',
            },
            { text: 'Contract number', value: 'contractNumber' },
            {
              text: 'leasing.renegotiations_status',
              value: 'prospect_fullscreen_status',
              leasing: true,
            },
            {
              text: 'leasing.renegotiations',
              value: 'renegotiations',
              leasing: true,
            },
          ],
          filter: {
            header: 'prospect_status',
            type: 'equal',
            options: [
              { text: 'ProspectPending', value: 'ProspectPending' },
              { text: '1. Lead', value: '1. Lead'  },
              { text: '2. Prospect', value: '2. Prospect' },
              { text: '3. NeedsAssessment', value: '3. NeedsAssessment' },
              { text: '4. Proposal', value: '4. Proposal' },
              { text: '5. ContractNegotiation', value: '5. ContractNegotiation' },
              { text: '6. Won', value: '6. Won' },
              { text: '7. RentAdministration', value: '7. RentAdministration' },
              { text: '8. Closed', value: '8. Closed'},
              { text: 'prospect.show_only_inactives', value: 'Inactive' },
              { text: 'Technical modification', value: 'Technical modification' },
              { text: 'prospect.show_all', value: null },
            ],
          },
          footers: [
            { text: i18n.t('Total') },
            {},
            { value: 'totalArea', format: 'Area' },
            {},
            {},
            {},
          ],
          //Order doesn't matter for browseFooters. Needs browseHeader with the same value. Use if normal footers don't have same values or formats
          browseFooters: [
            { value: 'totalArea', format: 'Area' },
          ],
          items: [],
          itemsPerPage: 10,
        },
        small: false,
        loading: true,
      },
      {
        id: 'site.leasing.permanent_contracts',
        type: 'ObjectTable',
        data: {
          headers: [
            { text: 'Tenant', value: 'tenant', leasing: true },
            { text: 'Units', value: 'unitNames' },
            { text: 'leasing.rentable_area', value: 'totalArea', format: 'Area' },
            { text: 'leasing.contract_first_possible_end_date_short', value: 'contractFirstPossibleEndDate', format: 'Date' },
            { text: 'leasing.end_date', value: 'endDate', format: 'Date' },
            { text: 'Vacancy rate assumption', value: 'vacancyRateAssumption' },
            { text: 'Vacancy rate assumption updated', value: 'vacancyRateAssumptionUpdated'},
            { text: 'leasing.contract_total_market_rent', value: 'contractTotalMarketRent', format: 'EuroPerMonth' },
            { text: 'leasing.renewal_status', value: 'prospect_status' },
            { text: 'leasing.renewal', value: 'renewal' },
          ],
          browseHeaders: [
            { text: 'Tenant', value: 'tenant', leasing: true },
            { text: 'Units', value: 'unitNames' },
            { text: 'leasing.rentable_area',value: 'totalArea', format: 'Area' },
            { text: 'leasing.contract_first_possible_end_date_short', value: 'contractFirstPossibleEndDate', format: 'Date' },
            { text: 'leasing.end_date', value: 'endDate', format: 'Date' },
            { text: 'Vacancy rate assumption', value: 'vacancyRateAssumption' },
            { text: 'Vacancy rate assumption updated', value: 'vacancyRateAssumptionUpdated'},
            { text: 'leasing.contract_total_market_rent', value: 'contractTotalMarketRent', format: 'EuroPerMonth' },
            { text: 'Contract number', value: 'contractNumber' },
            { text: 'leasing.renewal_status', value: 'prospect_fullscreen_status', leasing: true },
            { text: 'leasing.renewal', value: 'renewal', leasing: true },
          ],
          filter: {
            header: 'prospect_status',
            type: 'equal',
            options: [
              { text: 'ProspectPending', value: 'ProspectPending' },
              { text: '1. Lead', value: '1. Lead'  },
              { text: '2. Prospect', value: '2. Prospect' },
              { text: '3. NeedsAssessment', value: '3. NeedsAssessment' },
              { text: '4. Proposal', value: '4. Proposal' },
              { text: '5. ContractNegotiation', value: '5. ContractNegotiation' },
              { text: '6. Won', value: '6. Won' },
              { text: '7. RentAdministration', value: '7. RentAdministration' },
              { text: '8. Closed', value: '8. Closed'},
              { text: 'prospect.show_only_inactives', value: 'Inactive' },
              { text: 'Technical modification', value: 'Technical modification' },
              { text: 'leasing.not_relevant', value: 'Ei ajankohtainen' },
              { text: 'prospect.show_all', value: null },
            ],
          },
          footers: [
            { text: i18n.t('Total') },
            {},
            { value: 'totalArea', format: 'Area' },
            {},
            {},
            {},
            {},
            {},
          ],
          //Order doesn't matter for browseFooters. Needs browseHeader with the same value. Use if normal footers don't have same values or formats
          browseFooters: [
            { value: 'totalArea', format: 'Area' },
          ],
          items: [],
          itemsPerPage: 10,
        },
        maximized: [false],
        loading: true,
        small: false,
      },
      {
        id: 'site.leasing.occupancy_rate_progress',
        type: 'VerticalStackedGraph',
        data: {
          cumulative: [],
          headers: {
            capital: [
              {
                text: i18n.t('Vuokrattu'),
                value: 'rented_capital_percentage',
                color: '#4cae4f',
                afterLabel: 'rented_capital',
                afterLabelFormat: 'Euro',
                showOnTooltip: true,
              },
              {
                text: i18n.t('Vapaana & tulevaisuudessa vuokrattu'),
                value: 'future_rented_capital_percentage',
                color: '#89f27d',
                afterLabel: 'future_rented_capital',
                afterLabelFormat: 'Euro',
                showOnTooltip: true,
              },
              {
                text: i18n.t('Vapaana'),
                value: 'free_capital_percentage',
                color: '#ff5252',
                afterLabel: 'free_capital',
                afterLabelFormat: 'Euro',
                showOnTooltip: true,
              },
              { 
                text: i18n.t('Occupancy rate'), 
                value: 'occupancy_rate_capital', 
                color: '#000', 
                tooltipOnly: true, 
                unit: '%', 
                decimals: 2
              },
            ],
            unitsArea: [
              {
                text: i18n.t('Vuokrattu'),
                value: 'rented_units_area_percentage',
                color: '#4cae4f',
                afterLabel: 'rented_units_area',
                afterLabelFormat: 'Area',
                showOnTooltip: true,
              },
              {
                text: i18n.t('Vapaana & tulevaisuudessa vuokrattu'),
                value: 'future_rented_units_area_percentage',
                color: '#89f27d',
                afterLabel: 'future_rented_units_area',
                afterLabelFormat: 'Area',
                showOnTooltip: true,
              },
              {
                text: i18n.t('Vapaana'),
                value: 'free_units_area_percentage',
                color: '#ff5252',
                afterLabel: 'free_units_area',
                afterLabelFormat: 'Area',
                showOnTooltip: true,
              },
              { 
                text: i18n.t('Occupancy rate'), 
                value: 'occupancy_rate_units_area', 
                color: '#000', 
                tooltipOnly: true, 
                unit: '%', 
                decimals: 2
              },
            ],
            units: [
              {
                text: i18n.t('Vuokrattu'),
                value: 'rented_units_percentage',
                color: '#4cae4f',
                afterLabel: 'rented_units',
                afterLabelFormat: 'Amount',
                showOnTooltip: true,
              },
              {
                text: i18n.t('Vapaana & tulevaisuudessa vuokrattu'),
                value: 'future_rented_units_percentage',
                color: '#89f27d',
                afterLabel: 'future_rented_units',
                afterLabelFormat: 'Amount',
                showOnTooltip: true,
              },
              {
                text: i18n.t('Vapaana'),
                value: 'free_units_percentage',
                color: '#ff5252',
                afterLabel: 'free_units',
                afterLabelFormat: 'Amount',
                showOnTooltip: true,
              },
              { 
                text: i18n.t('Occupancy rate'), 
                value: 'occupancy_rate_units', 
                color: '#000', 
                tooltipOnly: true, 
                unit: '%', 
                decimals: 2
              },
            ],
            carparks: [
              {
                text: i18n.t('Vuokrattu'),
                value: 'rented_carparks_percentage',
                color: '#4cae4f',
                afterLabel: 'rented_carparks',
                afterLabelFormat: 'Amount',
                showOnTooltip: true,
              },
              {
                text: i18n.t('Vapaana & tulevaisuudessa vuokrattu'),
                value: 'future_rented_carparks_percentage',
                color: '#89f27d',
                afterLabel: 'future_rented_carparks',
                afterLabelFormat: 'Amount',
                showOnTooltip: true,
              },
              {
                text: i18n.t('Vapaana'),
                value: 'free_carparks_percentage',
                color: '#ff5252',
                afterLabel: 'free_carparks',
                afterLabelFormat: 'Amount',
                showOnTooltip: true,
              },
              { 
                text: i18n.t('Occupancy rate'), 
                value: 'occupancy_rate_carparks', 
                color: '#000', 
                tooltipOnly: true, 
                unit: '%', 
                decimals: 2
              },
            ],
          },
          sets: [
            {
              text: i18n.t('leasing.economic_combined'),
              value: 'capital',
              unit: '%',
            },
            {
              text: i18n.t('leasing.technical_rentable_area'),
              value: 'unitsArea',
              unit: '%',
            },
            {
              text: i18n.t('leasing.units_count'),
              value: 'units',
              unit: '%',
            },
            {
              text: i18n.t('leasing.technical_carpark_count'),
              value: 'carparks',
              unit: '%',
            },
          ],
          rangeSlider: true,
          items: [],
          column: 'month',
          report: 'occupancyRateProgress',
          customTooltip: true
        },
        small: false,
        loading: true,
      },
    ]
  },
  MyRentalGoals () {
    return {
      id: 'leasing.my_yearly_goals',
      type: 'PieDataGraph',
      data: {
        items: [],
        sets: [
          {
            title: i18n.t('prospect.show_all'),
            groupBy: 'nameAll',
            method: 'sum',
            parameters: ['all'],
          },
          {
            title: i18n.t('leasing.new_negotiation'),
            groupBy: 'nameNew',
            method: 'sum',
            parameters: ['new_negotiation'],
          },
          {
            title: i18n.t('leasing.all_renegotiations'),
            groupBy: 'nameAllReneg',
            method: 'sum',
            parameters: ['all_renegotiations'],
          },
          {
            title: i18n.t('leasing.renegotiations_fixed'),
            groupBy: 'nameFixedReneg',
            method: 'sum',
            parameters: ['fixed_renegotiations'],
          },
          {
            title: i18n.t('leasing.renewals_permanent'),
            groupBy: 'namePermanent',
            method: 'sum',
            parameters: ['permanent_renewals'],
          },
        ],
        colors: {
          ...stageColors,
          'leasing.still_missing': 'hsl(0,3.4%,77.3%)',
          'leasing.now': circleBlue,
        },
        small: true,
        showPercentages: true,
        showDatasetNamesAsTitle: true,
        //This rotates the optional line in the middle of the component to display time of year
        lineRotationInRadians: Math.PI - (moment().dayOfYear() * (2*Math.PI/365)),
        lineColor: circleBlue,
        alternativeText: i18n.t('leasing.no_site_responsibilities')
      },
      small: true,
      loading: true,
    }
  },
  MyBiggestLeases () {
    return [
      {
        id: 'leasing.users_biggest_leases',
        type: 'HorizontalGroupedBarDataGraph',
        data: {
          items: [],
          limit: 10,
          setsAreValueAreaType: true,
          customLegend: true,
          itemSetTypes: [i18n.t('prospect.show_all'), i18n.t('leasing.new_negotiation'), i18n.t('leasing.renegotiations'), i18n.t('leasing.renewal')],
          itemSetsButtonText: '€/m²',
          sets: [
            {
              title: i18n.t('Area'),
              groupBy: 'processName',
              format: 'Area',
              sortField: 'leased_space_area',
              groups: [
                {
                  value: 'leased_space_area',
                  label: i18n.t('Leased'),
                },
              ],
            },
            {
              title: i18n.t('Eur'),
              groupBy: 'processName',
              format: 'Euro',
              sortField: 'leased_space_eur',
              groups: [
                {
                  value: 'leased_space_eur',
                  label: i18n.t('Leased'),
                },
              ],
            },
          ],
        },
        plugins: [{
          beforeInit: function (chart) {
             chart.data.labels.forEach(function (e, i, a) {
                if (/\n/.test(e)) {
                   a[i] = e.split(/\n/);
                }
             });
          },
        }],
        small: false,
        loading: true,
      },
    ]
  },
}
