<template>
  <v-stepper
    v-model="step"
    class="c-stepper"
    non-linear
  >
    <v-stepper-header>
      <template v-for="s in steps">
        <v-stepper-step
          :key="`${s.id}-step`"
          :step="s.id + 1"
          :rules="s.rules"
          class="c-stepper__step"
          editable
          error-icon="priority_high"
          tabindex="-1"
          @click="$emit('changeStep', s.id + 1)"
        >
          <button 
            class="text-left"
            @click="$emit('changeStep', s.id + 1)"
          >
            {{ $t(s.title) }}
            <small
              v-if="s.description"
              class="mt-1"
            >
              {{ $t(s.description) }}
            </small>
          </button>
        </v-stepper-step>
        <v-divider
          v-if="s.id !== steps.length - 1"
          :key="`${s.id}-divider`"
        />
      </template>
    </v-stepper-header>
  </v-stepper>
</template>
<script>
export default {
  name: 'CdsStepper',
  props: {
    currentStep: {
      type: Number,
      default: 1
    },
    steps: {
      type: Array,
      default: null
    },
  },
  emits: ['changeStep'],
  data () {
    return {
      step: 1
    }
  },
  watch: {
    currentStep (currentStep) {
      this.step = currentStep + 1
    },
  },
}
</script>
<style lang="scss" scoped>
.c-stepper {
  margin: 0 -16px;
}
.c-stepper ::v-deep .v-stepper__step__step {
  color: var(--r-color-ocean-100) !important;
  background-color: var(--r-color-cyan-20) !important;
  font-weight: 700;
}
.c-stepper ::v-deep .v-stepper__step__step.primary {
  color: var(--r-color-white) !important;
  background-color: var(--r-color-ocean-100) !important;
}
.c-stepper ::v-deep .v-stepper__label {
  font-weight: 500;
  color: var(--r-color-ocean-100) !important;
}

.c-stepper ::v-deep .v-stepper__step.v-stepper__step--error .v-stepper__step__step {
  color: var(--r-color-danger-100) !important;
  background-color: var(--r-color-danger-20) !important;
}
.c-stepper ::v-deep .v-stepper__step.v-stepper__step--error .v-stepper__label {
  color: var(--r-color-danger-100) !important;
}

.c-stepper ::v-deep .v-stepper__step__step .v-icon.v-icon {
  font-size: var(--r-font-size-m);
}
.c-stepper ::v-deep.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none;
}
.c-stepper ::v-deep .v-stepper__header {
  height: 64px;
}
.c-stepper ::v-deep.v-stepper .v-stepper__step {
  border-radius: var(--c-radius-xl);
  padding: 0 var(--r-spacing-l);
  margin: 0 var(--r-spacing-m);
  transition: all 0.25s ease-in-out;
}
</style>
