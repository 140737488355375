<template>
  <BaseModal
    :is-loading="isLoading"
    @cancel="close"
  >
    <template #title>
      <h2 class="text-h5">
        {{ $t("create_rent_contract.contract") + ' ' + (currentPage + 1) + '/' + pages.length }}
      </h2>
    </template>
    <template #content>
      <div class="pt-1 px-2 pb-3">
        <SkipTo
          :label="$t('skip-to-tab-content')"
          to="#tab-content"
        />
        <v-row
          class="pb-1 align-top"
        >
          <v-col
            cols="8"
            md="3"
          >
            <span 
              v-if="outRent !== undefined"
              class="text-body-2 font-weight-bold"
            >
              <span>{{ outRent ? $t("Renting out") + ' ' : $t("Renting in") + ' ' }}</span><span id="contract_number">{{ contractNumber }}</span>
              <br>
            </span>

            <span class="text-body-2 font-weight-bold">
              {{ $t("create_rent_contract.rent_contract_duration") }}
              {{ headerText }}
            </span>
          </v-col>
          <v-col
            cols="2"
            md="6"
            class="text-body-2 font-weight-bold text-center"
          >
            {{ $t(contractSigningState) }}
          </v-col>
          <v-col
            cols="2"
            md="3"
            class="text-right"
          >
            <v-btn
              fab
              x-small
              class="blue-button"
              elevation="0"
              @click="showHistoryDialog = true"
            >
              <v-icon size="24px">
                history
              </v-icon>
              <span class="d-sr-only">{{ $t('Change history') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-divider />
      <v-dialog
        v-model="showHistoryDialog"
        persistent
        max-width="550"
      >
        <v-card>
          <ContractHistoryModal
            :contract-id="contractId"
            :user-info="userInfo"
            :all-parties="allParties"
            :is-updating-allowed="isUpdatingAllowed"
            @close="showHistoryDialog = false"
          />
        </v-card>
      </v-dialog>

      <!-- Pagination buttons -->
      <div class="text-center pb-4 pt-2">
        <v-tooltip
          v-for="page in pages"
          :key="page.id"
          top
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              icon
              x-small
              rounded
              class="pa-0 ma-0"
              v-on="on"
              @click="currentPage = page.id"
            >
              <v-icon
                :class="currentPage === page.id ? 'current-icon' : page.state + '-icon'"
                small
              >
                circle
              </v-icon>
              <span class="d-sr-only">
                {{ $t('Page') + ' ' + (page.id + 1) }}
                {{ currentPage === page.id ? ' — ' + $t('leasing.current_page_tooltip') : '' }} 
                {{ isUpdatingAllowed ? ' — ' + $t(`leasing.${page.state}_tooltip`) : '' }}
              </span>
            </v-btn>
          </template>
          <span>
            {{ $t('Page') + ' ' + (page.id + 1) }}
            {{ currentPage === page.id ? ' — ' + $t('leasing.current_page_tooltip') : '' }} 
            {{ isUpdatingAllowed ? ' — ' + $t(`leasing.${page.state}_tooltip`) : '' }}
          </span>
        </v-tooltip>
      </div>
        
      <!-- Tabs -->
      <v-col #extension>
        <v-tabs
          v-model="currentPage"
        >
          <v-tab
            v-for="page in pages"
            :key="page.id"
          />
        </v-tabs>
      </v-col>
      <v-tabs-items
        id="tab-content"
        v-model="currentPage"
      >
        <!-- First Tab -->
        <RentContractBasicInfoTab
          :is-active="currentPage === 0"
          :contract="contractToUpdate"
          :is-out-rent="outRent"
          :all-parties="allParties"
          :is-updating-allowed="isUpdatingAllowed"
          @updated="onBasicInfoTabUpdated"
          @isPageValidChanged="onIsBasicInfoTabValidChanged"
          @updateParties="getParties"
        />
        <!-- Second Tab -->
        <RentContractAdditionalInfoTab
          :is-active="currentPage === 1"
          :contract-id="contractId"
          :contract="contractToUpdate"
          :is-new-contract="isNewContract"
          :new-contract-number="newContractNumber"
          :contract-numbers="contractNumbers"
          :additional-info="additionalInfo"
          :tenant-id="tenantId"
          :tenant-type="tenantType"
          :is-out-rent="outRent"
          :all-parties="allParties"
          :settings="settings"
          :tags="tags"
          :contract-template="currentContractTemplate"
          @additionalInfoUpdated="onAdditionalInfoUpdated"
          @contractNumberUpdated="onContractNumberUpdated"
          @contractNumberValidated="contractNumberValidated"
        />
        <!-- Third Tab -->
        <RentContractRentPeriodTab
          :is-active="currentPage === 2"
          :contract="contractToUpdate"
          :contract-id="contractId"
          :is-out-rent="outRent"
          :is-updating-allowed="isUpdatingAllowed"
          :contract-template="currentContractTemplate"
          @updated="onRentPeriodTabUpdated"
          @isPageValidChanged="onIsRentPeriodTabValidChanged"
          @getUpdatedData="updateValuesFromExistingContract"
          @terminationPdf="contractTerminationPdf"
        />
        <!-- Fourth Tab -->
        <RentContractBillingTab
          :is-active="currentPage === 3"
          :contract="contractToUpdate"
          :contract-id="contractId"
          :is-out-rent="outRent"
          :currency-list="userCurrencies"
          @updated="onBillingTabUpdated"
          @isPageValidChanged="onIsBillingTabValidChanged"
        />
        <!-- Fifth tab  -->
        <RentContractUnitSelectionTab
          v-if="isUpdatingAllowed"
          :is-active="currentPage === 4"
          :existing-units="existingUnits"
          :date="new Date(rentPeriodStartDate)"
          :portfolio-data="existingPortfolio"
          :is-out-rent="outRent"
          :is-connected-to-portfolio="isConnectedToPortfolio"
          :settings="settings"
          @changedContractAttachmentType="onChangedContractAttachedType"
          @updated="onUnitSelectionTabUpdated"
          @isLoading="onIsUnitSelectionTabLoading"
        />
        <!-- Sixth tab  -->
        <RentContractUnitDetailsTab
          :is-new-contract="isNewContract"
          :validity-type="validityType"
          :is-active="currentPage === 5"
          :selected-targets="selectedUnits"
          :rent-period-start-date="rentPeriodStartDate"
          :rent-contract-end-date="rentContractEndDate"
          :portfolio="selectedPortfolio"
          :is-out-rent="outRent"
          :is-connected-to-portfolio="isConnectedToPortfolio"
          :settings="settings"
          :tenant="tenant"
          :addit-info-units="additionalInfoUnits"
          :addit-info-parking="additionalInfoParking"
          :additional-info="{ units: additionalInfoUnits, parking: additionalInfoParking }"
          :contract-template="currentContractTemplate"
          @updated="onUnitDetailsTabUpdated"
          @additionalInfoUpdated="onUnitDetailsAdditionalInfoUpdated"
          @portfolioDataChanged="onPortfolioDetailsUpdated"
          @isPageValidChanged="onIsUnitDetailsTabValidChanged"
        />
        <!-- Seventh tab  -->
        <RentPaymentsTab
          :is-active="currentPage === 6"
          :contract-id="contractId"
          :contract="contractToUpdate"
          :selected-units="selectedUnits"
          :single-payments="singlePayments"
          :contract-payments="contractPayments"
          :separate-payments="separatePayments"
          :rent-period-start-date="rentPeriodStartDate"
          :rent-payment-domains="rentPaymentDomains"
          :is-out-rent="outRent"
          :is-internal-contract="isInternalContract"
          :selected-buildings="selectedBuildings"
          :selected-currency="selectedCurrency"
          :contract-template="currentContractTemplate"
          @paymentsUpdated="onPaymentsUpdated"
        />
        <!-- Eight tab  -->
        <RentContractGuaranteesTab
          :is-active="(currentPage === 7)"
          :contract-id="contractId"
          :guarantees="guarantees"
          :selected-currency="selectedCurrency"
          @guaranteesUpdated="onGuaranteesUpdated"
        />
        <!-- Ninth tab  -->
        <RentContractConditionsTab
          :is-active="currentPage === 8"
          :contract-conditions="contractConditions"
          :is-out-rent="outRent"
          @updated="onContractConditionsUpdated"
          @isPageValidChanged="onIsContractConditionsTabValidChanged"
        />
        <!-- Tenth tab  -->
        <RentContractAttachmentsTabVue
          :is-active="currentPage === 9"
          :attachments="attachments"
          :is-updating-allowed="isUpdatingAllowed"
          :signed-contract-pdf="signedContractPdf"
          :contract-number="contractNumber"
          :tenant-type="tenantType"
          @updated="onAttachmentsUpdated"
        />
        <!-- Elevent tab  -->
        <SendContractForSigningTabVue
          v-if="isUpdatingAllowed"
          :is-active="(currentPage === 10)"
          :contract-id="contractId"
          :landlord-id="landlordId"
          :tenant-id="tenantId"
          :other-tenant-id="otherTenantId"
          :id-landlord-contact-person="idLandlordContactPerson"
          :tenant-type="tenantType"
          :first-tab-tenant-type-change="firstTabTenantTypeChange"
          :contract="contractToUpdate"
          :required-field-validation-messages="requiredFieldValidationMessages"
          :show-validation-message="showValidationMessage"
          :all-parties="allParties"
          :is-out-rent="outRent"
          :settings="settings"
          @updated="onSigningTabUpdated"
          @downloadContract="onDownloadContract"
          @isPageValidChanged="onIsSendContractForSigningTabValidChanged"
        />
        <!-- Last tab/Is active if the contract has no electronic signing  -->
        <NoElectronicSigningTab
          v-if="isUpdatingAllowed"
          :is-active="(currentPage === 11)"
          :contract-id="contractId"
          :contract="contractToUpdate"
          :contractpdfid="contractPDFId"
          :contract-signing-state="contractSigningState"
          @updated="onNonElectronicSigningTabUpdated"
          @downloadContract="onDownloadContract"
          @ready="setNonElectronicContractReadyToStart"
        />
      </v-tabs-items>
    </template>
    <template #hideCancel>
      <p />
    </template>
    <template #footer>
      <v-col
        cols="4"
        class="text-left pa-0"
      >
        <v-btn
          v-if="currentPage !== 0"
          text
          rounded
          outlined
          depressed
          @click="currentPage--"
        >
          <v-icon left>
            arrow_back_ios
          </v-icon>
          {{ $t('Previous') }}
          <span class="d-sr-only">{{ ` ${$t('Page')}` }}</span>
        </v-btn>
      </v-col>
      <v-col
        v-if="isDraft != false"
        cols="4"
        class="text-center pa-0"
      >
        <v-btn
          v-if="isUpdatingAllowed"
          id="save_as_draft"
          :disabled="isSigningProcessLoading || isUnitSelectionTabLoading || !isContractNumberValid"
          :loading="isSaving"
          class="primary"
          rounded
          depressed
          @click="saveAsDraft"
        >
          {{ $t('create_rent_contract.save_as_draft') }}
        </v-btn>
      </v-col>
      <v-col
        v-if="isDraft == false"
        cols="4"
        class="text-center pa-0"
      >
        <div v-if="showValidationMessage">
          <div
            v-for="requiredFieldValidationMessage in requiredFieldValidationMessages"
            :key="requiredFieldValidationMessage"
          >
            <p class="form-fields validation-error">
              {{ requiredFieldValidationMessage }}
            </p>
          </div>
        </div>
        <v-btn
          v-if="isUpdatingAllowed"
          :disabled="isSaving || isUnitSelectionTabLoading"
          color="tertiary"
          rounded
          depressed
          @click="changeToDraft"
        >
          {{ $t('create_rent_contract.mark_as_draft') }}
        </v-btn>
        <v-btn
          v-if="isUpdatingAllowed"
          :disabled="isSigningProcessLoading || isUnitSelectionTabLoading"
          :loading="isSaving"
          color="primary"
          rounded
          depressed
          @click="NonDraftContractSave"
        >
          {{ $t('Save') }}
        </v-btn>
      </v-col>
        
      <v-col
        cols="4"
        class="text-right pa-0"
      >
        <div class="save-error">
          {{ errorMessage ? errorMessage : '' }}
        </div>
        <div>
          <v-btn
            v-if="(!isUpdatingAllowed && currentPage < pages.length - 1) || (isUpdatingAllowed && currentPage !== 10 && currentPage !== 11 || currentPage == 10 && isDraft == false && isElectronicSigning == false)"
            text
            rounded
            outlined
            depressed
            @click="currentPage++"
          >
            {{ $t('Next') }}
            <span class="d-sr-only">{{ ` ${$t('Page')}` }}</span>
            <v-icon right>
              arrow_forward_ios
            </v-icon>
          </v-btn>
          <v-tooltip
            v-if="currentPage == 10 && (contractSigningState == 'ContractStateDraft' || contractSigningState == undefined) && (isElectronicSigning == true || isElectronicSigning == undefined)"
            top
          >
            <template #activator="{ on }">
              <v-btn
                :disabled="isSaving || isUnitSelectionTabLoading || !isUpdatingAllowed"
                :loading="isSigningProcessLoading"
                :aria-label="$t('create_rent_contract.send_btn_tooltip')"
                color="primary"
                rounded
                depressed
                v-on="on"
                @click="onSendButtonClicked"
              >
                <v-icon small>
                  send
                </v-icon>
                {{ $t('create_rent_contract.send') }}
              </v-btn>
            </template>
            <span>{{ $t('create_rent_contract.send_btn_tooltip') }}</span>
          </v-tooltip>
          <v-tooltip
            v-if="currentPage == 10 && isElectronicSigning == false && isDraft == true"
            top
          >
            <template #activator="{ on }">
              <v-btn
                v-if="isUpdatingAllowed"
                :disabled="isSaving || isUnitSelectionTabLoading"
                :loading="isSigningProcessLoading"
                :aria-label="$t('create_rent_contract.continue_btn_tooltip')"
                color="primary"
                rounded
                depressed
                v-on="on"
                @click="NonElectronicSigningContinue"
              >
                <v-icon small>
                  lock_open
                </v-icon>
                {{ $t('Continue') }}

                <v-icon
                  small
                  right
                >
                  arrow_forward_ios
                </v-icon>
              </v-btn> 
            </template>
            <span>
              {{ $t('create_rent_contract.continue_btn_tooltip') }}</span>
          </v-tooltip>
          <v-btn
            v-if="currentPage == 11 && contractSigningState !== 'ContractStateSigned' && contractSigningState !== 'ContractStateStarting' && isUpdatingAllowed"
            :disabled="isSaving || (!nonElectronicContractReadyToStart && isOutRent)"
            :loading="isSigningProcessLoading"
            class="secondary"
            rounded
            depressed
            @click="NonElectronicSigningReady"
          >
            <v-icon small>
              check
            </v-icon>
            {{ $t('Done') }}
          </v-btn> 
          <v-btn
            v-if="contractSigningState !== undefined && (contractSigningState == 'ContractStateReadyForSigning' || contractSigningState == 'ContractStateSigningRequestSent' ) && isElectronicSigning == true && isUpdatingAllowed"
            :aria-label="$t('create_rent_contract.cancel_signature_request')"
            color="error"
            rounded
            depressed
            @click="cancelContract"
          >
            <v-icon small>
              cancel
            </v-icon>
            {{ $t('create_rent_contract.cancel_signature_request') }}
          </v-btn>
        </div>
      </v-col>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import BaseModal from '../../../general/BaseModal'
import SkipTo from '../../../general/SkipTo'
import RentContractBasicInfoTab from './RentContractBasicInfoTab.vue'
import RentContractRentPeriodTab from './RentContractRentPeriodTab.vue'
import RentContractUnitSelectionTab from './RentContractUnitSelectionTab.vue'
import RentContractUnitDetailsTab from './RentContractUnitDetailsTab.vue'
import RentContractAdditionalInfoTab from './RentContractAdditionalInfoTab.vue'
import RentContractAttachmentsTabVue from './RentContractAttachmentsTab.vue'
import RentContractBillingTab from './RentContractBillingTab.vue'
import RentPaymentsTab from './RentPaymentsTab.vue'
import RentContractGuaranteesTab from './RentContractGuaranteesTab.vue'
import RentContractConditionsTab from './RentContractConditionsTab.vue'
import SendContractForSigningTabVue from './SendContractForSigningTab.vue'
import NoElectronicSigningTab from './NoElectronicSigningTab.vue'
import ContractHistoryModal from './ContractHistoryModal.vue'
import moment from 'moment'
import services from './Services'
import paymentServices from './Payments/Services'
import customPdfTemplate from './Services/pdf/customPdfTemplate.js'
import pdfDataHelper from './Services/pdf/pdfDataHelper.js'
import lodash from 'lodash'
import helpers from '../../../../helpers'

export default {
  name: "CreateRentContractModal",
    components: {
      BaseModal,
      SkipTo,
      RentContractBasicInfoTab,
      RentContractRentPeriodTab,
      RentContractUnitSelectionTab,
      RentContractUnitDetailsTab,
      RentContractAdditionalInfoTab,
      RentContractBillingTab,
      RentContractAttachmentsTabVue, 
      RentPaymentsTab,
      RentContractGuaranteesTab,
      RentContractConditionsTab,
      SendContractForSigningTabVue,
      NoElectronicSigningTab,
      ContractHistoryModal
    },
  props: {
    contractId: { type: Number, default: undefined },
    outRent: { type: Boolean, default: undefined },
    contractNumbers: { type: Array, default: undefined },
    readOnly: { type: Boolean, default: false },
    contractTemplate: { type: Object, default: undefined }
  },
  data () {
    return {
      landlordId: undefined,
      landlordHasEmail: false,
      tenantsHasEmails: false,
      landlordContactPersonEmail: undefined,
      tenantId: undefined,
      reviewFreq: undefined,
      rentReviewMonth: undefined,
      rentContractEndDate: undefined,
      rentPeriodStartDate: undefined,
      firstPossibleReviewDate: undefined,
      baseIndex: undefined,
      indexReviewMonths: undefined,
      rentDecreaseAccordingIndex: false,
      additionalContractTerms: "",
      validityType: 'validity_fixed',
      rentTimeYears: 1,
      rentReviewType: null,
      contractNumber: "",
      isContractNumberValid: true,
      contractSigningState: undefined,
      isElectronicSigning: true,
      selectedUnits: undefined,
      currentPage: 0,
      pages: [
        { id: 0, state: 'success'},
        { id: 1, state: 'success'},
        { id: 2, state: 'success'},
        { id: 3, state: 'success'},
        { id: 4, state: 'success'},
        { id: 5, state: 'success'},
        { id: 6, state: 'success'},
        { id: 7, state: 'success'},
        { id: 8, state: 'success'},
        { id: 9, state: 'success'},
        { id: 10, state: 'success'}
      ],
      noticePeriodTenant: null,
      noticePeriodLandlord: null,
      earliestNoticeDateTenant: null,
      earliestNoticeDateLandlord: null,
      isBasicInfoTabValid: false,
      isUnitDetailsTabValid: false,
      errorMessage: undefined,
      contractToUpdate: undefined,
      existingUnits: undefined,
      isUnitSelectionTabLoading: false,
      validityAdditionalInfo: undefined,
      billingStartDate: undefined,
      tenureStartDate: undefined,
      additionalExtensionTerms: undefined,
      defBillingAddress: null,
      defRentIncreaseLettersAddress: null,
      billingCycleLength: null,
      firstBillingMonth: null,
      isRetrospectiveBilling: false,
      ovtCode: undefined,
      payerReference: undefined,
      contractSpecificName: undefined,
      contractSpecificBusinessId: undefined,
      contractSpecificAddress: undefined,
      contractSpecificPostalCode: undefined,
      contractSpecificPostalCity: undefined,
      automaticBillingAllowed: undefined,
      automaticBillingReasoning: undefined,
      isDraft: true,
      singlePayments: [],
      contractPayments: [],
      separatePayments: [],
      attachments: [],
      uploadProgressPercentage: 0,
      tenantIdentityNumber: undefined,
      tenantType: 'company',
      isOutRent: true,
      idLandlordContactPerson: undefined,
      idTenantContactPerson: undefined,
      otherTenantId: undefined,
      otherTenantIdentityNumber: undefined,
      additionalInfo: undefined,
      guarantees: [],
      rentPaymentDomains: [],
      contractConditions: {},
      landlordSignatory: undefined,
      tenantSignatories: undefined,
      signedContract: undefined,
      signedPlace: undefined,
      signedDate: undefined,
      signedContractFileData: {
        idBuilding: null,
        fileName: '',
        title: '',
        description: '',
        type: '',
        versionDescription: '',
        tags: [],
        idCemetery: null,
        idSection: null,
        idGrave: null
      },
      contractPDFId: undefined,
      isRetrievingFile: false,
      tenantClientele: undefined,
      allowAutomaticBillingData: null,      
      requiredFieldValidationMessages: [],
      pageMessages: [],
      showValidationMessage: false,
      nonElectronicContractReadyToStart: false,
      squareBased: false,
      nonElectronicSigningContractID: undefined,
      signedContractPdf: undefined,
      allParties: [],
      initialContract: undefined,
      isLoading: true,
      showHistoryDialog: false,
      historyEvents : {
        savedAsDraft: 1,
        sendToSigning: 2,
        contractSigned: 3,
        signingRequestCancelled: 10
      },
      selectedPortfolio: undefined,
      IdPortfolio: undefined,
      portfolioStartDate: undefined,
      portfolioEndDate: undefined,
      portfolioAgreedArea: undefined,
      existingPortfolio: undefined,
      isConnectedToPortfolio: true,
      canConnectToPortfolio: undefined,
      newContractNumber: undefined,
      selectedBuildings: undefined,
      firstTabTenantTypeChange: false,
      tags: [],
      tenantExtensionPeriod: null,
      landlordExtensionPeriod: null,
      extensionNoticePeriod: null,
      currencyId: undefined,
      additionalInfoPayments: undefined,
      additionalInfoUnits: undefined,
      additionalInfoParking: undefined,
      // contractTemplate will be modified in this component. As such, we map it to currentContractTemplate
      currentContractTemplate: this.contractTemplate,
      isInternalContract: false,
      isSaving: false,
      isSigningProcessLoading: false,
    }
  },
  computed: {
    ...mapGetters('app', [  
      'definitionsByGroupLabel', 
      'definitionLabelById', 
      'customerLogo', 
      'contractConditionsTemplates', 
      'definitionByLabel', 
      'hasApplicationPermissionByName', 
      'currencies',
      ]),
    ...mapGetters('rentContracts', ['getContractTemplateById']),
    ...mapState('app', ['userInfo', 'settings', 'buildings', 'currentDate', 'settings']),
    rentReviewTypes () {
      return this.definitionsByGroupLabel('rentaloffer.rentreviewtype')
    },
    headerText () {
    let fromDate;
      if (!this.rentPeriodStartDate) {
        fromDate = new Date().toISOString().split('T')[0]
        fromDate = moment().format("DD.MM.YYYY")
        const toDate = this.rentContractEndDate ? moment(this.rentContractEndDate, "YYYY-MM-DD").format("DD.MM.YYYY") : ""
        return `${fromDate}–${toDate}`
      }
      else {
        fromDate = moment(this.rentPeriodStartDate, "YYYY-MM-DD").format("DD.MM.YYYY")
        const toDate = this.rentContractEndDate ? moment(this.rentContractEndDate, "YYYY-MM-DD").format("DD.MM.YYYY") : ""
        return `${fromDate}–${toDate}`
      }
    },
    isNewContract () {
      return !this.contractId
    },
    updatedContract () {
      // Leave the netSum out of the comparison, API doesn't provide it for the existing contract
      const updatedContract = this.getContractData()
      updatedContract.contractPayments.map(r => {
        delete r.netSum
        return r
      })
      updatedContract.separatePayments.map(r => {
        delete r.netSum
        return r
      })
      return { ...updatedContract, additionalInfo: { ...this.additionalInfo } }
    },
    isChanged () {
      return !lodash.isEqual(this.initialContract, this.updatedContract)
    },
    isSignatoriesRequired () {
      return this.isOutRent
        && this.contractSigningState !== 'ContractStateSigned'
        && this.contractSigningState !== 'ContractStateStarting'
        && this.contractSigningState !== 'ContractStateExpired'
    },
    isUpdatingAllowed () {
      if (this.readOnly) {
        return false
      }
      return this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_MUOKKAUS')
    },
    tenant () {
      return this.allParties.find(party => party.id == this.tenantId)
    },
    userCurrencies () {
      return this.settings.availableCurrencies.map(ac => { return this.currencies.find(c => c.code === ac) })
    },
    selectedCurrency () {
      return this.currencies.find(c => c.id === this.currencyId)
    },
  },
  watch: {
    currentPage () {
      this.formRequiredFieldValidationMessage();
      if (this.currentPage == this.pages.length - 1 && this.showValidationMessage && this.pageMessages.length == 0)
        this.showValidationMessage = false;
    },
    tenantId () {
      this.getAllRentPaymentDomains()
    },
    contractTemplate () {
      /**
       * After this component is created, we need to make sure the current contract template is updated as well
       * E.g. top-level component changes contract template and this modal should reflect that change
       */
      this.currentContractTemplate = helpers.clone(this.contractTemplate);
    }
  },
  async mounted () {
    this.isOutRent = this.outRent
    await this.getSettings()
    if (this.settings.length === 0) {
      this.canConnectToPortfolio = false;
    } else {
      this.canConnectToPortfolio = this.isOutRent == true
        ? this.settings.outrentCanConnectContractToPortfolio
        : this.settings.inrentCanConnectContractToPortfolio;
    }

    await this.getParties()
    await this.getAllRentPaymentDomains()
    if (this.contractId) {
      await this.updateValuesFromExistingContract()
    }

    if (this.isNewContract) {
      this.newContractNumber = await this.$rambollfmapi.contracts.contract.GetNewContractNumber(this.outRent)
      this.contractNumber = this.newContractNumber
      // this.isOutRent is updated from contract in this.updateValuesFromExistingContract
      // This block is only ran if this.contractId does not exist
      this.isOutRent = this.outRent
    }

    this.fetchAllLevels()
    this.getCustomerLogo()
    this.formRequiredFieldValidationMessage()
    this.initialContract = lodash.cloneDeep({ ...this.getContractData(), additionalInfo: { ...this.additionalInfo }})
    this.CheckContractModalPagesAmount()
    this.isLoading = false
  },
  methods: {
    ...mapActions('app', ['getCustomerLogo','getSettings']),
    ...mapActions("calculator", ["addTenantId","clearTenantIds"]),
    ...mapActions("costCenters", ["fetchAllLevels"]),
    signatoryEmails () {
      const landlordContactPerson = this.allParties.find(party => party.id == this.idLandlordContactPerson)
      this.landlordContactPersonEmail = landlordContactPerson !== undefined ? landlordContactPerson.email : ""
      const landLordSignatoryContactPerson = this.allParties.find(party => party.id == this.landlordSignatory)
      const landlordSignatoryEmail = landLordSignatoryContactPerson !== undefined ? landLordSignatoryContactPerson.email : ""
      this.landlordHasEmail = landlordSignatoryEmail === null || landlordSignatoryEmail.length < 1 ? false : true
      let tenantSignatoryEmails = []
       this.tenantSignatories.forEach(id => {
          const idData = this.allParties.filter(party => party.id === id).map(p => (
            {
              email: p.email,
            }
          ))
          tenantSignatoryEmails.push(idData[0].email) 
        })
      if (!tenantSignatoryEmails.includes(null) && !tenantSignatoryEmails.includes(e => e.length < 1))
        this.tenantsHasEmails = true
    },
    onAdditionalInfoUpdated ({ additionalInfo, tags }) {
      this.additionalInfo = additionalInfo
      this.tags = tags
      this.formRequiredFieldValidationMessage()
    },
    contractNumberValidated (valid) {
      this.isContractNumberValid = valid
    },
    onContractNumberUpdated (contractNumber) {
      if (contractNumber)
        this.contractNumber = contractNumber
    },
    onPaymentsUpdated (singlePayments, contractPayments, separatePayments, additionalInfo) {
      this.singlePayments = singlePayments
      this.contractPayments = contractPayments
      this.separatePayments = separatePayments
      this.additionalInfoPayments = additionalInfo
      this.formRequiredFieldValidationMessage()
    },
    onGuaranteesUpdated (guarantees) {
      this.guarantees = guarantees
      this.formRequiredFieldValidationMessage()
    },
    async updateValuesFromExistingContract () {
      this.updateUnitValues()
      const contractWithRows = await this.$rambollfmapi.contracts.contract.getWithRows({ contractId: this.contractId })
      this.currentContractTemplate = this.getContractTemplateById(contractWithRows.contract.idContractTemplate)
      this.singlePayments = contractWithRows.payments.singlePayments
      this.contractPayments = contractWithRows.payments.contractPayments
      this.separatePayments = contractWithRows.payments.separatePayments
      this.guarantees = contractWithRows.guarantees
      this.contractNumber = contractWithRows.contract.contractNumber
      this.contractSigningState = contractWithRows.contract.contractSigningState
      this.isElectronicSigning = contractWithRows.contract.isElectronicSigning
      this.landlordId = contractWithRows.contract.landlordId
      this.otherTenantId = contractWithRows.contract.otherTenantId
      this.reviewFreq = contractWithRows.contract.reviewFrequency
      this.rentReviewMonth = contractWithRows.contract.rentReviewMonth
      this.rentReviewType = contractWithRows.contract.rentReviewType
      this.rentContractEndDate = contractWithRows.contract.endDate?.split('T')[0]
      this.rentPeriodStartDate = contractWithRows.contract.startDate?.split('T')[0]
      this.firstPossibleReviewDate = contractWithRows.contract.firstPossibleReviewDate?.split('T')[0],
      this.baseIndex = contractWithRows.contract.baseIndex
      this.indexReviewMonths = contractWithRows.contract.indexReviewMonths
      this.rentDecreaseAccordingIndex = contractWithRows.contract.rentDecreaseAccordingIndex
      this.validityType = contractWithRows.contract.isFixedTerm ? "validity_fixed" : 'validity_permanent'
      this.additionalContractTerms = contractWithRows.contract.additionalContractTerms
      this.earliestNoticeDateTenant = contractWithRows.contract.earliestNoticeDateTenant?.split('T')[0]
      this.earliestNoticeDateLandlord = contractWithRows.contract.earliestNoticeDateLandlord?.split('T')[0]
      this.noticePeriodLandlord = contractWithRows.contract.noticePeriodLandlord
      this.noticePeriodTenant = contractWithRows.contract.noticePeriodTenant
      this.tenantExtensionPeriod = contractWithRows.contract.tenantExtensionPeriod
      this.landlordExtensionPeriod = contractWithRows.contract.landlordExtensionPeriod
      this.extensionNoticePeriod = contractWithRows.contract.extensionNoticePeriod
      this.validityAdditionalInfo = contractWithRows.contract.validityAdditionalInfo
      this.billingStartDate = contractWithRows.contract.billingStartDate?.split('T')[0]
      this.tenureStartDate = contractWithRows.contract.tenureStartDate?.split('T')[0]
      this.additionalExtensionTerms = contractWithRows.contract.additionalExtensionTerms
      this.defBillingAddress = contractWithRows.contract.defBillingAddress,
      this.defRentIncreaseLettersAddress = contractWithRows.contract.defRentIncreaseLettersAddress,
      this.billingCycleLength = contractWithRows.contract.billingCycleLength,
      this.firstBillingMonth = contractWithRows.contract.firstBillingMonth,
      this.isRetrospectiveBilling = contractWithRows.contract.isRetrospectiveBilling,
      this.ovtCode = contractWithRows.contract.ovtCode,
      this.payerReference = contractWithRows.contract.payerReference,
      this.contractSpecificName = contractWithRows.contract.contractSpecificName,
      this.contractSpecificBusinessId = contractWithRows.contract.contractSpecificBusinessId,
      this.contractSpecificAddress = contractWithRows.contract.contractSpecificAddress,
      this.contractSpecificPostalCode = contractWithRows.contract.contractSpecificPostalCode,
      this.contractSpecificPostalCity = contractWithRows.contract.contractSpecificPostalCity,
      this.automaticBillingAllowed = contractWithRows.contract.automaticBillingAllowed,
      this.automaticBillingReasoning = contractWithRows.contract.automaticBillingReasoning,
      this.isDraft = contractWithRows.contract.isDraft
      this.tenantIdentityNumber = contractWithRows.contract.tenantIdentityNumber
      this.otherTenantIdentityNumber = contractWithRows.contract.otherTenantIdentityNumber
      this.tenantType = contractWithRows.contract.isTenantCompany ? "company" : 'person'
      this.isOutRent = contractWithRows.contract.isOutRent
      this.idLandlordContactPerson = contractWithRows.contract.idLandlordContactPerson
      this.idTenantContactPerson = contractWithRows.contract.idTenantContactPerson
      this.contractConditions = contractWithRows.contractConditions
      this.landlordSignatory = contractWithRows.contract.landlordSignatory
      this.tenantSignatories = contractWithRows.contract.tenantSignatories
      this.signedPlace = contractWithRows.contract.signedPlace
      this.signedDate = contractWithRows.contract.signedDate
      this.IdPortfolio = contractWithRows.contract.idPortfolio
      this.currencyId = contractWithRows.contract.currencyId
      this.additionalInfoPayments = contractWithRows.contract.additionalInfoPayments
      this.additionalInfoUnits = contractWithRows.contract.additionalInfoUnits
      this.additionalInfoParking = contractWithRows.contract.additionalInfoParking
      this.existingPortfolio = {
        OriginalId: this.IdPortfolio,
        Name: null,
        Area: null,
        AgreedArea: contractWithRows.contract.portfolioAgreedArea,
        Startdate: contractWithRows.contract.portfolioStartDate,
        Enddate: contractWithRows.contract.portfolioEndDate,
      }
      this.signatoryEmails()

      this.contractToUpdate = {
        contractNumber: contractWithRows.contract.contractNumber,
        contractSigningState: contractWithRows.contract.contractSigningState,
        isElectronicSigning: contractWithRows.contract.isElectronicSigning,
        landlordId: this.landlordId,
        tenantId: contractWithRows.contract.tenantId,
        otherTenantId: contractWithRows.contract.otherTenantId,
        reviewFreq: this.reviewFreq,
        rentReviewMonth: this.rentReviewMonth,
        rentReviewType: this.rentReviewType,
        rentContractEndDate: this.rentContractEndDate,
        rentPeriodStartDate: this.rentPeriodStartDate,
        firstPossibleReviewDate: this.firstPossibleReviewDate,
        baseIndex: this.baseIndex,
        indexReviewMonths: this.indexReviewMonths,
        rentDecreaseAccordingIndex: this.rentDecreaseAccordingIndex,
        validityType: this.validityType,
        additionalContractTerms: this.additionalContractTerms,
        earliestNoticeDateTenant: this.earliestNoticeDateTenant,
        earliestNoticeDateLandlord: this.earliestNoticeDateLandlord,
        noticePeriodLandlord: this.noticePeriodLandlord,
        noticePeriodTenant: this.noticePeriodTenant,
        tenantExtensionPeriod: contractWithRows.contract.tenantExtensionPeriod,
        landlordExtensionPeriod: contractWithRows.contract.landlordExtensionPeriod,
        extensionNoticePeriod: contractWithRows.contract.extensionNoticePeriod,
        validityAdditionalInfo: this.validityAdditionalInfo,
        billingStartDate: this.billingStartDate,
        tenureStartDate: this.tenureStartDate,
        additionalExtensionTerms: this.additionalExtensionTerms,
        defBillingAddress: this.defBillingAddress,
        defRentIncreaseLettersAddress: this.defRentIncreaseLettersAddress,
        billingCycleLength: this.billingCycleLength,
        firstBillingMonth: this.firstBillingMonth,
        isRetrospectiveBilling: contractWithRows.contract.isRetrospectiveBilling,
        ovtCode: this.ovtCode,
        payerReference: this.payerReference,
        contractSpecificName: this.contractSpecificName,
        contractSpecificBusinessId: this.contractSpecificBusinessId,
        contractSpecificAddress: this.contractSpecificAddress,
        contractSpecificPostalCode: this.contractSpecificPostalCode,
        contractSpecificPostalCity: this.contractSpecificPostalCity,
        automaticBillingAllowed: this.automaticBillingAllowed,
        automaticBillingReasoning: this.automaticBillingReasoning,
        isDraft: this.isDraft ?? false,
        identityNumber: this.identityNumber,
        tenantType: this.tenantType,
        isOutRent: this.isOutRent ?? false,
        idLandlordContactPerson: this.idLandlordContactPerson,
        idTenantContactPerson: this.idTenantContactPerson,
        tenantIdentityNumber: this.tenantIdentityNumber,
        otherTenantIdentityNumber: this.otherTenantIdentityNumber,
        landlordSignatory: contractWithRows.contract.landlordSignatory,
        tenantSignatories: contractWithRows.contract.tenantSignatories,
        signedPlace: contractWithRows.contract.signedPlace,
        signedDate: contractWithRows.contract.signedDate,
        contractPDFId: contractWithRows.contract.contractPDFId,
        currencyId: contractWithRows.contract.currencyId,
        additionalInfoPayments: this.additionalInfoPayments,
        additionalInfoUnits: this.additionalInfoUnits,
        additionalInfoParking: this.additionalInfoParking,
      }

      this.tenantId = contractWithRows.contract.tenantId
      this.contractPDFId = contractWithRows.contract.contractPDFId

      this.existingUnits = await contractWithRows.rows.map(row => (
        {
          id: row.unitId ? row.unitId : row.structureId ? 'structure'+ row.structureId : 'parking' + row.parkingSpaceId,
          startDate: row.startDate?.split('T')[0],
          endDate: row.endDate?.split('T')[0],
          area: row.contractArea,
          agreedArea: row.agreedArea,
          rent: row.rent,
          id_site: row.siteId,
          rowId: row.rowId,
          unitId: row.unitId,
          unitName: row.unitName,
          siteId: row.siteId,
          buildingId: row.buildingId,
          siteCode: row.siteCode,
          structureId: row.structureId,
          contractArea: row.contractArea,
          site: row.site,
          unitCode: row.unitId ? row.unitName : row.structureId ? row.structureName : row.parkingSpaceName,
          name: this.getUnitStructureOrParkingSpaceName(row),
          address: this.getBuildingAddress(row.buildingId),
          idCostcenter: row.idCostcenter,
          parkingSpaceId: row.parkingSpaceId,
          agreedAmount: row.agreedAmount,
          amount: row.amount,
        }
      ))

      if (this.existingUnits.length > 0 && this.IdPortfolio == undefined) {
        this.isConnectedToPortfolio = false
      }

      this.tags = contractWithRows.contract.tags ?? []

      //#9313
      const additionalinfo = await this.$rambollfmapi.contracts.contract.getAdditionalInfoForContract(this.contractId);
      if (additionalinfo) {
        this.additionalInfo = additionalinfo
      }

      let allAttachments = await this.$rambollfmapi.contracts.contract.getAttachments(this.contractId)
      this.attachments = allAttachments.filter(att => !att.isContractPdf)
      for(let i=0; i< this.attachments.length; i++) {
        let attachment = this.attachments[i];
        attachment.file = { name: attachment.filename, size: attachment.size, type: attachment.type }
      }
      this.signedContractPdf = allAttachments.filter(att => att.isContractPdf)?.[0]
      this.CheckContractModalPagesAmount()
      this.addTenantId(this.tenantId);
      this.addTenantId(this.otherTenantId);
      this.determineInternalityOfContract()
    },
    onIsBasicInfoTabValidChanged (valid) {
      this.isBasicInfoTabValid = valid;
      this.pages[0].state = this.isBasicInfoTabValid ? 'success' : 'error'
    },
    onIsRentPeriodTabValidChanged (valid) {
      this.isRentPeriodTabValid = valid;
      this.pages[2].state = this.isRentPeriodTabValid ? 'success' : 'error'
    },
    onIsBillingTabValidChanged (valid) {
      this.isBillingTabValid = valid;
      this.pages[3].state = this.isBillingTabValid ? 'success' : 'error'
    },
    onIsUnitSelectionTabLoading (loading) {
      this.isUnitSelectionTabLoading = loading;
    },
    onIsUnitDetailsTabValidChanged (valid) {
      this.isUnitDetailsTabValid = valid;
      this.pages[4].state = this.isUnitDetailsTabValid ? 'success' : 'error'
    },
    async changeToDraft () {
      if (confirm(this.$t('Change contract to draft confirmation'))) {
        await this.onDraftButtonClicked()
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.contract_changed_to_draft_window_closed'))
        this.$emit('changedDraft')
        await this.$rambollfmapi.contracts.contract.AddContractEvent(this.historyEvents.savedAsDraft, [this.draftContractId])
      }
    },
    async saveAsDraft () {
      this.isSaving = true
      await this.onDraftButtonClicked()
      this.$store.dispatch('app/addAriaLive', this.$t('aria_live.contract_saved_as_draft_window_closed'))
      this.$emit('savedDraft')
      await this.$rambollfmapi.contracts.contract.AddContractEvent(this.historyEvents.savedAsDraft, [this.draftContractId])
      this.isSaving = false
    },
    close () {
      if (this.isChanged && this.isUpdatingAllowed) {
        if (confirm(this.$t('create_rent_contract.offer_close_confirmation'))) {
          this.$emit('close')
        }
      } else {
        this.$emit('close')
      }
    },
    getContractData () {
      const contract = {
        id: this.contractId,
        contractNumber: this.contractNumber,
        isElectronicSigning: this.isElectronicSigning,
        landlordId: this.landlordId,
        tenantId: this.tenantId,
        otherTenantId: this.otherTenantId,
        rentContract: true,
        reviewFrequency: this.reviewFreq,
        rentReviewMonth: this.rentReviewMonth,
        firstPossibleReviewDate: this.firstPossibleReviewDate,
        rentReviewType: this.rentReviewType,
        baseIndex: this.baseIndex,
        indexReviewMonths: this.indexReviewMonths,
        rentDecreaseAccordingIndex: this.rentDecreaseAccordingIndex,
        additionalContractTerms: this.additionalContractTerms,
        startDate: this.rentPeriodStartDate,
        isFixedTerm: this.validityType === "validity_fixed",
        endDate: this.rentContractEndDate,
        earliestNoticeDateTenant: this.earliestNoticeDateTenant,
        earliestNoticeDateLandlord: this.earliestNoticeDateLandlord,
        noticePeriodTenant: this.noticePeriodTenant,
        noticePeriodLandlord: this.noticePeriodLandlord,
        tenantExtensionPeriod: this.tenantExtensionPeriod,
        landlordExtensionPeriod: this.landlordExtensionPeriod,
        extensionNoticePeriod: this.extensionNoticePeriod,
        validityAdditionalInfo: this.validityAdditionalInfo,
        billingStartDate: this.billingStartDate,
        tenureStartDate: this.tenureStartDate,
        additionalExtensionTerms: this.additionalExtensionTerms,
        defBillingAddress: this.defBillingAddress,
        defRentIncreaseLettersAddress: this.defRentIncreaseLettersAddress,
        billingCycleLength: this.billingCycleLength,
        firstBillingMonth: this.firstBillingMonth,
        isRetrospectiveBilling: this.isRetrospectiveBilling,
        ovtCode: this.ovtCode,
        payerReference: this.payerReference,
        contractSpecificName: this.contractSpecificName,
        contractSpecificBusinessId: this.contractSpecificBusinessId,
        contractSpecificAddress: this.contractSpecificAddress,
        contractSpecificPostalCode: this.contractSpecificPostalCode,
        contractSpecificPostalCity: this.contractSpecificPostalCity,
        automaticBillingAllowed: this.automaticBillingAllowed,
        automaticBillingReasoning: this.automaticBillingReasoning,
        isDraft: this.isDraft,
        tenantIdentityNumber: this.tenantIdentityNumber,
        otherTenantIdentityNumber: this.otherTenantIdentityNumber,
        isTenantCompany: this.tenantType === "company",
        isOutRent: this.isOutRent,
        idLandlordContactPerson: this.idLandlordContactPerson,
        idTenantContactPerson: this.idTenantContactPerson,
        landlordSignatory: this.landlordSignatory,
        tenantSignatories: this.tenantSignatories,
        signedPlace: this.signedPlace,
        signedDate: this.signedDate,
        contractPDFId: this.contractPDFId,
        allowAutomaticBillingType: this.allowAutomaticBillingData?.type,
        allowAutomaticBillingDate: this.allowAutomaticBillingData?.date,
        squareBased: this.squareBased,
        IdPortfolio: this.IdPortfolio,
        portfolioStartDate: this.portfolioStartdate,
        portfolioEndDate: this.portfolioEnddate,
        portfolioAgreedArea: this.portfolioAgreedArea,
        tags: this.tags,
        currencyId: this.currencyId,
        idContractTemplate: this.currentContractTemplate != null && this.currentContractTemplate != undefined ? this.currentContractTemplate.id : null,
        additionalInfoPayments: this.additionalInfoPayments,
        additionalInfoUnits: this.additionalInfoUnits,
        additionalInfoParking: this.additionalInfoParking,
      };

      if (!this.selectedUnits) {
        this.selectedUnits = this.existingUnits;
      }

      const units = this.selectedUnits === undefined ? [] : this.selectedUnits
        .map(u => ({
          unitId: u.unitId,
          structureId: u.structureId,
          parkingSpaceId: u.parkingSpaceId,
          startDate: u.startDate,
          endDate: u.endDate,
          contractArea: u.area,
          agreedArea: u.agreedArea,
          agreedAmount: u.agreedAmount,
          amount: u.amount,
          rent: u.rent,
          rowId: u.rowId,
          siteId: u.siteId,
          siteCode: u.siteCode,
          buildingId: u.buildingId,
          idCostcenter: u.idCostcenter || null
        }))
        .sort((a, b) => a.unitId - b.unitId)

      return {
        contract,
        units,
        singlePayments: this.singlePayments,
        contractPayments: this.contractPayments,
        separatePayments: this.separatePayments,
        guarantees: this.guarantees,
        contractConditions: this.contractConditions,
        attachments: this.attachments
      }
    },
    async onDraftButtonClicked (closeAfterSaving = true) {

      const { contract, units, singlePayments, contractPayments, separatePayments, guarantees, contractConditions, attachments } = this.getContractData()
      contract.isDraft = true;

      const result = await this.saveContract({contract, units, singlePayments, contractPayments, separatePayments, guarantees, contractConditions})
      this.draftContractId = result.contractID

      if (!result.success) {
          this.errorMessage = this.$t("create_rent_contract.unknown_error")
      } else {
        await this.AddAdditionalInfo(result.contractID)
        //Luodaan kantaan uusi dokumentti liitteistä ja lisätään uuteen LIITTEET tauluun tietokannassa tarvittavat FOREIGN KEY viittaukset sopimus ja document tauluihin
        if (attachments && this.contractId) {
          await this.updateAttachmentsForContract(attachments)
        }
        else {
          await this.createAttachmentsForContract(result.contractID, attachments)        
        }

        // Upload contract if it was attached 
        const documentId = await this.uploadContractFile(result.contractID)
        if (documentId !== undefined) {
          contract.contractPDFId = documentId;
          if (!result.success) {
            this.errorMessage = this.$t("create_rent_contract.unknown_error")
          }
        }

        await this.$rambollfmapi.contracts.contract.createSigningOrder({ ...contract, id: result.contractID})

        this.$emit("close", { addContract: true }, closeAfterSaving)
      }
    },
    async getContractPDFData () {
      const landlord = this.allParties.find(party => party.id == this.landlordId)
      const landlordContactPerson = this.allParties.find(party => party.id == this.idLandlordContactPerson)

      const otherTenant = this.allParties.find(party => party.id == this.otherTenantId)
      const tenantContactPerson = this.allParties.find(party => party.id == this.idTenantContactPerson)
      
      await paymentServices.calculateSumsService.calculatePaymentSum(this.contractPayments, this.rentPaymentDomains, this.selectedUnits, this.$rambollfmapi)

      // Payments data
      const getPaymentData = (payments) => payments.map(async (payment) => {
        if (this.selectedUnits === undefined) return
        
        if (this.selectedUnits.length === 0) this.selectedUnits = this.existingUnits

        // Calculate sums
        const paymentDomain = this.rentPaymentDomains.filter(domain => domain.id === payment.paymentDomainId)[0]
        const agreedUnitAreas = this.selectedUnits
        .filter(unit => payment.wholeContractPayment || payment.targetUnits?.some(targetUnit => targetUnit === unit.id))
        .map(unit => unit.agreedArea ?? 0)
        const sums = payment.pcsCount > 0 
          ? await this.$rambollfmapi.rentpayments.payments.calculateSumsPcs(paymentDomain, payment.rent, payment.pcsCount, payment.squareBased) 
          : await this.$rambollfmapi.rentpayments.payments.calculateSums(paymentDomain, payment.rent, agreedUnitAreas, payment.squareBased)

        // Get index point numbers
        if (!payment.basicIndexPointNumber && payment.basicIndexYear && payment.basicIndexMonth) {
          const index = await this.$rambollfmapi.rentpayments.indexnumbers(payment.basicIndexYear, payment.basicIndexMonth)
          payment.basicIndexPointNumber = index[0]?.costOfLivingIndex
        }

        return {
          ...payment,
          reviewType: this.$t(this.definitionLabelById(payment.defRentReviewType)),
          paymentType: this.getPaymentDomain(payment),
          paymentVat: this.rentPaymentDomains.filter(domains => domains.id === payment.paymentDomainId)[0].vatPercent,
          rentReviewBasedOn: this.definitionLabelById(payment.defRentReviewBasedOn),
          processingMode: this.$t(this.definitionLabelById(payment.defProcessingMode)),
          invoicingBasis: this.$t(this.definitionLabelById(payment.defInvoicingBasis)),
          sums: sums
        }
      })

      const contractPayments = await Promise.all(getPaymentData(this.contractPayments))
      const separatePayments = await Promise.all(getPaymentData(this.separatePayments))

      const singlePayments = await Promise.all(this.singlePayments.map(async (payment) => {
        if (this.selectedUnits === undefined) return

        const paymentDomain = this.rentPaymentDomains.filter(domain => domain.id === payment.paymentDomainId)[0]
        const agreedUnitAreas = this.selectedUnits
          .filter(unit => payment.wholeContractPayment || payment.targetUnits?.some(targetUnit => targetUnit === unit.id))
          .map(unit => unit.agreedArea ?? 0)
          const sums = await this.$rambollfmapi.rentpayments.payments.calculateSums(paymentDomain, payment.rent, agreedUnitAreas, payment.squareBased)

        return {
          ...payment,
          paymentType: this.getPaymentDomain(payment),
          paymentVat: this.rentPaymentDomains.filter(domains => domains.id === payment.paymentDomainId)[0].vatPercent,
          sums: sums
        }
      }));

      // Save new contract as a draft before download to get the contractNumber
      if (!this.contractNumber) {
        await this.onDraftButtonClicked(false)
        await this.$emit("contractIdUpdated", this.draftContractId)
        await this.updateValuesFromExistingContract()
      }

      //If for some reason the address -field is empty, we try to get it again
      if (this.selectedUnits) {
        this.selectedUnits = await Promise.all(this.selectedUnits.map(async (unit) => {
          if (unit.id !== undefined && unit.id !== null && unit.address === "") {
            unit.address = await pdfDataHelper.getBuildingAddressForFutureBuilding(unit.buildingId, unit.siteId, this.currentDate, this.$rambollfmapi);
          }
          return unit;
        }));
      }

      return {            
        parties: {
          landlord,
          landlordContactPerson,
          tenant: this.tenant,
          tenantContactPerson,
          tenantIdentityNumber: this.tenantIdentityNumber,
          otherTenant,
          otherTenantIdentityNumber: this.otherTenantIdentityNumber,
        },
        selectedTargets: {
          targets: this.selectedUnits,
          additionalInfoUnits: this.additionalInfoUnits,
          additionalInfoParking: this.additionalInfoParking,
        },
        rentPeriod: {
          rentPeriodStartDate: this.rentPeriodStartDate,
          rentPeriodEndDate: this.rentContractEndDate,
          billingStartDate: this.billingStartDate,
          tenureStartDate: this.tenureStartDate,
          validityAdditionalInfo: this.validityAdditionalInfo,
          noticePeriodLandlord: this.noticePeriodLandlord,
          noticePeriodTenant: this.noticePeriodTenant,
          earliestNoticeDateTenant: this.earliestNoticeDateTenant,
          earliestNoticeDateLandlord: this.earliestNoticeDateLandlord,
          tenantExtensionPeriod: this.tenantExtensionPeriod,
          landlordExtensionPeriod: this.landlordExtensionPeriod,
          hasContractPeriodOfExtension: this.settings.hasContractPeriodOfExtension,
        },
        housingModification: {
          housingModification: this.additionalInfo.housingModification,
          housingModificationAgreedUpon: this.additionalInfo.housingModificationInfo,
        },
        billingInfo: {
          billingCycleLength: this.billingCycleLength,
          firstBillingMonth: this.firstBillingMonth,
        },
        payments: {
          contractPayments: this.selectedUnits ? contractPayments : undefined,
          separatePayments: this.selectedUnits ? separatePayments : undefined,
          singlePayments: this.selectedUnits ? singlePayments : undefined,
        },
        guarantees: this.guarantees,
        attachments: this.attachments.filter((a) => a.isForContract),
        contractConditions: this.contractConditions?.text ?? this.contractConditionsTemplates.filter((template) => template.id === this.contractConditions.templateId)?.[0]?.text
      }
    },
    async getAttachmentArrayBuffer (id) {
      this.isRetrievingFile = true
      const progress = { retrievalProgress: 0 }
      this.retrievalProgress = 0
      const self = this
      return await this.$rambollfmapi.documents
        .download(id, progress)
        .then(res => {
          self.isRetrievingFile = false
          self.retrievalProgress = 100
          return new Blob([res.data], { type: 'octet/stream' }).arrayBuffer()
        })
        .catch(() => {
          this.isRetrievingFile = false
        })
    },
    async onDownloadContract () {

      if (this.contractTemplate?.code.split('-')[0] === 'custom') {
        // Save new contract as a draft before download to get the contractNumber
        if (!this.contractNumber) {
          await this.onDraftButtonClicked(false)
          await this.$emit("contractIdUpdated", this.draftContractId)
          await this.updateValuesFromExistingContract()
        }
        
        const pdfData = await pdfDataHelper.getCustomData(
          { 
            allParties: this.allParties,
            landlordId: this.landlordId,
            idLandlordContactPerson: this.idLandlordContactPerson,
            otherTenantId: this.otherTenantId,
            idTenantContactPerson: this.idTenantContactPerson,
            tenant: this.tenant,
            tenantIdentityNumber: this.tenantIdentityNumber,
            otherTenantIdentityNumber: this.otherTenantIdentityNumber,
            contractPaymentsList: this.contractPayments, 
            separatePaymentsList: this.separatePayments, 
            singlePaymentsList: this.singlePayments, 
            rentPaymentDomains: this.rentPaymentDomains,
            selectedUnits: this.selectedUnits,
            contractNumber: this.contractNumber,
            customerLogo: this.customerLogo,
            additionalInfo: this.additionalInfo,
            tenantExtensionPeriod: this.tenantExtensionPeriod, 
            noticePeriodLandlord: this.noticePeriodLandlord, 
            noticePeriodTenant: this.noticePeriodTenant, 
            extensionNoticePeriod: this.extensionNoticePeriod, 
            rentPeriodStartDate: this.rentPeriodStartDate, 
            rentContractEndDate: this.rentContractEndDate, 
            billingStartDate: this.billingStartDate, 
            tenureStartDate: this.tenureStartDate, 
            validityAdditionalInfo: this.validityAdditionalInfo, 
            validityType: this.validityType, 
            earliestNoticeDateTenant: this.earliestNoticeDateTenant, 
            earliestNoticeDateLandlord: this.earliestNoticeDateLandlord, 
            billingCycleLength: this.billingCycleLength, 
            firstBillingMonth: this.firstBillingMonth, 
            additionalInfoUnits: this.additionalInfoUnits, 
            additionalInfoParking: this.additionalInfoParking, 
            additionalInfoPayments: this.additionalInfoPayments,  
            guaranteesList: this.guarantees, 
            attachments: this.attachments, 
            contractConditions: this.contractConditions, 
            contractConditionsTemplates: this.contractConditionsTemplates, 
            settings: this.settings, 
            getAttachmentArrayBuffer: this.getAttachmentArrayBuffer,
            existingUnits: this.existingUnits,
            definitionLabelById: this.definitionLabelById,
            currentDate: this.currentDate,
            rambollfmapi: this.$rambollfmapi,
        })

        const template = customPdfTemplate.getTemplate(pdfData)
        await services.PdfService.customPdfCreator(template, pdfData)

      } else {
        const pdfData = await this.getContractPDFData()
        const vatCount = pdfDataHelper.getVatCount(pdfData.payments)
        
        pdfData.billingInfo.isVat = vatCount > 0

        await services.PdfService.createContractPdf(
          this.contractNumber,
          this.additionalInfo.contracttype,
          this.additionalInfo.spaceuser,
          this.additionalInfo.spaceusage,
          this.customerLogo,
          pdfData.parties,
          pdfData.selectedTargets,
          pdfData.rentPeriod,
          pdfData.housingModification,
          pdfData.billingInfo,
          pdfData.payments,
          pdfData.guarantees,
          pdfData.contractConditions,
          pdfData.attachments,
          this.getAttachmentArrayBuffer
        )
      }
    },
    async saveContract ({contract, units, singlePayments, contractPayments, separatePayments, guarantees, contractConditions}) {
      let result;
      if (this.contractId) {
        result = await this.$rambollfmapi.contracts.contract.updateWithRows(
          contract,
          units,
          {
            singlePayments,
            contractPayments,
            separatePayments
          },
          guarantees,
          contractConditions,
        )
      } else {
        result = await this.$rambollfmapi.contracts.contract.createWithRows(
          contract,
          units,
          {
            singlePayments,
            contractPayments,
            separatePayments
          },
          guarantees,
          contractConditions,
        )
      }
      // API validates fields, if some field return false state, contractNumber and contractID comes with null value. 
      if (result.contractNumber === undefined || result.contractNumber === null) {
        result.contractNumber = contract.contractNumber
        result.contractID = contract.id
      }
      return result
    },
    async NonElectronicSigningContinue () {
      this.isSigningProcessLoading = true
      const result = await this.save(false)
      if (this.showValidationMessage) return
      if (result) {
        await this.$emit("contractIdUpdated", this.nonElectronicSigningContractID)
        this.CheckContractModalPagesAmount()
        this.currentPage++
        this.isDraft = false
        this.updateValuesFromExistingContract()
      }
      this.isSigningProcessLoading = false
    },
    async NonElectronicSigningReady () {
      this.isSigningProcessLoading = true
      await this.save(true)
      await this.$rambollfmapi.contracts.contract.SetContractAsSigned(this.contractId);
      await this.$rambollfmapi.contracts.contract.AddContractEvent(this.historyEvents.contractSigned, [this.contractId])
      this.isSigningProcessLoading = false
    },
    async NonDraftContractSave () {
      this.isSaving = true
      const contractIsReady = await this.$rambollfmapi.contracts.contract.CheckIfContractReady(this.contractId);
      await this.save(true)

      if (contractIsReady === true) {
        await this.$rambollfmapi.contracts.contract.SetContractAsSigned(this.contractId);
      }
      this.isSaving = false
    },
    async save (closeAfterSaving = true, sendButtonClicked = false) {
      this.formRequiredFieldValidationMessage()
      this.showValidationMessage = this.pageMessages.length > 0;
      if (this.showValidationMessage)
        return

      const wasInitiallyDraft = this.isDraft
      const { contract, units, singlePayments, contractPayments, separatePayments, guarantees, contractConditions, attachments } = this.getContractData()
      contract.isDraft = wasInitiallyDraft || !this.isElectronicSigning

      this.errorMessage = ""
      const result = await this.saveContract({contract, units, singlePayments, contractPayments, separatePayments, guarantees, contractConditions})
      this.nonElectronicSigningContractID = result.contractID
      this.sendContractId = result.contractID
      this.contractNumber = result.contractNumber

      if (!result.success) {
        if (result.invalidInput) {
          let invalidFields = []
          for(const validationResult in result.validationResults) {
            if (result.validationResults[validationResult] == false)
              invalidFields.push(this.$t("Validation" + validationResult))
          }
          this.errorMessage = this.$t("FixIncorrectOrLackingData") + ": " + invalidFields.join(', ')
        } else {
          this.errorMessage = this.$t("create_rent_contract.unknown_error")
        }
      } else {
        await this.AddAdditionalInfo(result.contractID)

        //Luodaan kantaan uusi dokumentti liitteistä ja lisätään uuteen LIITTEET tauluun tietokannassa tarvittavat FOREIGN KEY viittaukset sopimus ja document tauluihin
        if (attachments && this.contractId) {
          await this.updateAttachmentsForContract(attachments)
        }
        else {
          await this.createAttachmentsForContract(result.contractID, attachments)
        }

        // Upload contract if it was attached 
        const documentId = await this.uploadContractFile(result.contractID)
        if (documentId !== undefined) {
          contract.contractPDFId = documentId;
          if (!result.success) {
            this.errorMessage = this.$t("create_rent_contract.unknown_error")
          }
        }

        let blob
        if (this.contractTemplate?.code.split('-')[0] === 'custom') {
          const pdfData = await pdfDataHelper.getCustomData(
            { 
              allParties: this.allParties,
              landlordId: this.landlordId,
              idLandlordContactPerson: this.idLandlordContactPerson,
              otherTenantId: this.otherTenantId,
              idTenantContactPerson: this.idTenantContactPerson,
              tenant: this.tenant,
              tenantIdentityNumber: this.tenantIdentityNumber,
              otherTenantIdentityNumber: this.otherTenantIdentityNumber,
              contractPaymentsList: this.contractPayments, 
              separatePaymentsList: this.separatePayments, 
              singlePaymentsList: this.singlePayments, 
              rentPaymentDomains: this.rentPaymentDomains,
              selectedUnits: this.selectedUnits,
              contractNumber: this.contractNumber,
              customerLogo: this.customerLogo,
              additionalInfo: this.additionalInfo,
              tenantExtensionPeriod: this.tenantExtensionPeriod, 
              noticePeriodLandlord: this.noticePeriodLandlord, 
              noticePeriodTenant: this.noticePeriodTenant, 
              extensionNoticePeriod: this.extensionNoticePeriod, 
              rentPeriodStartDate: this.rentPeriodStartDate, 
              rentContractEndDate: this.rentContractEndDate, 
              billingStartDate: this.billingStartDate, 
              tenureStartDate: this.tenureStartDate, 
              validityAdditionalInfo: this.validityAdditionalInfo, 
              validityType: this.validityType, 
              earliestNoticeDateTenant: this.earliestNoticeDateTenant, 
              earliestNoticeDateLandlord: this.earliestNoticeDateLandlord, 
              billingCycleLength: this.billingCycleLength, 
              firstBillingMonth: this.firstBillingMonth, 
              additionalInfoUnits: this.additionalInfoUnits, 
              additionalInfoParking: this.additionalInfoParking, 
              additionalInfoPayments: this.additionalInfoPayments,  
              guaranteesList: this.guarantees, 
              attachments: this.attachments, 
              contractConditions: this.contractConditions, 
              contractConditionsTemplates: this.contractConditionsTemplates, 
              settings: this.settings, 
              getAttachmentArrayBuffer: this.getAttachmentArrayBuffer,
              existingUnits: this.existingUnits,
              definitionLabelById: this.definitionLabelById,
              currentDate: this.currentDate,
              rambollfmapi: this.$rambollfmapi,
            }
          )
          const template = customPdfTemplate.getTemplate(pdfData)
          
          blob = await services.PdfService.customPdfCreator(template, pdfData, true)
        } else {
          const pdfData = await this.getContractPDFData()
          blob = await services.PdfService.createContractPdf(
            result.contractNumber,
            this.additionalInfo.contracttype,
            this.additionalInfo.spaceuser,
            this.additionalInfo.spaceusage,
            this.customerLogo,
            pdfData.parties,
            pdfData.selectedTargets,
            pdfData.rentPeriod,
            pdfData.housingModification,
            pdfData.billingInfo,
            pdfData.payments,
            pdfData.guarantees,
            pdfData.contractConditions,
            pdfData.attachments,
            this.getAttachmentArrayBuffer,
            true
          )
        }

        if (wasInitiallyDraft || !this.isElectronicSigning) {
          if (this.isElectronicSigning) {
            var contractPdfDocument = await this.$rambollfmapi.documents.post({
              title: "contract",
              type: "contract pdf",
              description: "Contract"
            })

            await this.$rambollfmapi.documents.upload(contractPdfDocument.idDocument,
              {
                fileData: blob,
                query: {
                  description: "Contract"
                }
              },
              () => { }
            )

            contract.contractPDFId = contractPdfDocument.idDocument
          }

          if (blob.size <= 15000000 || !this.isElectronicSigning) { //Max filesize for Visma is 15MB
            contract.isDraft = false;
            const res = await this.$rambollfmapi.contracts.contract.createSigningOrder({ ...contract, id: result.contractID})
            
            //if creation of signing order fails, don't need to save contract as draft because it was already
            //saved as such in the beginning of this function
            if ( res.status !== 200 ) {
              this.errorMessage = this.$t("create_rent_contract.signing_order_failed")
              contract.isDraft = true;
              closeAfterSaving = false
              sendButtonClicked = false
            }
          } else {
              const size = blob.size/1000000 //bytes to MB
              this.errorMessage = this.$t("create_rent_contract.file_too_large") + size.toFixed(2) + "MB. " + this.$t("create_rent_contract.file_too_large2")
              contract.isDraft = true;
              closeAfterSaving = false
              sendButtonClicked = false
          }
        }
        if (sendButtonClicked === true) {
          await this.$rambollfmapi.contracts.contract.AddContractEvent(this.historyEvents.sendToSigning, [result.contractID])
        }
        this.$emit("close", { addContract: true}, closeAfterSaving )
        this.$store.dispatch('app/addAriaLive', this.$t('aria_live.contract_saved_window_closed'))
        return result.success
      }
    },
    async AddAdditionalInfo (contractID) {
      if (this.additionalInfo) {
        const info = {
          contractId: contractID,
          contractLayout: this.additionalInfo.contractlayout,
          contractType: this.additionalInfo.contracttype,
          contractTypeforcontract: this.additionalInfo.typeforcontract,
          contractHousingModification: this.additionalInfo.housingModification,
          contractHousingModificationInfo: this.additionalInfo.housingModificationInfo,
          contractSpaceuser: this.additionalInfo.spaceuser,
          contractSpaceusage: this.additionalInfo.spaceusage,
          contractClassification: this.additionalInfo.classification,
          contractClassificationtwo: this.additionalInfo.classification2,
          contractRentaldecision: this.additionalInfo.rentaldecision,
          contractCostshare: this.additionalInfo.costshare,
          contractIsSublease: this.additionalInfo.issublease
        }
        await this.$rambollfmapi.contracts.contract.addAdditionalInfo(info);
      }
    },
    async createAttachmentsForContract (contractId, attachments) {
      const newDocument = {}

      for (var index = 0; index < attachments.length; index++) {
        attachments[index].idContract = contractId
        newDocument.title = attachments[index].title
        newDocument.type = attachments[index].type
        newDocument.description = `Liite sopimukselle ID: ${contractId}`
        newDocument.isDeleted = 0

        var document = await this.$rambollfmapi.documents.post(newDocument)
        attachments[index].idDocument = document.idDocument

        await this.$rambollfmapi.documents.upload(
          document.idDocument,
            {
              fileData: attachments[index].file,
              query: {
                description: document.description
              }
            }, progress => {
              this.uploadProgressPercentage = progress.percentage
        })
      }

      await this.$rambollfmapi.contracts.contract.addAttachment(attachments)
    },
    async updateAttachmentsForContract (attachments) {
      for (var index = 0; index < attachments.length; index++) {
        attachments[index].idContract = this.contractId
        const newDocument = {
          title: attachments[index].title,
          type: attachments[index].type,
          description: 'Liite sopimukselle ID: ' + this.contractId,
          isDeleted: 0,
        }
        if (attachments[index].isNewAttachment == true) {
          attachments[index].filename = attachments[index].file.name + '.pdf'
          var document = await this.$rambollfmapi.documents.post(newDocument)
          attachments[index].idDocument = document.idDocument

          await this.$rambollfmapi.documents.upload(
          document.idDocument,
            {
              fileData: attachments[index].file,
              query: {
                description: document.description
              }
            }, progress => {
              this.uploadProgressPercentage = progress.percentage
        })
        }
      }
      await this.$rambollfmapi.contracts.contract.updateAttachment(this.contractId, attachments)
    },
    async uploadContractFile (contractID) {
      if (this.signedContract) {
        this.signedContractFileData.title = contractID + "_allekirjoitettu"
        this.signedContractFileData.type = "Allekirjoitettu sopimus"
        this.signedContractFileData.description = `Allekirjoitettu sopimus sopimusnumerolle ${contractID}`

        const document = await this.$rambollfmapi.documents.post(this.signedContractFileData)
        this.contractPDFId = document.idDocument
        this.signedContractFileData.file = this.signedContract

        await this.$rambollfmapi.documents.upload(document.idDocument, {
          fileData: this.signedContract,
          query: {
            description: document.description
          }},
          progress => {
            this.uploadProgressPercentage = progress.percentage
          })
        return document.idDocument
      }
    },
    isNullOrUndefined (value) {
      return value === null || value === undefined
    },
    addPageMessage (page, message) {
      if (typeof this.pageMessages[page] === 'undefined')
        this.pageMessages[page] = [];
      this.pageMessages[page].push(message);
    },
    addContractOrSeparatePaymentRequiredFieldMsgs (payments, reviewTypesContainingReviewStartDate, page) {
      let targetUnitMsg = false
      let paymentStartDataMsg = false
      let defPaymentDomainMsg = false
      let invoicingBasisMsg = false
      let processingModeMsg = false
      let pcsCountMsg = false
      let netSumRentMsg = false
      let reviewStartDateMsg = false
      let basicIndexYearMsg = false
      let basicIndexMonthMsg = false
      let reviewBasedOnMsg = false
      let reviewPeriodStartMsg = false
      let turnoverPeriodMsg = false
      let rentPercentTurnoverMsg = false
      let indexReviewMustHaveOneCheckMonthMsg = false
      let costBasedReviewMustHaveOneReviewMonthMsg = false
      let indexCheckMonthMsg = false
      let indexMonthMsg = false
      for(let paymentIndex in payments) {
        let payment = payments[paymentIndex]
        if (!targetUnitMsg 
          && !payment.wholeContractPayment 
          && ((this.isNullOrUndefined(payment.targetUnits) || payment.targetUnits.length === 0) 
            && (this.isNullOrUndefined(payment.targetStructures) || payment.targetStructures.length === 0)
            && (this.isNullOrUndefined(payment.targetParkingSpaces) || payment.targetParkingSpaces.length === 0))
          ) {
          this.addPageMessage(page, this.$t('At least one unit should be selected from') + 
          this.$t('create_rent_contract.units') +
          this.$t("if payment is not for the whole contract"));
          targetUnitMsg = true
        }
        if (this.isOutRent && !paymentStartDataMsg && this.isNullOrUndefined(payment.startDate)) {
          this.addPageMessage(page, this.$t('create_rent_contract.payment_start_data'));
          paymentStartDataMsg = true
        }
        if (!defPaymentDomainMsg && this.isNullOrUndefined(payment.paymentDomainId)) {
          this.addPageMessage(page, this.$t('create_rent_contract.contract_payment_domain'));
          defPaymentDomainMsg = true
        }
        if (this.isOutRent && !invoicingBasisMsg && this.isNullOrUndefined(payment.defInvoicingBasis)) {
          this.addPageMessage(page, this.$t('create_rent_contract.payment_invoicing_basis'));
          invoicingBasisMsg = true
        }
        if (this.isOutRent && !processingModeMsg && 
          payment.selectedInvoicingBasis === this.definitionByLabel('InvoicingBasisPcs').id && 
          this.isNullOrUndefined(payment.defProcessingMode)) {
          this.addPageMessage(page, this.$t('create_rent_contract.payment_processing_mode'));
          processingModeMsg = true
        }
        if (this.isOutRent && !pcsCountMsg && payment.selectedInvoicingBasis === this.definitionByLabel('InvoicingBasisPcs').id && 
          this.isNullOrUndefined(payment.pcsCount)) {
          this.addPageMessage(page, this.$t('create_rent_contract.payment_pcs'));
          pcsCountMsg = true
        }
        if (!netSumRentMsg && this.isNullOrUndefined(payment.netSum) &&
            this.isNullOrUndefined(payment.rent)) {
          this.addPageMessage(page, this.$t('Either field') + this.$t('create_rent_contract.net_sum') + 
          this.$t('or field') + this.$t('create_rent_contract.payment_square_rent_tax_free') +
          this.$t('must be given'));
          netSumRentMsg = true
        }
        if (this.isOutRent && !reviewStartDateMsg && reviewTypesContainingReviewStartDate.includes(payment.defRentReviewType) &&
          this.isNullOrUndefined(payment.rentReviewStartDate)) {
          this.addPageMessage(page, this.$t('create_rent_contract.rent_review_startdate'))
          reviewStartDateMsg = true
        }
        if (this.isOutRent && payment.defRentReviewType === this.definitionByLabel('PaymentRentReviewIndex').id) {
          if (!basicIndexYearMsg && this.isNullOrUndefined(payment.basicIndexYear)) {
            this.addPageMessage(page, this.$t('create_rent_contract.base_index_year'))
            basicIndexYearMsg = true
          }
          if (!basicIndexMonthMsg && this.isNullOrUndefined(payment.basicIndexMonth)) {
            this.addPageMessage(page, this.$t('create_rent_contract.base_index_month'))
            basicIndexMonthMsg = true
          }
          if (!reviewBasedOnMsg && this.isNullOrUndefined(payment.defRentReviewBasedOn)) {
            this.addPageMessage(page, this.$t('create_rent_contract.review_based_on'))
            reviewBasedOnMsg = true
          }
          if (!indexReviewMustHaveOneCheckMonthMsg && (this.isNullOrUndefined(payment.reviewMonths) || 
             payment.reviewMonths.length == 0 || 
             this.isNullOrUndefined(payment.indexMonths) || 
             payment.indexMonths.length == 0)) {
            this.addPageMessage(page, this.$t('create_rent_contract.index_review_should_have_at_least_one_review_and_index_month'))
            indexReviewMustHaveOneCheckMonthMsg = true
          }

          for(let i=0; i< payment.reviewMonths.length; i++) {
            if (!indexCheckMonthMsg && this.isNullOrUndefined(payment.reviewMonths[i])) {
              this.addPageMessage(page, this.$t('create_rent_contract.index_review_months'))
              indexCheckMonthMsg = true
              break
            }
          }
          for(let i=0; i< payment.indexMonths.length; i++) {
            if (!indexMonthMsg && this.isNullOrUndefined(payment.indexMonths[i])) {
              this.addPageMessage(page, this.$t('create_rent_contract.index_month'))
              indexMonthMsg = true
              break
            }
          }
        } else if (this.isOutRent && payment.defRentReviewType === this.definitionByLabel('PaymentRentReviewCostBased').id) {
          if (!costBasedReviewMustHaveOneReviewMonthMsg && (this.isNullOrUndefined(payment.reviewMonths) || 
             payment.reviewMonths.length == 0)) {
            this.addPageMessage(page, this.$t('create_rent_contract.cost_based_review_should_have_at_least_one_review_month'))
            costBasedReviewMustHaveOneReviewMonthMsg = true
          }
          for(let i=0; i< payment.reviewMonths.length; i++) {
            if (!indexCheckMonthMsg && this.isNullOrUndefined(payment.reviewMonths[i])) {
              this.addPageMessage(page, this.$t('create_rent_contract.index_review_months'))
              indexCheckMonthMsg = true
              break
            }
          }
        } else if (this.isOutRent && payment.defRentReviewType === this.definitionByLabel('PaymentRentReviewRevenueBased')) {
          if (!reviewPeriodStartMsg && this.isNullOrUndefined(payment.reviewPeriodStart)) {
            this.addPageMessage(page, this.$t('create_rent_contract.review_period_startdate'))
            reviewPeriodStartMsg = true
          }
          if (!turnoverPeriodMsg && this.isNullOrUndefined(payment.turnoverPeriod)) {
            this.addPageMessage(page, this.$t('create_rent_contract.turnover_period'))
            turnoverPeriodMsg = true
          }
          if (!rentPercentTurnoverMsg && this.isNullOrUndefined(payment.rentPercentTurnover)) {
            this.addPageMessage(page, this.$t('create_rent_contract.rent_percent_turnover'))
            rentPercentTurnoverMsg = true
          }
        }
      }
    },
    formRequiredFieldValidationMessage () {
      this.requiredFieldValidationMessages = [];
      this.pageMessages = [];

      if (this.isElectronicSigning && (this.isNullOrUndefined(this.landlordContactPersonEmail) || this.landlordContactPersonEmail.length < 1))
        this.addPageMessage(1, this.$t('create_rent_contract.landlord_contact_person_no_email'));
      if (this.isNullOrUndefined(this.landlordId))
        this.addPageMessage(1, this.$t('create_rent_contract.landlord'));
      if (this.isNullOrUndefined(this.idLandlordContactPerson) && this.outRent)
        this.addPageMessage(1, this.$t('create_rent_contract.contact_person'));
      if (this.isNullOrUndefined(this.tenantId))
        this.addPageMessage(1, this.$t('create_rent_contract.tenant'));
      if (this.tenantType == "company" && this.outRent && this.isNullOrUndefined(this.idTenantContactPerson))
        this.addPageMessage(1, this.$t('create_rent_contract.tenant_contact_person'));
      if (this.tenantType == "person" && (this.isNullOrUndefined(this.tenantIdentityNumber) ||
        this.tenantIdentityNumber == ""))
        this.addPageMessage(1, this.$t('create_rent_contract.tenant_identity_number'));
      if (this.tenantType == "person" && this.otherTenantId != undefined && this.otherTenantId != null
        && (this.otherTenantIdentityNumber == "" || this.otherTenantIdentityNumber == undefined ||
        this.otherTenantIdentityNumber == null))
        this.addPageMessage(1, this.$t('create_rent_contract.other_tenant_identity_number'));

      // Tab 2 begins
      if (this.isOutRent) {

      if (this.isNullOrUndefined(this.additionalInfo) || this.isNullOrUndefined(this.additionalInfo.contracttype) || 
        this.additionalInfo.contracttype.trim() == "")
        this.addPageMessage(2, this.$t('ContractType'));
      }

      // Tab 3 begins
      if (this.isNullOrUndefined(this.rentPeriodStartDate) || this.rentPeriodStartDate.trim() == "") 
        this.addPageMessage(3, this.$t('create_rent_contract.rent_period_start'));

      if (this.validityType == "validity_fixed") {
        if (this.isNullOrUndefined(this.rentContractEndDate) || this.rentContractEndDate.trim() == "")
          this.addPageMessage(3, this.$t('create_rent_contract.rent_contract_end_day'));
      } 
      else if (this.validityType == "validity_permanent") {
        if (this.isOutRent) {
        if (this.isNullOrUndefined(this.noticePeriodTenant))
          this.addPageMessage(3, this.$t('create_rent_contract.notice_period_tenant'));
        if (this.isNullOrUndefined(this.noticePeriodLandlord))
          this.addPageMessage(3, this.$t('create_rent_contract.notice_period_landlord'));
        }
      }

      if (this.isNullOrUndefined(this.billingStartDate) ||
          this.billingStartDate.trim() == "")
        this.addPageMessage(3, this.$t('create_rent_contract.billing_start_date'));
      if (this.isNullOrUndefined(this.tenureStartDate) || this.tenureStartDate.trim() == "")
        this.addPageMessage(3, this.$t('create_rent_contract.tenure_start_date'));

      // Tab 4 begins
      if (this.isNullOrUndefined(this.defBillingAddress))
        this.addPageMessage(4, this.$t('create_rent_contract.billing_address'));
      if (this.isNullOrUndefined(this.defRentIncreaseLettersAddress))
        this.addPageMessage(4, this.$t('create_rent_contract.increase_letter_address'));
      if (this.isNullOrUndefined(this.billingCycleLength))
        this.addPageMessage(4, this.$t('create_rent_contract.billing_cycle_length'));
      if (this.billingCycleLength != 1 && this.isNullOrUndefined(this.firstBillingMonth))
        this.addPageMessage(4, this.$t('create_rent_contract.first_billing_month'));
      if (this.isOutRent && this.automaticBillingAllowed && this.isNullOrUndefined(this.automaticBillingReasoning))
        this.addPageMessage(4, this.$t('create_rent_contract.automatic_billing_reasoning'));

      // Tab 5 begins
      if ((this.selectedUnits === undefined || this.selectedUnits === null || this.selectedUnits.length === 0) && (this.canConnectToPortfolio == false || (this.canConnectToPortfolio == true && this.isConnectedToPortfolio === false)) ) 
        this.addPageMessage(5, this.$t('At least one unit should be selected'))

      // Rent payments values
      let reviewTypesContainingReviewStartDate = [
        this.definitionByLabel('PaymentRentReviewCostBased').id, 
        this.definitionByLabel('PaymentRentReviewIndex').id, 
        this.definitionByLabel('PaymentRentReviewOther').id
      ];
      
      // Contract payments
     this.addContractOrSeparatePaymentRequiredFieldMsgs (this.contractPayments, reviewTypesContainingReviewStartDate, 6);

      // Separate payments
      this.addContractOrSeparatePaymentRequiredFieldMsgs (this.separatePayments, reviewTypesContainingReviewStartDate, 7);

      // Single payments
      let targetUnitMsg = false
      let paymentDateMsg = false
      let paymentDomainMsg = false
      let netSumMsg = false
      for(let singlePaymentIndex in this.singlePayments) {
        let singlePayment = this.singlePayments[singlePaymentIndex]
        if (!targetUnitMsg && !singlePayment.wholeContractPayment && 
          ((this.isNullOrUndefined(singlePayment.targetUnits) || singlePayment.targetUnits.length === 0) &&
           (this.isNullOrUndefined(singlePayment.targetStructures) || singlePayment.targetStructures.length === 0) &&
           (this.isNullOrUndefined(singlePayment.targetParkingSpaces) || singlePayment.targetParkingSpaces.length === 0))) {
          this.addPageMessage(8, this.$t('At least one unit should be selected from') + 
          this.$t('create_rent_contract.units') +
          this.$t("if payment is not for the whole contract"));
          targetUnitMsg = true
        }
        if (this.isOutRent && !paymentDateMsg && this.isNullOrUndefined(singlePayment.paymentDate)) {
          this.addPageMessage(8, this.$t('create_rent_contract.single_payment_date'))
          paymentDateMsg = true
        }
        if (!paymentDomainMsg && this.isNullOrUndefined(singlePayment.paymentDomainId)) {
          this.addPageMessage(8, this.$t('create_rent_contract.single_payment_basis_domain'))
          paymentDomainMsg = true
        }
        if (this.isOutRent && !netSumMsg && this.isNullOrUndefined(singlePayment.netSum)) {
          this.addPageMessage(8, this.$t('create_rent_contract.net_sum'))
          netSumMsg = true
        }
      }

      let quaranteeSumMsg = false
      let quaranteeTypeMsg = false
      for(let quaranteeIndex in this.guarantees) {
        let quarantee = this.guarantees[quaranteeIndex]
        if (!quaranteeTypeMsg && (this.isNullOrUndefined(quarantee.guaranteeType) ||
          quarantee.guaranteeType.trim() === "")) {
          this.addPageMessage(9, this.$t('GuaranteeType'))
          quaranteeTypeMsg = true
        }
        if (!quaranteeSumMsg && this.isNullOrUndefined(quarantee.guaranteeSum)) {
          this.addPageMessage(9, this.$t('GuaranteeSum'))
          quaranteeSumMsg = true
        }
      }

      if (this.isOutRent) {
      if (this.isNullOrUndefined(this.contractConditions.text) || 
        this.contractConditions.text.trim() == "")
      {
        this.addPageMessage(10, this.$t('Condition text'))
      }
      }

      let attachmentFileMsg = false
      for(let attachmentIndex in this.attachments) {
        let attachment = this.attachments[attachmentIndex]
        if (!attachmentFileMsg && this.isNullOrUndefined(attachment.file)) {
          this.addPageMessage(11, this.$t('File'))
          attachmentFileMsg = true
        }
      }

      // Tab 11
      if (this.isSignatoriesRequired && (!this.landlordSignatory || this.tenantSignatories.length === 0))
        this.addPageMessage(11, this.$t('SignatoryErrorMessage'))
      if (this.isElectronicSigning && (!this.landlordHasEmail || !this.tenantsHasEmails))
        this.addPageMessage(11, this.$t('create_rent_contract.error_no_email'))

      let pageNumbersWithErrors = [];
      // Gather messages
      this.pageMessages.forEach((onePageMessages, page) => {
        if (page >= 6 && page <= 8)
          pageNumbersWithErrors.push(7)
        if (page == 6) 
          this.requiredFieldValidationMessages.push(
            this.$t('Page {n}', {n: 7}) + " " + this.$t('Contract payments') 
            + ": " + onePageMessages.join(", "));
        else if (page === 7)
          this.requiredFieldValidationMessages.push(
            this.$t('Page {n}', {n: 7}) + " " + this.$t('Separate payments') 
            + ": " + onePageMessages.join(", "));
        else if (page === 8)
          this.requiredFieldValidationMessages.push(
            this.$t('Page {n}', {n: 7}) + " " + this.$t('Single payments') 
            + ": " + onePageMessages.join(", "));
        else {
          let shownPage = page
          if (page >= 9 && page <= 10)
            shownPage = page - 1
          this.requiredFieldValidationMessages.push(this.$t('Page {n}', {n: shownPage}) 
            + ": " + onePageMessages.join(", "));
          pageNumbersWithErrors.push(shownPage)
        }
      });
      for (let iPage = 0; iPage < this.pages; iPage++) {
        if (pageNumbersWithErrors.includes(iPage + 1))
          this.pages[iPage].state = "error"
        else
          this.pages[iPage].state = "success"
      }
    },
    async onBasicInfoTabUpdated (data) {
      this.landlordId = data.landlordId
      this.tenantId = data.tenantId
      this.otherTenantId = data.otherTenantId
      this.reviewFreq = data.reviewFreq
      this.rentReviewMonth = data.rentReviewMonth
      this.rentReviewType = data.rentReviewType
      this.baseIndex = data.baseIndex
      this.indexReviewMonths = data.indexReviewMonths
      this.firstPossibleReviewDate = data.firstPossibleReviewDate
      this.rentDecreaseAccordingIndex = data.rentDecreaseAccordingIndex
      this.additionalContractTerms = data.additionalContractTerms
      this.firstTabTenantTypeChange = this.tenantType != data.tenantType
      this.tenantType = data.tenantType
      this.isOutRent = data.isOutRent
      this.idLandlordContactPerson = data.idLandlordContactPerson
      this.idTenantContactPerson = data.idTenantContactPerson
      this.tenantIdentityNumber = data.tenantIdentityNumber
      this.otherTenantIdentityNumber = data.otherTenantIdentityNumber
      if (this.firstTabTenantTypeChange) {
        this.clearTenantIds()
        this.tenantId = undefined
        this.idTenantContactPerson = undefined
        this.tenantIdentityNumber = undefined
        this.otherTenantIdentityNumber = undefined
      }
      this.tenantClientele = data.tenantClientele
      await this.determineInternalityOfContract()
      this.landlordContactPersonEmail = data.landlordContactPersonEmail
      this.formRequiredFieldValidationMessage()
      this.addTenantId(this.tenantId);
      this.addTenantId(this.otherTenantId);
    },
    async determineInternalityOfContract () {
      if (!this.isOutRent) {
        this.isInternalContract = false
      } else {
        if (typeof this.allParties !== 'undefined'){
          await this.getCustomerGroup()
          if (this.tenantClientele === 'Liikelaitos' || 
            this.tenantClientele === 'Toimiala' || 
            this.tenantClientele === 'Virasto') {
            this.isInternalContract = true
          } else { 
            this.isInternalContract = false
          }
        }
      }
    },
    onRentPeriodTabUpdated (data) {
      this.rentPeriodStartDate = data.rentPeriodStartDate
      this.validityType = data.validityType
      this.rentTimeYears = data.rentTimeYears
      this.rentContractEndDate = data.rentContractEndDate
      this.noticePeriodTenant = data.noticePeriodTenant
      this.noticePeriodLandlord = data.noticePeriodLandlord
      this.earliestNoticeDateTenant = data.earliestNoticeDateTenant
      this.earliestNoticeDateLandlord = data.earliestNoticeDateLandlord
      this.tenantExtensionPeriod = data.tenantExtensionPeriod
      this.landlordExtensionPeriod = data.landlordExtensionPeriod
      this.extensionNoticePeriod = data.extensionNoticePeriod
      this.validityAdditionalInfo = data.validityAdditionalInfo
      this.billingStartDate = data.billingStartDate
      this.tenureStartDate = data.tenureStartDate
      this.additionalExtensionTerms = data.additionalExtensionTerms
      this.updateUnitValues()
      this.formRequiredFieldValidationMessage()
    },
    onBillingTabUpdated (data) {
      this.defBillingAddress = data.defBillingAddress,
      this.defRentIncreaseLettersAddress = data.defRentIncreaseLettersAddress,
      this.billingCycleLength = data.billingCycleLength,
      this.firstBillingMonth = data.firstBillingMonth,
      this.isRetrospectiveBilling = data.isRetrospectiveBilling,
      this.ovtCode = data.ovtCode,
      this.payerReference = data.payerReference,
      this.contractSpecificName = data.contractSpecificName,
      this.contractSpecificBusinessId = data.contractSpecificBusinessId,
      this.contractSpecificAddress = data.contractSpecificAddress,
      this.contractSpecificPostalCode = data.contractSpecificPostalCode,
      this.contractSpecificPostalCity = data.contractSpecificPostalCity,
      this.automaticBillingAllowed = data.automaticBillingAllowed,
      this.automaticBillingReasoning = data.automaticBillingReasoning,
      this.allowAutomaticBillingData = data.allowAutomaticBillingData,
      this.currencyId = data.currencyId,
      this.updateUnitValues()
      this.formRequiredFieldValidationMessage()
    },
    onUnitSelectionTabUpdated (selectedUnits) {

      if (selectedUnits.portfolio != undefined) {
        this.selectedPortfolio = selectedUnits.portfolio
      }

      this.selectedUnits = [
        ...this.selectedUnits ? this.selectedUnits?.filter(u => this.isTargetSelected(u, selectedUnits.units)) : [],
        ...selectedUnits?.units?.filter(u => !this.isTargetSelected(u, this.selectedUnits))
      ]

      this.selectedUnits.forEach(u => {
        if (u.unitId) {
          u.id = u.unitId
        }
        if (u.structureId) {
          u.id = u.structureId
        }
        if (u.parkingSpaceId) {     
          u.id = u.parkingSpaceId

          const foundParkingSpace = selectedUnits.units.find(p => p.parkingSpaceId === u.parkingSpaceId)
          u.rentalType = foundParkingSpace.rentalType
          u.rentalTypeName = foundParkingSpace.rentalTypeName
        }
      }) 

      this.selectedBuildings = selectedUnits.selectedBuildings
      this.updateUnitValues()
      this.formRequiredFieldValidationMessage()
    },
    isTargetSelected (obj, targets) {
      if (targets) {
        return targets.some(t => t.unitId === obj.unitId && t.structureId === obj.structureId && t.parkingSpaceId === obj.parkingSpaceId)
      }
      return false
    },
    onUnitDetailsTabUpdated (selectedUnits) {
      this.selectedUnits = selectedUnits
    },
    onUnitDetailsAdditionalInfoUpdated (additionalInfoUnits, additionalInfoParking) {
      this.additionalInfoUnits = additionalInfoUnits
      this.additionalInfoParking = additionalInfoParking
    },
    onChangedContractAttachedType (payload) {
      this.isConnectedToPortfolio = payload
    },
    onPortfolioDetailsUpdated (portfolio) {
      if (portfolio.length>0) {
        this.IdPortfolio = portfolio[0].Id
        this.portfolioStartdate = portfolio[0].Startdate
        this.portfolioEnddate= portfolio[0].Enddate
        this.portfolioAgreedArea = portfolio[0].AgreedArea
      }
      else {
        this.IdPortfolio = null
        this.portfolioStartdate = null
        this.portfolioEnddate= null
        this.portfolioAgreedArea = null
      }
    },
    onSigningTabUpdated (data) {
      this.landlordSignatory = data.landlordSignatory,
      this.tenantSignatories = data.tenantSignatories,
      this.signedContract = data.signedContract,
      this.signedPlace = data.signedPlace,
      this.signedDate = data.signedDate,
      this.isElectronicSigning = data.isElectronicSigning,
      this.tenantsHasEmails = data.tenantsHasEmails,
      this.landlordHasEmail = data.landlordHasEmail,
      this.firstTabTenantTypeChange = false
      this.formRequiredFieldValidationMessage()
    },
    async onNonElectronicSigningTabUpdated (data) {
      if ( data.signedPlace && data.signedDate && data.signedContract) {
        this.setNonElectronicContractReadyToStart(true)
      } 
      else {
        this.setNonElectronicContractReadyToStart(false)
      }
      this.signedContract = data.signedContract,
      this.signedPlace = data.signedPlace,
      this.signedDate = data.signedDate
      this.uploadContractFile(this.contractId)
    },
    setNonElectronicContractReadyToStart (state) {
      this.nonElectronicContractReadyToStart = state
    },
    onAttachmentsUpdated (attachments) {
      attachments.forEach((att, index) => {
        att.orderIndex = index
      });
      this.attachments = attachments
      this.formRequiredFieldValidationMessage()
    },
    updateUnitValues () {
      if (this.selectedUnits === undefined || this.selectedUnits.length === 0) {
        return;
      }
      this.selectedUnits = this.selectedUnits.map(unit => {

        if (!unit.startDate || new Date(unit.startDate) < new Date(this.rentPeriodStartDate)) {
          unit.startDate = this.rentPeriodStartDate
        }

        if (!unit.area) {
          unit.area = 0
        }

        if (!unit.agreedArea || unit.agreedArea < 0) {
          unit.agreedArea = unit.area
        }
        
        if (!unit.amount) {
          unit.amount = 0
        }
        
        if (!unit.agreedAmount || unit.agreedAmount < 1) {
          unit.agreedAmount = unit.amount
        }

        if (!unit.rent) {
          unit.rent = 0
        }

        if (this.isNewContract && !unit.endDate) {
          unit.endDate = this.rentContractEndDate
        }

        if (this.validityType == 'validity_permanent' && this.rentContractEndDate) {
          if(unit.endDate != undefined && unit.endDate > this.rentContractEndDate){
              unit.endDate = this.rentContractEndDate
          }
          if(unit.endDate == undefined){
            unit.endDate = this.rentContractEndDate
          }
        }
        
        if (this.validityType == 'validity_fixed' && this.rentContractEndDate <= unit.endDate) {
          if(unit.endDate > this.rentContractEndDate){
            unit.endDate = this.rentContractEndDate
          }
        }

        if (!unit.structureId) {
          unit.structureId = null
        }

        if (!unit.unitId) {
          unit.unitId = null
        }

         if (!unit.parkingSpaceId) {
          unit.parkingSpaceId = null
        }

        return unit;
      })
    },
    onContractConditionsUpdated (contractConditions) {
      this.contractConditions.id = contractConditions.id
      this.contractConditions.text = contractConditions.text
      this.contractConditions.templateId = contractConditions.templateId
      this.formRequiredFieldValidationMessage()
    },
    onIsContractConditionsTabValidChanged (isPageValid) {
      this.pages[5].state = isPageValid ? 'success' : 'error'
      this.formRequiredFieldValidationMessage()
    },
    onIsSendContractForSigningTabValidChanged (isPageValid) {
      this.pages[10].state = isPageValid ? 'success' : 'error'
      this.formRequiredFieldValidationMessage()
    },
    async cancelContract () {
      if (confirm(this.$t('create_rent_contract.cancel_signature_confirmation'))) {
        await this.$rambollfmapi.contracts.contract.cancelContract(this.contractId)
        this.$emit("close")
      }
    },
    async getCustomerGroup () {
      if (typeof this.allParties !== 'undefined'){
        const parties = this.allParties
        const filteredParty = await new Promise(resolve => {
          resolve(parties.filter(p => p.id === this.tenantId));
        })
        this.tenantClientele = filteredParty[0]?.clientele
      }
    },
      
    async getAllRentPaymentDomains () {
      const result = await this.$rambollfmapi.contracts.contract.getRentPaymentDomains()
      result.forEach(r => { r.name = this.$t(r.name) })
      result
        .sort((a, b) => b.vatPercent - a.vatPercent)
        .sort((a, b) => a.name > b.name ? 1 : -1)
      if (this.isOutRent) {
        await this.getCustomerGroup()
        const recommendedPaymentDomains = result.filter(pd => pd.clientGroups.some(cg => {
          pd.order = cg.paymentDomainOrder
          return this.definitionLabelById(cg.defClientGroup) === this.tenantClientele
        })).sort((a, b) => a.order > b.order ? 1 : -1)

        const sortedPaymentDomains = [
          { header: this.$t('RecommendedPaymentDomains') },
          ...recommendedPaymentDomains.map(r => ({ ...r, group: this.$t('Recommended') })),
          { divider: true },
          { header: this.$t('OtherPaymentDomains') },
          ...result,
        ]
        this.rentPaymentDomains = recommendedPaymentDomains.length > 0 ? sortedPaymentDomains : result
      } else {
        this.rentPaymentDomains = result
      }
    },
    getPaymentDomain (payment){
      if (typeof this.rentPaymentDomains !== 'undefined'){
        let paymentDomain = this.rentPaymentDomains.filter(domains => domains.id === payment.paymentDomainId)
        let name = paymentDomain[0].name
        return name
      } 
    },
    CheckContractModalPagesAmount () {
      if (!this.isUpdatingAllowed) {
        this.pages.length = 9
      } else {
        if (!this.isElectronicSigning && !this.isDraft) {
          if (this.pages.length == 11) {
            this.pages.push({ id: 11, state: 'success'});
          }
        }
        else {
          if (this.pages.length == 12) {
            this.pages.pop()
          }
        }
      }
    },
    async getParties () { 
      let parties = await this.$rambollfmapi.parties.list({ query: { visibility_status: true } })
      this.allParties = parties
    },
    async onSendButtonClicked () {
      this.isSigningProcessLoading = true
      await this.save(true, true)
      this.isSigningProcessLoading= false
    },
    getUnitStructureOrParkingSpaceName (row) {
      if (row.structureId !== null) {
        return `${row.structureName}, ${this.definitionLabelById(row.usage)},  ${Math.round(row.contractArea * 2) / 2} m²`
      }
      if (row.parkingSpaceId !== null) {
        return `${row.parkingSpaceName}, ${this.definitionLabelById(row.usage)}, ${Math.round(row.contractArea * 2) / 2} m²`
      }
      return `${row.unitName}, ${this.definitionLabelById(row.usage) || "-"}, ${Math.round(row.contractArea * 2) / 2} m²`
    },
    getBuildingAddress (buildingId) {
      if (this.buildings) {
        const filteredBuildings = this.buildings.filter(building => building.id_building === buildingId)
        const addressArray = new Set(filteredBuildings
          .filter(b => !!b.address)
          .map(b => `${b.address}, ${b.zip_code} ${b.municipality_name.toUpperCase()}`)
        )
        return Array.from(addressArray).join("\n")
      }
      return ""
    },
    contractTerminationPdf (params){

      const { contractNumber, landlordId, idLandlordContactPerson, tenantId, otherTenantId } = this.contractToUpdate

      const { type } = params

      let previewText = ""

      if(type === "preview"){
        previewText = "luonnos"
      }

      const documentName = `irtisanomisilmoitus${contractNumber}${previewText}.pdf`

      const terminationPdfData = {
        ...this.contractToUpdate,
        ...params,
        landlord: this.allParties.find((party) => party.id === landlordId),
        landlordContactPerson: this.allParties.find((party) => party.id === idLandlordContactPerson),
        tenant: this.allParties.find((party) => party.id === tenantId),
        otherTenant: this.allParties.find(party => party.id == otherTenantId),
        selectedUnits: this.selectedUnits,
        contractSpaceusage: this.additionalInfo.spaceusage,
      }

      const blob = services.PdfService.createContractTerminationPdf(documentName, terminationPdfData)
      
      const file = new File([blob], documentName)

      if(type === "final"){

        const newAttachment = {
          file,
          title: documentName,
          type: "muu",
          typeAttachment: "muu",
          isForContract: false,
          isNewAttachment: true
        }

        this.createAttachmentsForContract(this.contractId, [newAttachment])
      }

    },
  }
}
</script>

<style scoped>
.btn {
  text-transform: unset !important;
}
.draft-button {
  background: #ffffff !important;
  color: rgb(105, 105, 105) !important;
}
.current-icon {
  color: var(--c-color-accent) !important;
}
.success-icon {
  color: var(--r-color-mountain-40) !important;
}
.error-icon {
  color: #ff5252 !important;
}
.v-input--is-disabled {
  background: #f5f5f5 !important;
  border: #8b8b8b 1px solid !important;
}
.v-input--is-disabled >>> input {
  color: rgba(0, 0, 0, 0.6) !important;
}
.v-input--is-disabled >>> .v-input__slot::before {
  border-style: none !important;
}
.save-error {
  color: #ff5252;
}
</style>