<template>
  <div>
    <div
      v-if="loading"
      class="d-flex justify-center align-center pa-8"
    >
      <v-progress-circular
        size="64"
        indeterminate
        color="primary"
      />
    </div>
    <div
      v-else
      class="buildingRatingsChart"
    >
      <div class="buildingRatingsChart__y-label">
        {{ $t('Performance level') }}
      </div>
      <div class="buildingRatingsChart__y-values">
        <span>5</span>
        <span>3</span>
        <span>1</span>
      </div>
      <div class="buildingRatingsChart__bg">
        <span>{{ $t('salkutus_kehitettavat') }}</span>
        <span>{{ $t('salkutus_pidettavat') }}</span>
        <span>{{ $t('salkutus_realisoitavat') }}</span>
        <span>{{ $t('salkutus_selvitettavat') }}</span>

        <template v-for="item in items">
          <v-tooltip
            :key="item.id" 
            top
          >
            <template #activator="{ on }">
              <button
                :class="item.approval_date ? 'buildingRatingsChart__circle--approved' : ''"
                class="buildingRatingsChart__circle"
                :style="getLocation(item)"
                :aria-label="`${$t('buildingRating.date')}: ${dateAsString(item.start_date)}, ${$t('Rating class')}: ${item.rating_class_name}`"
                v-on="on"
              >
                <v-icon 
                  size="16"
                  color="white"
                  :class="!item.approval_date ? 'd-none' : ''"
                >
                  check
                </v-icon>
              </button>
            </template>
            <div>
              <span v-if="item.approval_date">
                <v-icon
                  size="16"
                  color="white"
                >check</v-icon>
                {{ $t('Approval date') + ': ' + dateAsString(item.approval_date) }}<br>
              </span>
              <span v-else>
                <v-icon
                  size="16" 
                  color="white"
                >
                  calendar_today
                </v-icon>
                {{ $t('buildingRating.date') + ': ' + dateAsString(item.start_date) }}<br>
              </span>
              <span>
                {{ $t('Rating class') + ': ' + item.rating_class_name }}<br>
              </span>
              <span v-if="proposalSelected(item)">
                {{ `${$t('Characteristics')}: ${item.characteristics} — ` }} {{ `${$t('Performance level')}: ${item.performance_level}` }}
              </span>
            </div>
          </v-tooltip>
        </template>
      </div>
      <div class="buildingRatingsChart__x-values">
        <span>1</span>
        <span>3</span>
        <span>5</span>
      </div>
      <div class="buildingRatingsChart__x-label">
        {{ $t('Characteristics') }}
      </div>
    </div>
  </div>
</template>

<script>
import helpers from '../../helpers'
export default {
  name: "BuildingRatingsChart",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    dateAsString (date) {
      return helpers.humanize.date(date, 'date')
    },
    proposalSelected (item) {
      return item.rating_class === item.rating_class_proposal
    },
    getLocation (item) {
      let characteristics = this.getPercentage(item.characteristics)
      let performance_level = this.getPercentage(item.performance_level)

      // If none/too few of the questions are answered, the circle should be placed in the middle of the square
      if (characteristics < 1 && performance_level < 1) {
        characteristics = this.getPercentage(2)
        performance_level = this.getPercentage(2)
      }

      // If the selected rating class isn't the same as the proposal, the circle should be placed in the middle of the square
      if (item.rating_class !== item.rating_class_proposal) {
        console.warn('switch', item.rating_class)
        switch (item.rating_class) {
          case 0: // 'salkutus_realisoitavat'
            characteristics = this.getPercentage(2)
            performance_level = this.getPercentage(2)
            break;
          case 1: // 'salkutus_kehitettavat'
            characteristics = this.getPercentage(2)
            performance_level = this.getPercentage(4)
            break;
          case 2: // 'salkutus_selvitettavat'
            characteristics = this.getPercentage(4)
            performance_level = this.getPercentage(2)
            break;
          case 3: // 'salkutus_pidettavat'
            characteristics = this.getPercentage(4)
            performance_level = this.getPercentage(4)
            break;
        }
      }

      return { 
        'bottom': performance_level + '%', 
        'left': characteristics + '%' 
      }
    },
    getPercentage (val) {
      const percentage = (val - 1) / 4 * 100
      return percentage > 0 ? percentage : 0
    },
  }
}
</script>

<style scoped>
.buildingRatingsChart {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-template-rows: repeat(4, auto);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin: var(--r-spacing-m);
  margin-bottom: var(--r-spacing-xs);
  font-size: var(--r-font-size-xs);
}
.buildingRatingsChart__bg {
  grid-area: 1 / 3 / 3 / 4;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 1px solid var(--r-color-white);
  border-left: 1px solid var(--r-color-white);
}
.buildingRatingsChart__bg::before {
  content: '';
  position: absolute;
  inset: 0;
  z-index: -1;
  background: var(--r-color-mountain-10);
  background: linear-gradient(45deg, rgba(255,160,119,1) 0%, rgba(255,160,119,1) 30%, rgba(255,230,130,1) 50%, rgba(173,208,149,1) 70%, rgba(173,208,149,1) 100%);
  filter: blur(32px);
  transform: scale(1.1);
}
.buildingRatingsChart__bg > span {
  border-right: 1px solid var(--r-color-white);
  border-bottom: 1px solid var(--r-color-white);
  padding: var(--r-spacing-xs) var(--r-spacing-s);
}

.buildingRatingsChart__y-label {
  grid-area: 1 / 1 / 3 / 2;
  display: flex;
  justify-content: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
}
.buildingRatingsChart__y-values {
  grid-area: 1 / 2 / 3 / 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  padding: 0 var(--r-spacing-xs);
}
.buildingRatingsChart__x-label {
  grid-area: 4 / 3 / 4 / 4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 var(--r-spacing-xs);
}
.buildingRatingsChart__x-values {
  grid-area: 3 / 3 / 4 / 4;
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: var(--r-spacing-xxs) 0;
}

.buildingRatingsChart__circle {
  position: absolute;
  width: var(--r-sizing-m);
  height: var(--r-sizing-m);
  border-radius: 50%;
  background: var(--c-color-accent);
  box-shadow: 0 0 0 2px var(--r-color-white);
  transition: all 0.3s;
  transform: translate(-50%, 50%);
  transform-origin: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--r-color-white);
}
.buildingRatingsChart__circle--approved {
  background: var(--r-color-ocean-100);
  width: var(--r-sizing-l);
  height: var(--r-sizing-l);
}
.buildingRatingsChart__circle:focus {
  background: var(--c-color-accent);
}
</style>