
<template>
  <div>
    <v-tab-item
      :key="0"
    >
      <v-form
        ref="form1"
        class="pt-2"
      >
        <ValidationObserver ref="observer">
          <div>
            <h3 class="form-headers">
              {{ $t('create_rent_contract.basic_info') }}
            </h3>

            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
            >
              <v-autocomplete
                v-if="isExternalRent === true"
                v-model="landlordId"
                :items="companyParties"
                item-text="name"
                item-value="id"
                :hint="$t('Required')"
                persistent-hint
                required
                :error-messages="errors[0]"
                class="form-fields mt-8 ml-2"
                :label="$t('create_rent_contract.landlord')"
                :readonly="companyParties && companyParties.length === 1 && (isExternalRent === true || (contract && contract.isDraft === false))"
                @change="onDataChanged()"
              />
              <v-autocomplete
                v-else
                v-model="landlordId"
                :items="parties"
                item-text="company_name"
                item-value="company_id"
                :hint="$t('Required')"
                persistent-hint
                required
                :error-messages="errors[0]"
                class="form-fields mt-8 ml-2"
                :label="$t('create_rent_contract.landlord')"
                :readonly="contract && contract.isDraft === false"
                @change="onDataChanged()"
              />
            </ValidationProvider>
          
            <ValidationProvider
              v-slot="{ errors }"
              :rules="`${isExternalRent ? 'required' : ''}`"
            >
              <v-autocomplete
                v-model="idLandlordContactPerson"
                :items="isExternalRent === true ? notChangeablePersonParties : personParties"
                item-text="name"
                item-value="id"
                :hint="$t('Required')"
                persistent-hint
                required
                :error-messages="errors[0]"
                class="form-fields mt-8 ml-2"
                :label="$t('create_rent_contract.contact_person')"
                @change="onDataChanged"
              />
            </ValidationProvider>
            <div
              v-if="isExternalRent === true"
            >
              <v-radio-group
                v-model="tenantType"
                :label="$t('create_rent_contract.tenantType')"
                :readonly="contract && contract.isDraft === false"
                row
                hide-details
                class="py-2 px-4 font-weight-bold mt-6 ml-2 rounded-pill"
                @change="changeTenantType"
              >
                <v-radio
                  default
                  :label="$t('create_rent_contract.company')"
                  :disabled="!isUpdatingAllowed"
                  :readonly="contract && contract.isDraft === false"
                  value="company"
                  class="font-weight-regular px-2"
                />
                <v-radio
                  :label="$t('create_rent_contract.person')"
                  :disabled="!isUpdatingAllowed"
                  :readonly="contract && contract.isDraft === false"
                  value="person"
                  class="font-weight-regular px-2"
                />
              </v-radio-group>
              <div v-if="tenantType === 'company'">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-autocomplete
                    id="company_tenant"
                    v-model="tenantId"
                    :items="parties"
                    item-text="company_name"
                    item-value="company_id"
                    required
                    :error-messages="errors[0]"
                    class="form-fields mt-5 ml-2"
                    :label="$t('create_rent_contract.tenant')"
                    :readonly="contract && contract.isDraft === false"
                    :hint="$t('Required')"
                    persistent-hint
                    @change="onDataChanged()"
                  />
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-autocomplete
                    v-model="idTenantContactPerson"
                    :items="tenantPersonParties"
                    item-text="name"
                    item-value="id"
                    required
                    :error-messages="errors[0]"
                    class="form-fields mt-8 mb-4 ml-2"
                    :label="$t('create_rent_contract.tenant_contact_person')"
                    :hint="$t('Required')"
                    persistent-hint
                    @change="onDataChanged()"
                  />
                </ValidationProvider>
              </div>
              <div v-else-if="isUpdatingAllowed && tenantType === 'person'">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-autocomplete
                    v-model="tenantId"
                    :items="allPersonParties"
                    item-text="name"
                    item-value="id"
                    required
                    :hint="$t('Required')"
                    persistent-hint
                    :error-messages="errors[0]"
                    class="form-fields mt-8 ml-2"
                    :label="$t('create_rent_contract.tenant')"
                    :readonly="contract && contract.isDraft === false"
                    @change="onDataChanged()"
                  />
                </ValidationProvider>
          
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="'validIdentityNumber'"
                >
                  <v-text-field
                    v-model="tenantIdentityNumber"
                    type="text"
                    :error-messages="errors[0]"
                    class="form-fields mt-8 ml-2"
                    :label="$t('create_rent_contract.tenant_identity_number')" 
                    :readonly="contract && contract.isDraft === false"
                    @input="onDataChanged()"
                  />
                </ValidationProvider>

                <v-btn
                  v-if="isOtherTenantActive === false"
                  rounded
                  depressed
                  color="secondary"
                  class="mt-4"
                  @click="addTenant"
                >
                  <v-icon left>
                    add
                  </v-icon>
                  {{ $t('create_rent_contract.add_tenant') }}
                </v-btn>
                <div
                  v-if="isOtherTenantActive === true"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="otherTenantId"
                      :items="allPersonParties"
                      item-text="name"
                      item-value="id"
                      required
                      :hint="$t('Required')"
                      persistent-hint
                      :error-messages="errors[0]"
                      class="form-fields mt-8 ml-2"
                      :label="$t('create_rent_contract.tenant')"
                      :readonly="contract && contract.isDraft === false"
                      @change="onDataChanged()"
                    />
                  </ValidationProvider>
          
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="validOtherIdentityNumber"
                  >
                    <v-text-field
                      v-model="otherTenantIdentityNumber"
                      type="text"
                      :error-messages="errors[0]"
                      class="form-fields mt-8 ml-2"
                      :label="$t('create_rent_contract.tenant_identity_number')"
                      :readonly="contract && contract.isDraft === false"
                      @input="onDataChanged()"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div
              v-else
            >
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
              >
                <v-autocomplete
                  v-model="tenantId"
                  :items="companyParties"
                  item-text="name"
                  item-value="id"
                  required
                  :hint="$t('Required')"
                  persistent-hint
                  :error-messages="errors[0]"
                  class="form-fields mt-8 ml-2"
                  :label="$t('create_rent_contract.tenant')"
                  :readonly="contract && contract.isDraft === false"
                  @change="onDataChanged()"
                />
              </ValidationProvider>
              <v-autocomplete
                v-model="idTenantContactPerson"
                :items="notChangeablePersonParties"
                item-text="name"
                item-value="id"
                class="form-fields mt-8 ml-2"
                :label="$t('create_rent_contract.tenant_contact_person')"
                @change="onDataChanged"
              />
            </div>
          </div>
        </ValidationObserver>
        <v-dialog
          v-model="partyDialog"
          width="550"
          persistent
        >
          <template #activator="{ on }">
            <v-btn
              v-if="isUpdatingAllowed && (!contract || contract.isDraft === true)"
              :aria-label="$t('create_rent_contract.add_new_party')"
              depressed
              rounded
              color="secondary"
              class="mt-4"
              @click="addNewParty"
              v-on="on"
            >
              <v-icon left>
                person_add
              </v-icon>
              {{ $t('create_rent_contract.add_new_party') }}
            </v-btn>
          </template>
          <v-card>
            <PartyRegisterForm
              v-if="partyDialog"
              :edit-party="editedItem"
              :editing="editing"
              :cost-centers="costCenters"
              :companies="parties"
              :items-object="partyTypes"
              @close="partyRegisterClosed"
              @childToParent="onPartyUpdated"
              @savedParty="includeParty"
            />
          </v-card>
        </v-dialog>
      </v-form>
    </v-tab-item>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import moment from 'moment'
import { i18n } from '../../../../plugins/i18n'
import PartyRegisterForm from '../../../PartyRegisterForm'

const getCheckMark = (result) => {
  let checkMark
  if (result == 0) {
    checkMark = "0";
  }
  else if (result == 1) {
    checkMark = "1";
  }
  else if (result == 2) {
    checkMark = "2";
  }
  else if (result == 3) {
    checkMark = "3";
  }
  else if (result == 4) {
    checkMark = "4";
  }
  else if (result == 5) {
    checkMark = "5";
  }
  else if (result == 6) {
    checkMark = "6";
  }
  else if (result == 7) {
    checkMark = "7";
  }
  else if (result == 8) {
    checkMark = "8";
  }
  else if (result == 9) {
    checkMark = "9";
  }
  else if (result == 10) {
    checkMark = "A";
  }
  else if (result == 11) {
    checkMark = "B";
  }
  else if (result == 12) {
    checkMark = "C";
  }
  else if (result == 13) {
    checkMark = "D";
  }
  else if (result == 14) {
    checkMark = "E";
  }
  else if (result == 15) {
    checkMark = "F";
  }
  else if (result == 16) {
    checkMark = "H";
  }
  else if (result == 17) {
    checkMark = "J";
  }
  else if (result == 18) {
    checkMark = "K";
  }
  else if (result == 19) {
    checkMark = "L";
  }
  else if (result == 20) {
    checkMark = "M";
  }
  else if (result == 21) {
    checkMark = "N";
  }
  else if (result == 22) {
    checkMark = "P";
  }
  else if (result == 23) {
    checkMark = "R";
  }
  else if (result == 24) {
    checkMark = "S";
  }
  else if (result == 25) {
    checkMark = "T";
  }
  else if (result == 26) {
    checkMark = "U";
  }
  else if (result == 27) {
    checkMark = "V";
  }
  else if (result == 28) {
    checkMark = "W";
  }
  else if (result == 29) {
    checkMark = "X";
  }
  else if (result == 30) {
    checkMark = "Y";
  }
  else {
    checkMark = null;
  }
  return checkMark;
}

const getValidIdentityNumber = (ssn) => {
  if (ssn.trim() != ssn) return false
  let digits = ssn.replace(/\D/g, '') 
  if (digits.length === 10){ 
    digits = digits.slice(0, -1)
  }
  let patt = new RegExp("[0-9]{6}[+-ABCDEFYXWVU]{1}[0-9]{3}$")
  let remainder = digits % 31
  let checkMark = getCheckMark(remainder)

  let allButCheckMark = ssn.slice(0, -1)
  let mark = ssn.substring(ssn.length - 1)
  
  let res = patt.test(allButCheckMark)
  if (res === true && checkMark === mark) {
    res = true
  }
  else {
    res = false
  }
  return res
}


extend('validIdentityNumber', {
  message: i18n.t("create_rent_contract.invalid_identity_number"),
  params: ['tenantIdentityNumber'],
  validate: (tenantIdentityNumber) => {
    const validSsn = getValidIdentityNumber(tenantIdentityNumber)
    return validSsn
  }
});

extend('validOtherIdentityNumber', {
  message: i18n.t("create_rent_contract.invalid_identity_number"),
  params: ['otherTenantIdentityNumber'],
  validate: (otherTenantIdentityNumber) => {
    const validSsn = getValidIdentityNumber(otherTenantIdentityNumber)
    return validSsn
  }
});

export default {
  name: "RentContractBasicInfoTab",
  components: {
    ValidationProvider,
    ValidationObserver,
    PartyRegisterForm,
  },
  props: {
    isActive: {
      type: Boolean,
      default: () => false
    },
    contract: {
      type: Object,
      default: () => undefined
    },
    isOutRent: {
      type: Boolean,
      default: () => false 
    },
    allParties: {
      type: Array,
      default: () => []
    },
    isUpdatingAllowed: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return (
      {
        landlordContactPersonEmail:"",
        landlordId: undefined,
        tenantId: undefined,
        otherTenantId: undefined,
        sites: undefined,
        parties: undefined,
        isPageValid: false,
        isExternalRent: false,
        companyParties: undefined,
        personParties: undefined,
        tenantType: 'company',
        tenantIdentityNumber: undefined,
        otherTenantIdentityNumber: undefined,
        idLandlordContactPerson: undefined,
        idTenantContactPerson: undefined,
        isOtherTenantActive: false,
        allPersonParties: undefined,
        notChangeablePersonParties: undefined,
        tenantPersonParties: undefined,
        partyDialog: false,
        editing: false,
        editedItem: null,
        partyTypes: [
          {
            state: this.$t('Business party'),
            id: 0,
            icon: 'business_center',
            data: [],
          },
          {
            state: this.$t('Person party'),
            id: 1,
            icon: 'face',
            data: [],
          }
        ],
        newParty: {
          name: null,
          business_id: null,
          phone_number: null,
          company_id: null,
          job_description: null,
          person_count: null,
          cost_center: null,
          unit: null,
          email: null,
          industry_classification: null,
          company: null,
          liaison: null,
          tenant_corporation: null,
          mediator_company: false
        },
        costCenters: [],
        justCreatedParty: undefined,
        justCreatedToPartyToSecondAllParties: false
      }
    )
  },
  computed: {
    ...mapGetters('app', ['definitionsByGroupLabel', 'applicationPermissions', 'countries']),
    rentReviewTypes () {
      return this.definitionsByGroupLabel('rentaloffer.rentreviewtype')
    },
  },
  watch: {
    async isActive () {
      this.isPageValid = await this.$refs.observer.validate()
      this.$emit("isPageValidChanged", this.isPageValid)
    },
    async contract () {
      if (this.contract) {
      this.updateDataFromExistingContract()
      }
    },
    allParties () {
      if (this.justCreatedToPartyToSecondAllParties) {
        this.justCreatedToPartyToSecondAllParties = false
        return
      }
      this.parties = this.allParties.filter(party => party.type == 0).map(p => (
        {
          company_name: p.name,
          company_id: p.id,
        })
      );
      let companyParties = this.allParties
        .filter(p => p.is_company === true)
        .map(p => this.getPartyObject(p));
      this.isExternalRent = this.isOutRent
      this.companyParties = companyParties
      if (this.justCreatedParty !== undefined) {
        if (this.justCreatedParty.id !== undefined && this.justCreatedParty.id !== null && 
          this.justCreatedParty.id !== 0) {
          if (this.justCreatedParty.type === 0) {
            if (this.tenantType === "company") {
              this.tenantId = this.justCreatedParty.id
              this.idTenantContactPerson = undefined
              this.otherTenantId = undefined
            }
          } else {
            if (this.tenantId !== this.justCreatedParty.company_id && this.tenantType === "company") {
              this.otherTenantId = undefined
            }
            if (this.tenantType !== "company") {
              let otherTenantParties = 
                this.allParties.filter(party => party.id === this.otherTenantId && party.type === 1)
              if (otherTenantParties.length === 0) {
                this.otherTenantId = undefined
              } else if (otherTenantParties[0].company_id !== this.justCreatedParty.company_id) {
                this.otherTenantId = undefined
              }
              this.tenantId = this.justCreatedParty.id
            } else {
              if (this.tenantId === this.justCreatedParty.company_id) {
                if (this.isExternalRent) {
                  this.tenantPersonParties = this.filterPersonParties(this.tenantId, this.allParties)
                } else {
                  this.notChangeablePersonParties = this.filterPersonParties(this.tenantId, this.allParties)
                }
              }
              this.tenantId = this.justCreatedParty.company_id
              this.idTenantContactPerson = this.justCreatedParty.id
            }
          }
          this.justCreatedToPartyToSecondAllParties = true
        }
      }
      if (this.isOutRent) {
        this.landlordId = companyParties.length > 0 ? companyParties[0].id : undefined
        if (this.justCreatedParty === undefined || this.justCreatedParty.id === undefined || 
          this.justCreatedParty.id === null || this.justCreatedParty.id === 0) {
          this.tenantId = undefined
        }
        this.personParties = this.filterPersonParties(this.landlordId, this.allParties)
        this.notChangeablePersonParties = this.personParties
        this.allPersonParties = this.allParties
          .filter(party => party.type === 1)
          .map(p => this.getPartyObject(p));
      }
      else {
        if (this.justCreatedParty === undefined || this.justCreatedParty.id === undefined || 
          this.justCreatedParty.id === null || this.justCreatedParty.id === 0) {
          this.tenantId = companyParties.length > 0 ? companyParties[0].id : undefined
        }
        this.landlordId = undefined
        this.personParties = this.filterPersonParties(this.tenantId, this.allParties)
        this.notChangeablePersonParties = this.personParties
      }
      this.justCreatedParty = undefined
    },
    landlordId () {
      if (this.isExternalRent) {
        this.notChangeablePersonParties = this.filterPersonParties(this.landlordId, this.allParties)
      } else {
        this.personParties = this.filterPersonParties(this.landlordId, this.allParties)
      }
    },
    tenantId () {
      if (this.isExternalRent) {
        this.tenantPersonParties = this.filterPersonParties(this.tenantId, this.allParties)
      } else {
        this.notChangeablePersonParties = this.filterPersonParties(this.tenantId, this.allParties)
      }
    }
  },
  async mounted () {
    this.getCostCenter()
    let defaultCountries = this.countries.filter(c => c.code === 'FI')
    if (defaultCountries.length !== 0) {
      this.newParty.id_country = defaultCountries[0].id
    } else if (this.countries.length !== 0) {
      this.newParty.id_country = this.countries[0].id
    }
  },
  methods: {
    getCostCenter () {
      this.$rambollfmapi.organizations.costCenters().list()
      .then(res => {
        this.costCenters = res.map(cc => {
          return {
            cost_center_identifier: cc.code,
            cost_center_name: cc.code + ' - ' + cc.name
          }
        })
      })
    },
    addNewParty () {
      this.partyDialog = true
      this.editedItem = this.newParty
      this.editing = false
    },
    includeParty (party) {
      this.justCreatedParty = party
      this.$emit('updateParties')
    },
    onPartyUpdated (value) {
      this.partyDialog = value 
      this.$emit('updateParties')
    },
    partyRegisterClosed () {
      this.partyDialog = false
    },
    changeTenantType () {
      if (this.isExternalRent && this.tenantType != 'person') {
        this.tenantId = undefined;
        this.otherTenantId = undefined;
        this.tenantIdentityNumber = "";
        this.otherTenantIdentityNumber = "";
        this.isOtherTenantActive = false
      }
      if (this.isExternalRent && this.tenantType != 'company') {
        this.tenantId = undefined;
        this.idTenantContactPerson = undefined;
      }
      this.onDataChanged()
    },
    formatDate (date) {
      const formatted = moment(date, "YYYY-MM-DD")
      return formatted.isValid() ? formatted.format("DD.MM.YYYY") : undefined
    },
    async updateDataFromExistingContract () {
      this.landlordId = this.contract.landlordId
      this.tenantType = this.contract.tenantType
      
      this.otherTenantId = this.contract.otherTenantId
      
      this.tenantIdentityNumber = this.contract.tenantIdentityNumber
      this.otherTenantIdentityNumber = this.contract.otherTenantIdentityNumber
      this.idLandlordContactPerson = this.contract.idLandlordContactPerson
      this.idTenantContactPerson = this.contract.idTenantContactPerson
      this.isExternalRent = this.contract.isOutRent
      this.tenantId = this.contract.tenantId
      if (this.contract.isOutRent) {
        this.tenantPersonParties = this.filterPersonParties(this.tenantId, this.allParties)
      } else {
        this.personParties = this.filterPersonParties(this.landlordId, this.allParties)
      }
      if (this.contract.otherTenantId !== null) {
        this.isOtherTenantActive = true
      }
      if (this.contract.isOutRent === false) {
        this.tenantType = "company"
      }
    },
    async onDataChanged () {
      const landlordContactPerson = this.allParties.find(party => party.id == this.idLandlordContactPerson)
      this.landlordContactPersonEmail = landlordContactPerson !== undefined ? landlordContactPerson.email : ""
      const data = {
        landlordId: this.landlordId,
        tenantId: this.tenantId,
        otherTenantId: this.otherTenantId,
        tenantType: this.tenantType,
        tenantIdentityNumber: this.tenantIdentityNumber,
        otherTenantIdentityNumber: this.otherTenantIdentityNumber,
        idTenantContactPerson: this.idTenantContactPerson,
        idLandlordContactPerson: this.idLandlordContactPerson,
        isOutRent: this.isExternalRent,
        landlordContactPersonEmail: this.landlordContactPersonEmail
      }
      this.$emit('updated', data)
    },
    addTenant () {
      this.isOtherTenantActive = true
    },
    filterPersonParties (companyId, parties) {
      return parties
        .filter(party => party.type === 1 && party.company_id && party.company_id === companyId)
        .map(p => this.getPartyObject(p));
    },
    getPartyObject (p) {
      return {
        name: p.name,
        id: p.id,
      }
    },
  }
}
</script>

<style scoped>
.form-headers {
  margin: 0px 10px 10px 10px !important;
  display: flex;
  color: rgb(0, 0, 0, 0.6);
}
</style>