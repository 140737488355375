<template>
  <v-dialog
    :value="isShown"
    persistent
    width="auto"
  >
    <ConfirmDialog ref="confirmDialog" />
    <BaseModal @cancel="close">
      <template #title>
        {{ $t(title) }}
      </template>
      <template #content>
        <div>
          <div class="info-text-container">
            <ConfirmedPositive v-if="infoComponent === 'ConfirmedPositive'" />
            <ConfirmedNegative
              v-else-if="infoComponent === 'ConfirmedNegative1'"
              :is-fixed-term="true"
            />
            <ConfirmedNegative
              v-else-if="infoComponent === 'ConfirmedNegative2'"
            />
            <ActiveProcesses
              v-else-if="infoComponent === 'ActiveProcesses'"
            />
            <PredictionInfoText
              v-else-if="infoComponent === 'PredictionInfoText1'"
              :is-fixed-term="true"
            />
            <PredictionInfoText
              v-else-if="infoComponent === 'PredictionInfoText2'"
            />
            <CurrentFreeInfoText
              v-else-if="infoComponent === 'CurrentFreeInfoText'"
            />
            <TargetInfoText
              v-else-if="infoComponent === 'TargetInfoText'"
              :year="inspectionYear"
            />
          </div>
          <DynamicWidget
            v-if="currentWidget !== null"
            :type="currentWidget.type"
            :title="$t(currentWidget.id)"
            :data="currentWidget.data"
            :loading="isLoading"
            @dataUpdate="onDataUpdated($event)"
          />
        </div>
      </template>
      <template #footer>
        <v-btn
          v-if="isGoalsInfoComponent"
          :disabled="!isGoalsModified || goalsSaving"
          depressed
          rounded
          color="primary"
          @click="onSaveClicked"
        >
          <span v-if="!goalsSaving">{{ $t('Save') }}</span>
          <v-progress-circular
            v-else
            :size="20"
            :width="3"
            indeterminate
            color="primary"
          />
        </v-btn>
        <v-btn
          v-else-if="isEndingContractInfoComponent"
          :disabled="!isContractsModified || contractsSaving"
          depressed
          rounded
          color="primary"
          @click="onSaveContractsClicked"
        >
          <span v-if="!contractsSaving">{{ $t('Save') }}</span>
          <v-progress-circular
            v-else
            :size="20"
            :width="3"
            indeterminate
            color="primary"
          />
        </v-btn>
      </template>
    </BaseModal>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import BaseModal from '../../general/BaseModal.vue'
import DynamicWidget from '../../DynamicWidget.vue'
import {
  ConfirmedTable,
  EndingFixedTable,
  TerminatedPermanentTable,
  ActiveProspectsTable,
  PredictionEndingFixedTable,
  PredictionTerminatedPermanentTable,
  CurrentFreeTable,
  TargetTable,
} from '../../../widgets/situationAndTargetModal'
import ConfirmedPositive from './STInfoTexts/ConfirmedPositive.vue'
import ConfirmedNegative from './STInfoTexts/ConfirmedNegative.vue'
import ActiveProcesses from './STInfoTexts/ActiveProcesses.vue'
import PredictionInfoText from './STInfoTexts/PredicitionInfoText.vue'
import CurrentFreeInfoText from './STInfoTexts/CurrentFreeInfoText.vue'
import TargetInfoText from './STInfoTexts/TargetInfoText.vue'
import { computeProspect, getProspectType } from '../../../helpers/leasing/prospect'
import { getRentalProcessTitle } from '../../../helpers/leasing/rentalprocess'
import moment from 'moment'
import { getDaysBetweenDates } from '../../../helpers/contract'
import { getBooleanString } from '../../../helpers/mappers/booleanString'
import ConfirmDialog from '../../general/ConfirmDialog.vue'

export default {
  name: 'SituationAndTargetModalController',
  components: {
    BaseModal,
    DynamicWidget,
    ConfirmedPositive,
    ConfirmedNegative,
    ActiveProcesses,
    PredictionInfoText,
    CurrentFreeInfoText,
    TargetInfoText,
    ConfirmDialog,
  },
  props: {
    modalInformation: {
      type: Object,
      default: function () {
        return {}
      },
    },
    partyLinks: {
      type: Array,
      default: () => []
    },
    inspectionYear: {
      type: Number,
      default: 0
    },
    lowerBoundQuarterDates: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    onClose: null,
  },
  data () {
    return {
      // If goal is modified, it will be added to there.
      modifiedGoals: {},
      isGoalsModified: false,
      contractsSaving: false,
      modifiedContracts: {},
      isContractsModified: false,
    }
  },
  computed: {
    ...mapState('leasing', [
      'prospectEvents',
      'municipalities',
      'parties',
      'leasingUsers',
      'quarterlyUsage',
      'capitalOccupancy',
      'leasingSummaries',
      'leasingSummariesLoading',
      'corporations',
    ]),
    ...mapState('rentalProcess', ['rentalProcesses', 'locations']),
    ...mapState('rentingGoal', ['goals', 'goalsLoading', 'goalsSaving']),
    ...mapState('app', ['sites', 'buildings', 'currentDate']),
    ...mapState('carpark', ["carparkOccupancyRates", "carParksAndParkingZonesWithRent"]),
    ...mapGetters('app', ['definitionById', 'definitionsByGroupLabel', 'hasApplicationPermissionByName']),
    ...mapGetters('rentingGoal', ['getDefaultGoals']),
    isEndingContractInfoComponent () {
      return this.infoComponent.includes('ConfirmedNegative') || this.infoComponent.includes('PredictionInfoText')
    },
    isGoalsInfoComponent () {
      return this.infoComponent === 'TargetInfoText'
    },
    isShown () {
      return this.modalInformation !== null
    },
    title () {
      if (this.modalInformation === null) {
        return ''
      }

      const {
        item: { text = '' },
        header,
      } = this.modalInformation

      if (header.value === 'currentFree') {
        return `${this.$t('leasing.currentFree')}: ${text}`
      }

      if (header.value === 'prediction') {
        return `${this.$t('leasing.active_processes')}: ${text}`
      }

      if (header.value === 'target') {
        return `${this.$t('leasing.target_title')}: ${text}`
      }

      return `${this.$t('leasing.confirmed')}: ${text}`
    },
    currentWidget () {
      if (this.modalInformation === null) {
        return null
      }
    
      const {
        items = [],
        item,
        header
      } = this.modalInformation
    
      let widget = null
    
      if (header.value === 'currentFree') {
        widget = CurrentFreeTable()
        widget.data.items = this.currentFreeContent(
          items,
          this.sites,
          this.quarterlyUsage[0],
          this.capitalOccupancy,
          this.leasingSummaries
        )
      } else if (header.value === 'target') {
        widget = TargetTable()
        widget.data.items = this.targetTableContent(
          items,
          this.sites,
          item.classifications
        )
      } else if (header.value === 'prediction') {
        if (item.type === 'terminated_permanent') {
          widget = PredictionTerminatedPermanentTable()
          widget.data.items = this.predictionContent(items)
        } else if (item.type === 'ending_fixed') {
          widget = PredictionEndingFixedTable()
          widget.data.items = this.predictionContent(items)
        } else {
          widget = ActiveProspectsTable()
          widget.data.items = this.activeProspectsContent(items)
        }
      } else if (item.type === 'terminated_permanent') {
        widget = TerminatedPermanentTable()
        widget.data.items = this.predictionContent(items)
      } else if (item.type === 'ending_fixed') {
        widget = EndingFixedTable()
        widget.data.items = this.predictionContent(items)
      } else {
        widget = ConfirmedTable()
        widget.data.items = this.confirmedContent(items)
      }
    
      if (widget) {
        widget.data.itemsPerPage = 15
      }
    
      return this.addFiltersToWidget(widget, item)
    },
    infoComponent () {
      if (this.modalInformation === null) {
        return ''
      }
      const { item, header } = this.modalInformation

      if (header.value === 'currentFree') {
        return 'CurrentFreeInfoText'
      }

      if (header.value === 'target') {
        return 'TargetInfoText'
      }

      if (header.value === 'prediction') {
        if (item.type === 'ending_fixed') {
          return  'PredictionInfoText1'
        } else if (item.type === 'terminated_permanent') {
          return 'PredictionInfoText2'
        }

        return 'ActiveProcesses'
      }

      if (item.type === 'ending_fixed') {
        return 'ConfirmedNegative1'
      } else if (item.type === 'terminated_permanent') {
        return 'ConfirmedNegative2'
      }

      return 'ConfirmedPositive'
    },
    isLoading () {
      return this.goalsLoading || this.loading
    },
    siteManagersBySite () {
      const siteResponsibilityDefinitions = this.definitionsByGroupLabel('site.responsibility')
      const siteManagerDefId = siteResponsibilityDefinitions.find(def => def.label === 'Kohdevastaava')?.id
      const siteManagerLinks = this.partyLinks.filter(link => siteManagerDefId === link.idDefinition)
      const siteManagersBySite = siteManagerLinks.reduce((siteManagersObject, link) => {
        siteManagersObject[link.idSite] ? siteManagersObject[link.idSite] += ', ' + link.partyName : siteManagersObject[link.idSite] = link.partyName
        return siteManagersObject
      }, {})
      return siteManagersBySite
    },
    leaseResponsiblesBySite () {
      const siteRoleDefinitions = this.definitionsByGroupLabel('role')
      const leaseResponsibleDefId = siteRoleDefinitions.find(def => def.label === 'Vuokravastuu (työntekijä)')?.id
      const leaseResponsibleLinks = this.partyLinks.filter(link => leaseResponsibleDefId === link.idDefinition)
      const leaseResponsiblesBySite = leaseResponsibleLinks.reduce((leaseManagerObject, link) => {
        leaseManagerObject[link.idSite] ? leaseManagerObject[link.idSite] += ', ' + link.partyName : leaseManagerObject[link.idSite] = link.partyName
        return leaseManagerObject
      }, {})
      return leaseResponsiblesBySite
    },
    partnerLeaseResponsiblesBySite () {
      const siteRoleDefinitions = this.definitionsByGroupLabel('role')
      const leaseResponsibleDefId = siteRoleDefinitions.find(def => def.label === 'Vuokrausvastuu (yhteistyökumppani)')?.id
      const leaseResponsibleLinks = this.partyLinks.filter(link => leaseResponsibleDefId === link.idDefinition)
      const leaseResponsiblesBySite = leaseResponsibleLinks.reduce((leaseManagerObject, link) => {
        leaseManagerObject[link.idSite] ? leaseManagerObject[link.idSite] += ', ' + link.partyName : leaseManagerObject[link.idSite] = link.partyName
        return leaseManagerObject
      }, {})
      return leaseResponsiblesBySite
    }
  },
  watch: {
    goals () {
      this.initModifiedGoals()
    },
    modalInformation (newInfo) {
      // Initialize modified goals.
      if (newInfo && newInfo.header.value === 'target') {
        this.initModifiedGoals()
      }
    },
  },
  methods: {
    ...mapActions('rentingGoal', ['updateAllGoals', 'fetchAllGoals']),
    getDefinitionLabelById (key) {
      const def = this.definitionById(key)
      if (typeof def === 'undefined' || def === null) {
        return this.$t('Undefined')
      }
      return def.label !== null ? def.label : this.$t('Undefined')
    },
    addFiltersToWidget (widget, item) {
      if (!widget || item.type !== 'total') {
        return widget
      }
      const siteClassificationHeader = widget.data.headers.find(header => header.value === 'site_classification')
      if (!siteClassificationHeader) {
        widget.data.headers.push({ text: 'Site classification', value: 'site_classification' })
      }
      const classifications = item.classifications.map(classification => {
        const classificationText = this.getDefinitionLabelById(classification)
        return {text: classificationText, value: classificationText}
      })
      widget.data.filter = {
        header: "site_classification",
        type: "equal",
        options: [ { text: this.$t('All') }, ...classifications ],
        defaultFilter: null,
      }
      return widget
    },
    confirmedContent (items) {
      const codes = ["AO", "AP"]
      return Object.values(items.reduce((result, item) => {
        const { rentalStatus, prospect, carparkContract } = item
        if (rentalStatus) {
          const id = rentalStatus.contractNumber
          const foundSite = this.sites.find((sitesItem) => sitesItem.id_site == rentalStatus.siteId)
          if (!result[id]) {
            const corporation = this.corporations.find(corp => corp.id === rentalStatus.curPartyId)
            const rental_type_translated = this.$t(this.definitionById(rentalStatus.defTypeOfRenting)?.label)
            const contractDuration = getDaysBetweenDates(rentalStatus.curPartyStartDate, rentalStatus.curPartyEndDate)
            result[id] = {
              site_identifier: rentalStatus.site_identifier,
              site: rentalStatus.siteName,
              id_site: rentalStatus.siteId,
              site_classification: this.getDefinitionLabelById(rentalStatus.site_classification),
              process_title: null,
              prospect_status: null,
              tenant: rentalStatus.curPartyName,
              area: rentalStatus.area,
              percentage_of_rentable_area: rentalStatus.area / foundSite.rentable_floor_area * 100,
              area_ratio: null,
              rent: rentalStatus.capitalRent,
              termination_date: rentalStatus.termination_date,
              signature_date: rentalStatus.signature_date,
              contractNumber: rentalStatus.contractNumber,
              curPartyEndDate: rentalStatus.curPartyEndDate,
              curPartyStartDate: rentalStatus.curPartyStartDate,
              contractDuration,
              siteManager: this.siteManagersBySite[rentalStatus.siteId] ?? null,
              leaseResponsible: this.leaseResponsiblesBySite[rentalStatus.siteId] ?? null,
              id_tenant: rentalStatus.curPartyId,
              business_id: rentalStatus.business_id,
              tenant_corporation: rentalStatus.tenant_corporation,
              prospect_type_icon: '',
              rental_type_translated,
              unitId: rentalStatus.unitId,
              curUnitPartyId: rentalStatus.curUnitPartyId,
              reportable_area: 0,
              discounts: rentalStatus.discounts,
              contractType: rentalStatus.contractType,
              validity: rentalStatus.validity,
              industry: rentalStatus.industry,
              category: corporation?.tenant_category,
              credit_rating: corporation?.credit_rating,
              vat_status: getBooleanString(rentalStatus.vat),
              apartment_type: rentalStatus.apartment,
              carparkCount: 0,
              carparkCodes: [],
              unitCount: 1,
              unitCodes: [rentalStatus.unit_code_long || this.$t('Undefined')],
              rentalProcessResponsibles: null,
              unit_status: this.$t(this.getDefinitionLabelById(rentalStatus.unitStatus)),
              renter: null,
            }
            if (result[id].unit_status == this.$t('rentable.koy')) {
              result[id].renter = 'KOy';
            } else if (result[id].unit_status === this.$t('rentable')) {
              result[id].renter = 'Osakkeiden omistaja'
            }
            if (prospect) {
            const typeInfo = getProspectType(prospect)
            const rentalProcessResponsibles = this.rentalProcessResponsibles(prospect)
            result[id].rentalProcessResponsibles = rentalProcessResponsibles
            result[id].rental_type = typeInfo.typeText
            result[id].rental_type_translated = this.$t(typeInfo.typeText)
            result[id].prospect_type_icon = typeInfo.typeIcon
            result[id].process_title = getRentalProcessTitle(prospect)
            result[id].id_prospect = prospect.id_prospect
            result[id].id_process = prospect.id_process
            const index = prospect.contract_info.findIndex(contract => contract.contractNumber === rentalStatus.contractNumber && contract.contractSigningDate === rentalStatus.signature_date)
              if (index !== -1) {
                result[id].reportable_area = prospect.contract_info[index].reportableArea
                result[id].area_ratio = result[id].reportable_area ? result[id].area / result[id].reportable_area * 100 : null
              } 
          }
          }
          else {
            result[id].unitCodes.push(rentalStatus.unit_code_long || this.$t('Undefined'))
            result[id].unit_status = this.$t('Multiple')
            result[id].unitCount++
            result[id].area += rentalStatus.area
            result[id].rent += rentalStatus.capitalRent
            result[id].percentage_of_rentable_area = result[id].area / foundSite.rentable_floor_area * 100
            result[id].area_ratio = result[id].reportable_area ? result[id].area / result[id].reportable_area * 100 : null
          }
        } else if (carparkContract) {
          const id = carparkContract.contract_number
          if (!result[id]) {
            const foundSite = this.sites.find((sitesItem) => sitesItem.id_site == carparkContract.id_site)
            const siteClassification = this.getDefinitionLabelById(carparkContract.site_classification)
            const contractDuration = getDaysBetweenDates(carparkContract.start_date, carparkContract.end_date)
            const corporation = this.corporations.find(corp => corp.id === carparkContract.id_party)
            result[id] = {
              site_identifier: carparkContract.site_identifier,
              site: carparkContract.site_name,
              area: carparkContract.contract_area,
              rent: carparkContract.capital_rent,
              prospect_status: null,
              siteManager: this.siteManagersBySite[carparkContract.id_site] ?? null,
              leaseResponsible: this.leaseResponsiblesBySite[carparkContract.id_site] ?? null,
              signature_date: carparkContract.signature_date,
              curPartyEndDate: carparkContract.end_date,
              curPartyStartDate: carparkContract.start_date,
              contractDuration,
              percentage_of_rentable_area: carparkContract.contract_area / foundSite.rentable_floor_area * 100,
              site_classification: this.$t(siteClassification) || '',
              id_site: carparkContract.id_site,
              id_prospect: null,
              id_process: null,
              tenant: carparkContract.tenant,
              id_tenant: carparkContract.id_party,
              business_id: carparkContract.business_id,
              tenant_corporation: carparkContract.contract_party,
              contractType: carparkContract.contract_type,
              industry: carparkContract.industry,
              validity: carparkContract.validity,
              contractNumber: carparkContract.contract_number,
              carparkCodes: [carparkContract.carpark_code_long],
              carparkCount: 0,
              unitCount: 0,
              rentalProcessResponsibles: null,
              prospect_type_icon: null,
              rental_type_translated: null,
              vat_status: getBooleanString(carparkContract.vat_responsibility),
              category: corporation?.tenant_category,
              credit_rating: corporation?.credit_rating,
              reportable_area: 0,
              renter: carparkContract.landlord,
            }
          } else { 
            result[id].carparkCodes.push(carparkContract.carpark_code_long || this.$t('Undefined'))
          }
          if (carparkContract.carpark_code !== null && codes.some(prefix => carparkContract.carpark_code.startsWith(prefix))) {
              result[id].carparkCount++
          }
        }
        return result;
      }, {}));
    },
    activeProspectsContent (items) {
      const filteredItems = items.filter((item) => !!item.prospect)
      
      return filteredItems.map((item) => {
        const { prospect, weight } = item
        const events = this.prospectEvents.filter(
          (event) => event.id_prospect === prospect.id_prospect
        )

        const computedProspect = computeProspect(
          prospect,
          this.parties,
          this.municipalities,
          this.sites,
          this.leasingUsers,
          events,
          (id) => this.getDefinitionLabelById(id)
        )
        const corporation = this.corporations.find(corp => corp.id === prospect.id_corporation)
        // Get site managers and lease responsibles for all sites connected to the prospect
        const prospectSiteIds = prospect.sites.map(site => site.id_site)
        const siteManager = [...new Set(prospectSiteIds.map(siteId => this.siteManagersBySite[siteId] ?? ''))].join(', ')
        const leaseResponsible = [...new Set(prospectSiteIds.map(siteId => this.leaseResponsiblesBySite[siteId] ?? ''))].join(', ')
        const rentalProcessResponsibles = this.rentalProcessResponsibles(computedProspect)
        let rentalProcessLocations = []
        prospect.locations.forEach(location => {
          const locationName = this.locations.find(loc => loc.id === location)?.text
          if (locationName) {
            rentalProcessLocations.push(locationName)
          }
        })
        let calculatedArea = prospect.unit_area_preference || 0
        if (weight || weight === 0) {
          calculatedArea = calculatedArea * weight
        }

        return {
          ...computedProspect,
          calculatedArea,
          siteManager,
          leaseResponsible,
          rentalProcessLocations,
          rentalProcessResponsibles,
          credit_rating: corporation?.credit_rating,
        }
      })
    },
    rentalProcessResponsibles (process) {
      const first = process.first_responsible ? process.first_responsible : null;
      const secondary = process.secondary_responsible ? process.secondary_responsible : null;

      if (!first && !secondary) {
        return null;
      }

      return `${first || ''}${first && secondary ? ', ' : ''}${secondary || ''}`;
    },
    predictionContent (items) {
      const codes = ["AO", "AP"]
      const userHasRightsToEdit = this.hasApplicationPermissionByName('MUUT_SOPIMUKSET_RAPORTOINTI_MUOKKAUS')
      return  Object.values(items.reduce((result, item) => {
        const { prospect, rentalStatus, carparkContract } = item

        let computedProspect = {}

        if (prospect) {
          const events = this.prospectEvents.filter(
            (event) => event.id_prospect === prospect.id_prospect
          )

          computedProspect = computeProspect(
            prospect,
            this.parties,
            this.municipalities,
            this.sites,
            this.leasingUsers,
            events,
            (id) => this.getDefinitionLabelById(id)
          )
        }
        if (rentalStatus) {

          const id = rentalStatus.contractNumber
          const prospectStatus = computedProspect.prospect_status || this.getDefinitionLabelById(rentalStatus.defNegotiationStatus)
          const foundSite = this.sites.find((sitesItem) => sitesItem.id_site == rentalStatus.siteId)
          const siteClassification = this.getDefinitionLabelById(foundSite?.site_classification)
          const contractDuration = getDaysBetweenDates(rentalStatus.curPartyStartDate, rentalStatus.curPartyEndDate)
          const rentalProcessResponsibles = this.rentalProcessResponsibles(computedProspect)

          if (!result[id]) {
            result[id] = {
              site: rentalStatus.siteName,
              process_title: computedProspect.prospect_description || '',
              area: rentalStatus.area,
              rent: rentalStatus.capitalRent,
              curPartyEndDate: rentalStatus.curPartyEndDate,
              curPartyStartDate: rentalStatus.curPartyStartDate,
              contractFirstPossibleEndDate: rentalStatus.contractFirstPossibleEndDate,
              contractDuration,
              prospect_status: prospectStatus,
              prospect_fullscreen_status: this.$t(prospectStatus),
              status_description: computedProspect.status_description,
              last_modified: computedProspect.last_modified || '',
              id_site: computedProspect.id_site || rentalStatus.siteId,
              id_prospect: computedProspect.id_prospect,
              id_process: computedProspect.id_process,
              tenant: computedProspect.tenant || rentalStatus.curPartyName,
              id_tenant: computedProspect.id_tenant || rentalStatus.curPartyId,
              business_id: computedProspect.business_id || rentalStatus.business_id,
              tenant_corporation:
              computedProspect.tenant_corporation ||
              rentalStatus.tenant_corporation,
              curPartyName: rentalStatus.curPartyName,
              siteManager: this.siteManagersBySite[rentalStatus.siteId] ?? null,
              leaseResponsible: this.leaseResponsiblesBySite[rentalStatus.siteId] ?? null,
              site_classification: this.$t(siteClassification) || '',
              percentage_of_rentable_area: rentalStatus.area / foundSite.rentable_floor_area * 100,
              termination_date: rentalStatus.termination_date,
              contractType: rentalStatus.contractType,
              industry: rentalStatus.industry,
              open_bills_total: rentalStatus.openBillsTotal,
              apartment_type: rentalStatus.apartment,
              contractNumber: rentalStatus.contractNumber,
              unitCodes: rentalStatus.unit_code_long,
              unitCount: 1,
              carparkCodes: [],
              carparkCount: 0,
              carparkArea: 0,
              rentalProcessResponsibles,
              reportable_ending: rentalStatus.reportable_ending,
              reportable_area: rentalStatus.reportable_area !== null ? rentalStatus.reportable_area : rentalStatus.area,
              reportable_carparks_count: 0,
              unitContractId: rentalStatus.contractId,
              carparkContractId: null,
              userHasRightsToEdit,
           }
          } else {
            result[id].unitCodes += ', ' + rentalStatus.unit_code_long
            result[id].unitCount++
            result[id].area += rentalStatus.area
            result[id].reportable_area += rentalStatus.reportable_area !== null ? 0 : rentalStatus.area
            result[id].rent += rentalStatus.capitalRent
            result[id].percentage_of_rentable_area = result[id].area / foundSite.rentable_floor_area * 100
            result[id].apartment_type = this.$t('Multiple')
          }
        } else {
          const id = carparkContract.contract_number
          if (!result[id]) {
            const foundSite = this.sites.find((sitesItem) => sitesItem.id_site == carparkContract.id_site)
            const siteClassification = this.getDefinitionLabelById(foundSite?.site_classification)
            const contractDuration = getDaysBetweenDates(carparkContract.contract_start_date, carparkContract.contract_end_date)
            result[id] = {
              site: carparkContract.site_name,
              area: carparkContract.contract_area,
              rent: carparkContract.capital_rent,
              prospect_status: 'Undefined',
              siteManager: this.siteManagersBySite[carparkContract.id_site] ?? null,
              leaseResponsible: this.leaseResponsiblesBySite[carparkContract.id_site] ?? null,
              contractFirstPossibleEndDate: carparkContract.first_possible_end_date,
              curPartyEndDate: carparkContract.contract_end_date,
              curPartyStartDate: carparkContract.contract_start_date,
              contractDuration,
              percentage_of_rentable_area: carparkContract.contract_area / foundSite.rentable_floor_area * 100,
              site_classification: this.$t(siteClassification) || '',
              id_site: carparkContract.id_site,
              id_prospect: null,
              id_process: null,
              tenant: carparkContract.tenant,
              id_tenant: carparkContract.id_party,
              business_id: carparkContract.business_id,
              tenant_corporation: carparkContract.contract_party,
              curPartyName: carparkContract.tenant,
              contractType: carparkContract.contract_type,
              industry: carparkContract.industry,
              open_bills_total: carparkContract.open_bills_total,
              contractNumber: id,
              carparkCodes: [carparkContract.carpark_code_long],
              carparkCount: 0,
              unitCount: 0,
              carparkArea: carparkContract.area || 0,
              reportable_ending: carparkContract.reportable_ending,
              reportable_area: carparkContract.reportable_area !== null ? carparkContract.reportable_area : carparkContract.contract_area,
              reportable_carparks_count: 0,
              unitContractId: null,
              carparkContractId: carparkContract.id_car_park_contract,
              userHasRightsToEdit,
            }
          } else { 
            result[id].carparkArea += carparkContract.area
            result[id].carparkCodes.push(carparkContract.carpark_code_long)
            result[id].carparkContractId = carparkContract.id_car_park_contract
          }
          if (codes.some(prefix => carparkContract.carpark_code.startsWith(prefix))) {
              result[id].carparkCount++
              if (result[id].reportable_ending) {
                result[id].reportable_carparks_count++
              }
          }
        }
        return result
      }, {}));
    },
    currentFreeContent (items, sites, occupancyRates, capitalOccupancy, leasingSummaries) {
      // Group the carpark data by site id'
      const grouppedCarparkData = this.carParksAndParkingZonesWithRent.reduce((acc, item) => {
        const { idSite } = item
        if (!acc.has(idSite)) {
          acc.set(idSite, [])
        }
        acc.get(idSite).push(item)
        return acc
      }, new Map())

      // Group carpark occupancy rates by site id
      const grouppedCarparkOccupancyRates = this.carparkOccupancyRates.reduce((acc, item) => {
        const { id_site } = item
        acc.set(id_site, item)
        return acc
      }, new Map())

      // Group the rental processes by site id
      const grouppedRentalProcesses = this.rentalProcesses.reduce((acc, item) => {
        item.sites.forEach(site => {
          const { id_site } = site
          if (!acc.has(id_site)) {
            acc.set(id_site, [])
          }
          acc.get(id_site).push(item)
        })
        return acc
      }, new Map())

      // group the sites by id
      const groupedSites = sites.reduce((acc, site) => {
        if (!acc.has(site.id_site)) {
          acc.set(site.id_site, site)
        }
        return acc
      }, new Map())

      // Group the leasing summaries by site id
      const groupedLeasingSummaries = leasingSummaries.reduce((acc, item) => {
        acc[item.id_site] = item
        return acc
      }, {})
      
      // Group the occupancy rates by site id
      const groupedRates = this.groupOccupancyRates(occupancyRates)

      // Group the capital occupancy by site id
      const groupedCapitalOccupancy = capitalOccupancy.reduce((acc, item) => {
        acc[item.id_site] = item
        return acc
      }, {})

      return Object.values(items.reduce((result, item) => {
        const { rentalStatus } = item;
        const siteId = rentalStatus.siteId;

        // Initialize or update the site object
        if (!result[siteId]) {
          const site = groupedSites.get(siteId)
          const building = this.buildings.find(building => building.id_site === siteId)

          const siteClassification = this.definitionById(site.site_classification)
          const siteStatus = this.definitionById(parseInt(site.site_status))
          const rentType = this.definitionById(parseInt(site.rental_contract_type))
          
          const rates = groupedRates.get(siteId)
          const capitalOccupancy = groupedCapitalOccupancy[siteId]
          const leasingSummary = groupedLeasingSummaries[siteId]         
          const rentalProcesses = grouppedRentalProcesses.get(siteId) || []
          const carparkOccupancy = grouppedCarparkOccupancyRates.get(siteId) || {}
          const carparkData = grouppedCarparkData.get(siteId) || {}
          let carparks = []
          let carparkPriviledges = []
          let carparkAreas = []
          if (carparkData.length > 0) {
            carparks = carparkData.filter(item => item.rentingtype === 'Single')
            carparkPriviledges = carparkData.filter(item => item.rentingtype === 'Privilege')
            carparkAreas = carparkData.filter(item => item.rentingtype === 'AreaRent')
          }

          const carparksCount = carparks.length
          const freeCarparks = carparks.reduce((acc, item) => acc + item.num_of_free_car_spaces, 0)
          const parkingRights = carparkPriviledges.reduce((acc, item) => acc + item.num_of_parking_rights, 0)
          const freeParkingRights = carparkPriviledges.reduce((acc, item) => acc + item.num_of_free_parking_rights, 0)
          const carparksInArea = carparkAreas.reduce((acc, item) => acc + item.num_of_car_spaces, 0)
          const freeCarparksInArea = carparkAreas.reduce((acc, item) => acc + item.num_of_free_car_spaces, 0) 
          const carparksRentedInArea = carparksInArea - freeCarparksInArea
          const parkingAreaUsageRate = carparksRentedInArea / carparksInArea * 100

          const validStages = ['4', '5', '6', '7']
          const activeRentalProcessAreaPreference = rentalProcesses
            .filter(({ status, stage }) => status !== 'Inactive' && validStages.includes(stage.charAt(0)))
            .reduce((acc, { area_preference }) => acc + area_preference, 0);
          const confirmedNewNegotiationsArea = rentalProcesses
            .filter(process => process.stage === '8. Closed' && !process.renegotiations) 
            .reduce((acc, process) => {
              const reportableArea = process.contract_info.reduce((acc, contract) => acc + contract.reportableArea, 0)
              return acc + reportableArea
            }, 0)

          const economicOccupancy = ((capitalOccupancy?.unit_capital_future + capitalOccupancy?.unit_capital_current + capitalOccupancy?.carpark_capital_current)
              / (capitalOccupancy?.unit_capital_future + capitalOccupancy?.carpark_capital_current + capitalOccupancy?.unit_capital_current + capitalOccupancy?.unit_market_rent_free + capitalOccupancy.carpark_market_rent_free )) * 100
          const rentableUnitsTotal = leasingSummary.free_units + leasingSummary.rented_units

          result[siteId] = {
            // from site
            site: site.name,
            site_identifier: site.site_identifier,
            site_classification: this.$t(siteClassification?.label) || '',
            site_status: this.$t(siteStatus?.label) || '',
            contract_type: this.$t(rentType?.label) || '',
            commercial_name: site.commercial_name,
            site_state: site.site_state,
            rentable_floor_area: site.rentable_floor_area,
            free_floor_area: site.free_floor_area,
            rented_floor_area: site.rented_floor_area,
            id_site: siteId,
            siteManager: this.siteManagersBySite[siteId] ?? null,
            leaseResponsible: this.leaseResponsiblesBySite[siteId] ?? null,
            rental_liability: this.partnerLeaseResponsiblesBySite[siteId] ?? null,

            // from building
            completed_year: building?.completed_year,
            
            // other
            rentalSituation: '',
            area: 0, // Initialize area to 0

            // from capitalOccupancy
            unit_market_rent_free: capitalOccupancy?.unit_market_rent_free,
            economic_occupancy: economicOccupancy,
            
            // from leasingSummary
            net_total_previous_year: leasingSummary?.rented_area_start_of_current_year - leasingSummary?.rented_area_start_of_previous_year,
            net_total_current_year: leasingSummary?.rented_area - leasingSummary?.rented_area_start_of_current_year,
            rentable_units_total: rentableUnitsTotal,
            unitRate: leasingSummary?.rented_units / rentableUnitsTotal * 100,
            ...leasingSummary,

            // from rentalprocesses
            active_processes_area_preference: activeRentalProcessAreaPreference,
            confirmed_new_negotiations_area: confirmedNewNegotiationsArea,

            // from carparkData
            carpark_count: carparksCount,
            free_carparks : freeCarparks,
            carpark_usage_rate: (carparksCount - freeCarparks) / carparksCount * 100,
            parking_rights_remain: freeParkingRights,
            parking_priviledges_usage_rate: (parkingRights - freeParkingRights) / parkingRights * 100,
            carparks_in_area: carparksInArea,
            free_carparks_area: freeCarparksInArea,
            parking_area_usage_rate: parkingAreaUsageRate,

            // from carparkOccupancy
            max_parking_priviledges: carparkOccupancy?.max_parking_rights,
            parking_rights_given: carparkOccupancy?.carparks_rented, 


            // from rates
            ...rates,
          };

          if (rates.economic_usage_rate > 95) {
          result[siteId].rentalSituation = 'rented'
          }
          else if (rates.economic_usage_rate >= 80) {
            result[siteId].rentalSituation = 'in_process'
          }
          else if (rates.economic_usage_rate >=70) {
            result[siteId].rentalSituation = 'free_under_100'
          }
          else {
            result[siteId].rentalSituation = 'free_over_100'
          }
        }

        // Add the current rentalStatus.area to the total area
        result[siteId].area += rentalStatus.area;

        return result;
      }, {}));
    },
    targetTableContent (items, sites, classifications) {
      if (items.length === 0) {
        return []
      }
      const itemsBySite = this.calculateAreasBySite(items, sites, classifications)
      const defaultGoals = this.getDefaultGoals
      const siteRentingPriorities = this.arrayToMap(sites, 'id_site', 'rent_priority')

      const content = []

      // Populate content array.
      itemsBySite.forEach((item) => {
        const {
          siteId,
          siteName,
          totalArea,
          freeArea,
          freeUnits,
          freeMarketRent,
          endingArea,
          endingContracts,
          ufnArea,
          ufnContracts,
          capitalRent,
          site_classification,
        } = item

        let goal = defaultGoals
        // If site goal is set, let's use it. Otherwise use default values.
        if (this.goals && this.goals.has(siteId)) {
          goal = this.goals.get(siteId)
        }

        // Calculate areas using the values.
        const newNegotiationTarget = goal.new_negotiation
        const renegotiationTarget = goal.renegotiation
        const renewalTarget = goal.renewal
        const usageRateTarget = goal.usage_rate

        const usage = totalArea != 0 ? ((totalArea - freeArea) / totalArea * 100).toFixed(2) : 0
        const economicUsage = (freeMarketRent + capitalRent) != 0 ? (capitalRent / (freeMarketRent + capitalRent) * 100).toFixed(2) : 0

        content.push({
          site: siteName,
          id_site: siteId,
          rent_priority: siteRentingPriorities.get(siteId),
          freeArea,
          freeUnits,
          newNegotiationTarget,
          endingArea,
          endingContracts,
          renegotiationTarget,
          ufnArea,
          ufnContracts,
          renewalTarget,
          usage,
          economicUsage,
          usageRateTarget,
          siteManager: this.siteManagersBySite[siteId] ?? null,
          leaseResponsible: this.leaseResponsiblesBySite[siteId] ?? null,
          modified: goal.modified,
          modifier: goal.modifier,
          site_classification,
        })
      })

      return content
    },
    calculateAreasBySite (items, sites, classifications) {
      const groupedBySite = items.reduce((acc, item) => {

        const { 
          siteId, 
          area,
          contractNumber,
          siteName,
          validity,
          curPartyId,
          curPartyEndDate,
          capitalRent,
          market_rent
        } = item


        let accStatus = acc.get(siteId)

        if (!accStatus) {
          const newRow = {
            siteId,
            siteName,
            totalArea: 0,
            freeArea: 0,
            freeUnits: 0,
            freeMarketRent: 0,
            endingArea: 0,
            endingContracts: 0,
            ufnArea: 0,
            ufnContracts: 0,
            capitalRent: 0,
            contractNumbers: [],
            site_classification: this.getDefinitionLabelById(sites.find(site => site.id_site === siteId)?.site_classification),
          }
          acc.set(siteId, newRow)
          accStatus = acc.get(siteId)
        }

        accStatus.totalArea += area

        if (!curPartyId) {
          accStatus.freeArea += area
          accStatus.freeUnits++
          accStatus.freeMarketRent += (market_rent * area)
        }
        else {
          if (validity === 'määräaikainen' && curPartyEndDate != null) {
            const endDate = new Date(curPartyEndDate)
            const endOfYear = new Date(moment({ year: this.inspectionYear}).utc(true).endOf('year'))
            if (endDate <= endOfYear) {
              accStatus.endingArea += area
            }
            if (!accStatus.contractNumbers.includes(contractNumber)) {
              if (endDate <= endOfYear) {
                accStatus.endingContracts++
              }
              accStatus.capitalRent += capitalRent
              accStatus.contractNumbers.push(contractNumber)
            }
          }
          if (validity === 'toistaiseksi voimassaoleva') {
            accStatus.ufnArea += area
            if (!accStatus.contractNumbers.includes(contractNumber)) {
                accStatus.ufnContracts++
                accStatus.capitalRent += capitalRent
                accStatus.contractNumbers.push(contractNumber)
              }
          }
        }
        accStatus.area += area
        accStatus.units++
        return acc
      }, new Map())

      // Also add sites that don't have any active rental statuses
      if (classifications) {
        const classificationSites = sites.filter(site => classifications.includes(site.site_classification) && !groupedBySite.has(site.id_site))
        classificationSites.forEach(site => groupedBySite.set(site.id_site, {siteId: site.id_site, siteName: site.name, area: 0}))
      }

      return groupedBySite
    },
    groupOccupancyRates (occupancyRates) {
      // Calcualte the total occupancyrate for the site and group the data by siteid.
      const { site_occupancy_rates: siteOccupancyRates } = occupancyRates

      const groupedRates = siteOccupancyRates.reduce((acc, occupancyRate) => {
        const {
          id_site: idSite,
          unit_usage_occupancy_rates: unitUsageOccupancyRates,
        } = occupancyRate 

        if (acc.has(idSite)) {
          return acc
        }

        // unitUsageOccupancyRates contains the data by unit usage. We need to calcualte total for site.
        const total = unitUsageOccupancyRates.reduce(
          (acc, rate) => {
            acc.rented_area += rate.rented_area
            acc.rented_rent += rate.rented_rent
            acc.total_area += rate.total_area
            acc.total_rent += rate.total_rent
            acc.free_area += rate.free_area

            return acc
          },
          {
            rented_area: 0,
            rented_rent: 0,
            total_area: 0,
            total_rent: 0,
            free_area: 0,
            free_rent: 0,
          }
        )

        // Finally calculate the usage rates. Just divide rented by total.
        const calculatedRates = {
          ...total,
          economic_usage_rate: total.total_rent
            ? (total.rented_rent / total.total_rent) * 100
            : 0,
          technical_usage_rate: total.total_area
            ? (total.rented_area / total.total_area) * 100
            : 0,
        }

        acc.set(idSite, calculatedRates)
        return acc
      }, new Map())

      return groupedRates
    },
    onDataUpdated (event) {
      if (this.isGoalsInfoComponent) {
        const {
          header,
          item: { id_site: idSite },
          value,
        } = event

        const numValue = isNaN(value) ? null : value

        let toBeModified = this.modifiedGoals[idSite]
        // If we dont have goals for this site yet, add it
        if (!toBeModified) {
          const defaultGoal = this.getDefaultGoals
          toBeModified = {
            ...defaultGoal,
            id_site: idSite,
            modified: null,
            modifier: null,
            shouldUpdate: true
          }
          this.modifiedGoals[idSite] = toBeModified
        }

        if (header === 'newNegotiationTarget') {
          toBeModified.new_negotiation = numValue
          toBeModified.shouldUpdate = true
        } else if (header === 'renegotiationTarget') {
          toBeModified.renegotiation = numValue
          toBeModified.shouldUpdate = true
        } else if (header === 'renewalTarget') {
          toBeModified.renewal = numValue
          toBeModified.shouldUpdate = true
        } else if (header === 'usageRateTarget') {
          toBeModified.usage_rate = numValue
          toBeModified.shouldUpdate = true
        }

        this.isGoalsModified = true
      } else {
        const {
          header,
          item,
          value,
        } = event
        this.modifiedContracts = { 
          ...this.modifiedContracts, 
          [item.contractNumber]: { 
            reportable_ending: item.reportable_ending, 
            reportable_carparks_count: item.reportable_carparks_count, 
            reportable_area: item.reportable_area,
            contract_number: item.contractNumber,
            unitContractId: item.unitContractId,
            carparkContractId: item.carparkContractId,
            contractEndDate: item.curPartyEndDate,
          } 
        }
        if (header === 'reportable_ending') {
          this.modifiedContracts[item.contractNumber].reportable_ending = value
          if (value === true) {
            this.modifiedContracts[item.contractNumber].reportable_carparks_count = item.carparkCount
          } else {
            this.modifiedContracts[item.contractNumber].reportable_carparks_count = 0
            this.modifiedContracts[item.contractNumber].reportable_area = 0
          }
        } else if (header === 'reportable_area') {
          this.modifiedContracts[item.contractNumber].reportable_area = value
        }

        this.isContractsModified = true
      }
    },
    onSaveClicked () {
      const fetchAllGoals = this.fetchAllGoals
      const inspectionYear = this.inspectionYear
      let time = new Date()
      time.setFullYear(inspectionYear)

      this.updateAllGoals({modifiedGoals: this.modifiedGoals, time}).then(() => fetchAllGoals(time))
      this.isGoalsModified = false
    },
    onSaveContractsClicked () {
      this.contractsSaving = true
      const carparkContractsToUpdate = Object.values(this.modifiedContracts).filter(contract => contract.carparkContractId).map(contract => {
        return {
          id_car_park_contract: contract.carparkContractId,
          reportable_ending: contract.reportable_ending,
          reportable_area: contract.reportable_area,
          contract_end_date: contract.contractEndDate          
        }
      })
      const unitContractsToUpdate = Object.values(this.modifiedContracts).filter(contract => contract.unitContractId).map(contract => {
        return {
          contractId: contract.unitContractId,
          reportable_ending: contract.reportable_ending,
          reportable_area: contract.reportable_area,
          contractEndDate: contract.contractEndDate
        }
      })
      new Promise((resolve, reject) => {
        const promises = []
      
        if (carparkContractsToUpdate.length > 0) {
          promises.push(this.$rambollfmapi.carparks.contracts.updateReportableInfo(carparkContractsToUpdate, this.currentDate));
        }
        if (unitContractsToUpdate.length > 0) {
          promises.push(this.$rambollfmapi.units.rentalStatus().updateReportableInfo(unitContractsToUpdate, this.currentDate));
        }
      
        Promise.all(promises)
          .then(() => resolve())
          .catch(error => reject(error))
      })
      .then(() => {
        this.$emit('onUpdate');
      })
      .catch(error => {
        this.$log.info('Failed to update contracts', error)
      })
      this.modifiedContracts = {}
      this.isContractsModified = false;
      this.contractsSaving = false;
    },
    initModifiedGoals () {
      const modifiedGoals = {}

      this.goals.forEach((value) => {
        modifiedGoals[value.id_site] = { ...value }
      })
      this.modifiedGoals = modifiedGoals
    },
    arrayToMap (array, key, value) {
      const map = new Map()
      array.forEach(item => {
        map.set(item[key], item[value])
      })
      return map;
    },
    async close () {
      if (this.isGoalsModified || this.isContractsModified) {
        try {
          const ok = await this.$refs.confirmDialog.show({
            title: 'leasing.offer_close_confirmation',
            positive: 'Yes',
          })
          if (ok) {
            this.$emit('onClose')
          }
        } catch (_) {
          return
        }
      }
      else {
        this.$emit('onClose')
      }
      this.isGoalsModified = false
      this.isContractsModified = false
      this.modifiedContracts = {}
    }
  },
}
</script>

<style scoped>
.info-text-container {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
